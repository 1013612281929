import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import * as RockActions from './rock.actions';
import { withLatestFrom, switchMap, map, catchError, tap } from 'rxjs/operators';

import { of, from } from 'rxjs';
import { Router } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { Rock } from '@entities/rock';
import { RockService } from '@app/evolve/services/rock.service';
import { ErrorService } from '@app/shared/services/error.service';
import { appRoutesNames } from '@app/app.routes.names';
import { evolveRouteNames } from '@app/evolve/evolve.routes.names';
import { evolveSelectors } from '../evolve.state';
import { Goal } from '@entities/goal';
import { GoalService } from '@app/evolve/services/goal.service';
import { cloneDeep } from 'lodash';

@Injectable()
export class RockEffects {
   constructor(
      private actions$: Actions,
      private store: Store<State>,
      private router: Router,
      private rockService: RockService,
      private errorService: ErrorService,
      private snackBar: MatSnackBar,
      private goalService: GoalService
   ) {}

   createRock$ = createEffect(() =>
      this.actions$.pipe(
         ofType(RockActions.CreateRock),
         withLatestFrom(this.store.pipe(select(getOrganizationId))),
         switchMap(([{ rock, preventNavigation, showSnackBar }, orgId]) => {
            return from(this.rockService.save(rock)).pipe(
               map((r) => {
                  if (showSnackBar) {
                     this.snackBar.open('Rock Saved');
                  }
                  if (!preventNavigation) {
                     this.router.navigate([
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.EVOLVE,
                        evolveRouteNames.ROCKS,
                        r.id,
                     ]);
                  }
                  return RockActions.SelectRock({ rockId: r.id });
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   saveRock$ = createEffect(() =>
      this.actions$.pipe(
         ofType(RockActions.SaveRock),
         withLatestFrom(this.store.pipe(select(evolveSelectors.getGoals))),
         switchMap(([{ rock }, goals]) => {
            let goalToSave: Goal;
            const rockToSave = { ...rock };
            if (rock.goalId) {
               const goal = goals.find((g) => g.id === rock.goalId);
               if (goal) {
                  if (!goal.rocks || !goal.rocks.includes(rock.id)) {
                     const rocks = goal.rocks ? [...goal.rocks, rock.id] : [rock.id];
                     goalToSave = {
                        ...goal,
                        rocks,
                     };
                  }
               } else {
                  rockToSave.goalId = null;
               }
            }
            return from(this.rockService.save(rockToSave)).pipe(
               switchMap((savedRock) => {
                  if (goalToSave) {
                     return from(this.goalService.save(goalToSave)).pipe(map(() => savedRock));
                  } else {
                     return of(savedRock);
                  }
               }),
               map((r) => {
                  return RockActions.SelectRock({ rockId: r.id });
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   duplicateRock$ = createEffect(() =>
      this.actions$.pipe(
         ofType(RockActions.DuplicateRock),
         switchMap(({ rock }) => {
            const rockToSave = cloneDeep(rock);
            rockToSave.name = `[Copy] ${rock.name}`;
            rockToSave.id = null;
            return from(this.rockService.save(rockToSave)).pipe(
               map((savedRock) => {
                  return RockActions.EditRock({ rock: savedRock });
               })
            );
         })
      )
   );

   addRock$ = createEffect(() =>
      this.actions$.pipe(
         ofType(RockActions.AddRock),
         withLatestFrom(this.store.pipe(select(getOrganizationId))),
         map(([action, orgId]) => {
            this.router.navigate([
               appRoutesNames.ORGANIZATION,
               orgId,
               appRoutesNames.EVOLVE,
               evolveRouteNames.ROCKS,
               'new',
            ]);
            return RockActions.SelectRock({ rockId: null });
         })
      )
   );

   editRock$ = createEffect(() =>
      this.actions$.pipe(
         ofType(RockActions.EditRock),
         withLatestFrom(this.store.pipe(select(getOrganizationId))),
         map(([action, orgId]) => {
            this.router.navigate([
               appRoutesNames.ORGANIZATION,
               orgId,
               appRoutesNames.EVOLVE,
               evolveRouteNames.ROCKS,
               action.rock.id,
            ]);
            return RockActions.SelectRock({ rockId: action.rock.id });
         })
      )
   );

   deleteRock$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(RockActions.DeleteRock),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([action, orgId]) => {
               this.rockService
                  .delete(action.rock)
                  .then(() => {
                     this.router.navigate([
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.EVOLVE,
                        evolveRouteNames.ROCKS,
                     ]);
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );
}
