import { Component, OnInit, Input } from '@angular/core';
import { UserComment } from '@entities/user-comment';

@Component({
   selector: 'app-user-comment',
   templateUrl: './user-comment.component.html',
   styleUrls: ['./user-comment.component.scss'],
})
export class UserCommentComponent implements OnInit {
   @Input() comment: UserComment;

   constructor() {}

   ngOnInit() {}
}
