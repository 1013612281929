<mat-toolbar color="primary" class="no-print">
   <app-topnav></app-topnav>
</mat-toolbar>
<div class="accent-ribbon"></div>

<mat-sidenav-container autosize>
   <mat-sidenav
      #drawer
      role="navigation"
      mode="side"
      [opened]="orgSelected$ | async"
      disableClose="true"
      class="sidenav"
      position="start"
   >
      <app-sidenav></app-sidenav>
   </mat-sidenav>
   <mat-sidenav-content>
      <app-alert-banner></app-alert-banner>
      <div class="main-content">
         <router-outlet></router-outlet>
         <div class="env">{{ envName }}</div>
      </div>
   </mat-sidenav-content>

   <mat-sidenav #drawer mode="side" [opened]="helpOpen$ | async" class="sidenav" position="end">
      <app-help-sidebar></app-help-sidebar>
   </mat-sidenav>
</mat-sidenav-container>

<div class="loader-container" *ngIf="navigating">
   <div class="loader"></div>
</div>
