<form [formGroup]="form">
   <app-sticky-header
      title="Performance Evaluation"
      [saveDisabled]="form.pristine || !form.valid"
      (save)="save()"
      (delete)="deleteEval()"
      (back)="back()"
   ></app-sticky-header>
   <mat-card>
      <mat-card-header>Overview</mat-card-header>
      <mat-card-content class="eval-summary">
         <div class="top-row">
            <app-team-member-autocomplete
               [idFormControl]="getControl('teamMemberId')"
               placeholder="Team Member"
            ></app-team-member-autocomplete>
            <mat-form-field>
               <input matInput placeholder="Year" formControlName="year" />
            </mat-form-field>
            <mat-form-field>
               <mat-select placeholder="Status" formControlName="status">
                  <mat-option *ngFor="let key of statusKeys" [value]="key">
                     {{ status[key] }}
                  </mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div class="description">
            <app-text-editor
               label="Notes"
               [config]="editorConfig"
               [formCtrl]="getControl('notes')"
               [canEdit]="true"
            ></app-text-editor>
         </div>
      </mat-card-content>
   </mat-card>
</form>
Competencies
<ng-container *ngFor="let item of perfEvalItems">
   <app-performance-evaluation-item-edit
      [item]="item"
      (itemUpdated)="updateItem($event)"
   ></app-performance-evaluation-item-edit>
</ng-container>
