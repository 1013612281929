import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormArray } from '@angular/forms';
import { Observable } from 'rxjs';
import { BusinessUnit } from '@entities/business-unit';
import { TeamMember } from '@entities/team-member';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { froalaConfig } from '@app/shared/config/froala.config';

@Component({
   selector: 'app-business-unit-edit',
   templateUrl: './business-unit-edit.component.html',
   styleUrls: ['./business-unit-edit.component.scss'],
})
export class BusinessUnitEditComponent implements OnInit {
   form: UntypedFormGroup;
   editAction: string;
   canEdit$: Observable<boolean>;

   editorConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Purpose',
      minHeight: 100,
   };

   private orgBuilderFacade: OrgBuilderFacade;

   constructor(
      @Inject(MAT_DIALOG_DATA)
      data: {
         businessUnit: BusinessUnit;
         service: OrgBuilderFacade;
      }
   ) {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         purpose: new UntypedFormControl(),
         notes: new UntypedFormControl(),
         directorId: new UntypedFormControl(),
         organizationId: new UntypedFormControl(),
         order: new UntypedFormControl(),
      });
      this.form.patchValue(data.businessUnit);
      const vision = data.businessUnit['vision'];
      if (vision && !data.businessUnit.purpose) {
         this.form.get('purpose').setValue(vision);
      }
      this.orgBuilderFacade = data.service;
      this.editAction = data.businessUnit.id ? 'Edit' : 'Add';
      this.canEdit$ = this.orgBuilderFacade.canEdit$;
   }

   ngOnInit() {}

   getControl(name: string): UntypedFormControl {
      return this.form.get(name) as UntypedFormControl;
   }

   save() {
      const businessUnit: BusinessUnit = this.form.value;
      this.orgBuilderFacade.saveBusinessUnit(businessUnit);
   }

   cancel() {
      this.orgBuilderFacade.cancelEditBusinessUnit();
   }

   setDirectorId(event: MatAutocompleteSelectedEvent) {
      if (event) {
         const id = event.option.value.id;
         this.form.get('directorId').setValue(id);
         this.form.markAsDirty();
      } else {
         this.form.get('directorId').setValue(null);
         this.form.markAsDirty();
      }
   }
}
