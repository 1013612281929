import { createAction, props } from '@ngrx/store';
import { ActionItem } from '@entities/action-item';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { Sort } from '@angular/material/sort';

export const ActionItemsUpdated = createAction(
   '[Actions] actionItems updated',
   props<{ actionItems: ActionItem[] }>()
);
export const AddActionItem = createAction('[Actions] Add actionItem');
export const EditActionItem = createAction(
   '[Actions] Edit actionItem',
   props<{ actionItemId: string }>()
);
export const SelectActionItem = createAction(
   '[Actions] Select actionItem',
   props<{ actionItemId: string }>()
);
export const SaveActionItem = createAction(
   '[Actions] Save actionItem',
   props<{ actionItem: ActionItem; hideSnackBar?: boolean; preventNavigation?: boolean }>()
);
export const DeleteActionItem = createAction(
   '[Actions] Delete actionItem',
   props<{ actionItem: ActionItem }>()
);
export const HideEditActionItem = createAction(
   '[Actions] Hide edit actionItem',
   props<{ actionItemId: string }>()
);
export const ActionItemsFilterUpdated = createAction(
   '[Actions] Filter updated',
   props<{ filter: FilterValues }>()
);

export const ConvertActionToRock = createAction(
   '[Actions] Convert action to rock',
   props<{ action: ActionItem; deleteAction: boolean }>()
);
export const SetVisibleColumns = createAction(
   '[Actions] Set visible columns',
   props<{ columns: string[] }>()
);
export const SetSort = createAction('[Actions] Set sort', props<{ sort: Sort }>());
export const DuplicateActionItem = createAction(
   '[Actions] Duplicate action item',
   props<{ actionItem: ActionItem }>()
);

export const ActionItemActions = {
   ActionItemsUpdated,
   AddActionItem,
   EditActionItem,
   SelectActionItem,
   SaveActionItem,
   DeleteActionItem,
   DuplicateActionItem,
   HideEditActionItem,
   ActionItemsFilterUpdated,
   ConvertActionToRock,
   SetVisibleColumns,
   SetSort,
};
