import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { take, map } from 'rxjs/operators';
import { FirebaseAuthService } from './firebase-auth.service';
import { firstValueFrom } from 'rxjs';
import { appRoutesNames } from '@app/app.routes.names';

@Injectable({
   providedIn: 'root',
})
export class AuthGuard implements CanActivate {
   constructor(private auth: FirebaseAuthService, private router: Router) {}

   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      return firstValueFrom(this.auth.firebaseUser$).then((user) => {
         if (user) {
            return true;
         } else {
            localStorage.setItem('redirectUrl', state.url);
            this.auth.logout(route.data['logoutRedirect']);
            const logoutRedirect = route.data?.logoutRedirect ?? appRoutesNames.LOGIN;
            return this.router.createUrlTree([logoutRedirect]);
         }
      });
   }
}
