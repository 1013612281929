import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorService } from '@app/shared/services/error.service';

import { Store } from '@ngrx/store';
import { State } from '@app/app.state';
import * as AuthActions from '../state/auth.actions';
import { AuthUserService } from './auth-user.service';
import { take, takeUntil } from 'rxjs/operators';
import { AuthUser } from '@entities/auth-user';
import { appRoutesNames } from '@app/app.routes.names';
import {
   MatLegacyDialog as MatDialog,
   MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { FirebaseAuthShim } from './firebase-auth.shim';

@Injectable()
export class FirebaseAuthService implements OnDestroy {
   firebaseUser$: Observable<any>;
   authUser$: Observable<AuthUser>;
   authState$: Observable<any>;

   private dialogRef: MatDialogRef<any>;
   private destroyed$ = new Subject<void>();

   constructor(
      private firebaseAuth: FirebaseAuthShim,
      private router: Router,
      private errorService: ErrorService,
      private store: Store<State>,
      private authUserService: AuthUserService,
      private dialog: MatDialog
   ) {
      this.firebaseUser$ = this.firebaseAuth.user();
      this.authUser$ = this.authUserService.currentUser$;
      this.authState$ = this.firebaseAuth.authState();
      this.syncAuthUser();
      this.authUser$.pipe(takeUntil(this.destroyed$)).subscribe((authUser) => {
         this.store.dispatch(AuthActions.AuthUserUpdated({ authUser }));
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   getCurrentUser() {
      return this.firebaseAuth.currentUser;
   }

   private syncAuthUser() {
      this.authState$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
         if (user) {
            this.authUserService
               .getAuthUser(user)
               .toPromise()
               .then((authUser) => {
                  if (!authUser) {
                     // user is in firebase auth but not our database
                     this.authUserService.createAuthUser(user);
                  }
               });
         }
      });
   }

   signup(email: string, password: string, firstName: string, lastName: string) {
      const displayName = `${firstName} ${lastName}`;
      this.firebaseAuth
         .createUserWithEmailAndPassword(email, password)
         .then((credential) => {
            const fbUser = this.firebaseAuth
               .updateProfile(credential.user, { displayName })
               .then(() => {
                  const user = {
                     ...credential.user,
                     displayName,
                  };
                  this.authUserService
                     .createAuthUser(user, firstName, lastName)
                     .then((authUser: AuthUser) => {
                        const redirect = localStorage.getItem('redirectUrl');
                        this.store.dispatch(AuthActions.AuthUserUpdated({ authUser }));
                        if (redirect) {
                           localStorage.removeItem('redirectUrl');
                           this.router.navigateByUrl(redirect);
                        } else {
                           this.router.navigate([appRoutesNames.LANDING]);
                        }
                     });
               });
         })
         .catch((err) => {
            this.errorService.handleError(err, null, true);
         });
   }

   login(email: string, password: string) {
      this.firebaseAuth
         .signInWithEmailAndPassword(email, password)
         .then(() => {
            const redirect = localStorage.getItem('redirectUrl');
            if (redirect) {
               localStorage.removeItem('redirectUrl');
               this.router.navigateByUrl(redirect);
            } else {
               this.router.navigate([appRoutesNames.LANDING]);
            }
         })
         .catch((err) => {
            this.errorService.handleError(err, null, true);
         });
   }

   logout(redirect?: string) {
      this.firebaseAuth.signOut().then(() => {
         this.clearState();
         if (redirect) {
            this.router.navigate([redirect]);
         } else {
            this.router.navigate([appRoutesNames.LOGIN]);
         }
      });
   }

   clearState() {
      localStorage.removeItem('state');
   }

   resetPassword() {
      this.dialogRef = this.dialog.open(ResetPasswordComponent, {
         data: { service: this },
         width: '400px',
      });
   }

   sendResetEmail(email: string) {
      this.firebaseAuth.sendPasswordResetEmail(email);
   }

   closeResetDialog() {
      this.dialogRef.close();
   }

   updateEmail(email: string) {
      return this.firebaseUser$
         .pipe(take(1))
         .toPromise()
         .then((currentUser) => {
            return this.firebaseAuth.updateEmail(currentUser, email);
         });
   }

   async reauthenticate(password: string) {
      const user = await this.firebaseAuth.currentUser;
      if (user) {
         return this.firebaseAuth.signInWithEmailAndPassword(user.email, password).then(() => true);
      } else {
         return Promise.resolve(false);
      }
   }
}
