<div class="editor-wrapper">
   <mat-label *ngIf="formCtrl.value || alwaysShowLabel" class="editor-label">
      {{ label }}
   </mat-label>
   <div class="editor" (click)="focusEditor()">
      <textarea
         matInput
         type="text"
         [readonly]="!canEdit"
         [formControl]="formCtrl"
         [froalaEditor]="editorConfig"
         (froalaInit)="initFroala($event)"
      ></textarea>
   </div>
</div>

