import { Product } from '@entities/product';
import { PlanInterval } from '@entities/enums/plan-interval';
import { ProductType } from '@entities/enums/product-type';

const products: Product[] = [
   // {
   //    name: 'Process',
   //    id: 'prod_GKcGyBjShc61Zt',
   //    type: ProductType.Process,
   //    plans: [
   //       { id: 'plan_GKcHllwPvANRIn', interval: PlanInterval.MONTHLY, price: 10 },
   //       { id: 'plan_GRGiGcAT7yczlr', interval: PlanInterval.YEARLY, price: 100 }
   //    ]
   // },
   // {
   //    name: 'Evolve',
   //    id: 'prod_GKcGyBjShc61Zt',
   //    type: ProductType.Evolve,
   //    plans: [
   //       { id: 'plan_GKcMJyxPl8TAUd', interval: PlanInterval.MONTHLY, price: 10 },
   //       { id: 'plan_GKcMEaZ4sN8ivb', interval: PlanInterval.YEARLY, price: 100 }
   //    ]
   // },
   {
      name: 'Trellis Tools Complete',
      id: 'prod_GKcGyBjShc61Zt',
      type: ProductType.Complete,
      plans: [
         { id: 'plan_GKcNkmcSSd9iLz', interval: PlanInterval.MONTHLY, price: 15 },
         { id: 'plan_GKcNuV0zA2ajmw', interval: PlanInterval.YEARLY, price: 150 },
      ],
   },
   // {
   //    name: 'Reference',
   //    id: 'prod_GKcGyBjShc61Zt',
   //    type: ProductType.Reference,
   //    plans: [
   //       { id: 'price_1HxKAyEVIHp5fNwtZwHDgI7j', interval: PlanInterval.MONTHLY, price: 5 },
   //       { id: 'price_1HxKBDEVIHp5fNwtNvbrY6AZ', interval: PlanInterval.YEARLY, price: 50 },
   //    ],
   // },
];

export const environment = {
   auth0: {
      client: '90q5LbFL9PhideBimnpgalmtA3h32Vf3',
      domain: 'opsmgr.auth0.com',
      redirectUri: 'https://opsmgr-dev.alignedapps.com/callback',
      logoutUrl: 'https://opsmgr-dev.alignedapps.com',
      realm: 'Username-Password-Authentication',
   },
   isConfig: true,
   production: false,
   apiUrl: 'https://alignedapps-dev.azurewebsites.net/api',
   baseUrl: 'https://opsmgr-dev.alignedapps.com',
   demoOrg: 'qQc1bO5a48EN6clJlNdx',
   name: 'DEV',
   firebase: {
      apiKey: 'AIzaSyCZiO-2EVnx5jbrawpU64_9nurv3juNxZ4',
      authDomain: 'aligned-orgs-dev.firebaseapp.com',
      databaseURL: 'https://aligned-orgs-dev.firebaseio.com',
      projectId: 'aligned-orgs-dev',
      storageBucket: 'aligned-orgs-dev.appspot.com',
      messagingSenderId: '212333093273',
      appId: '1:212333093273:web:85de2879a3678cce',
   },
   logrocket: {
      init: 'dqnbv6/trellis-tools-dev',
   },
   sentry: {
      dsn: 'https://4c81239742244e619e29fda9f4ec083a@sentry.io/5188751',
   },
   stripe: {
      publicKey: 'pk_test_FNk2lIFLpAcu1UChhuvyxlo500RlpBNULD',
      products: products,
   },
};
