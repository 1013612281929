import { Component, inject } from '@angular/core';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { AuthUser } from '@entities/auth-user';

@Component({
   selector: 'app-alert-banner',
   templateUrl: './alert-banner.component.html',
   styleUrls: ['./alert-banner.component.scss'],
})
export class AlertBannerComponent {
   showAlert = false;
   emailSent = false;
   private userOrgFacade = inject(UserOrgFacade);
   authUser: AuthUser;

   ngOnInit() {
      this.userOrgFacade.authUser$.subscribe((authUser) => {
         this.authUser = authUser;
         this.showAlert = authUser && !authUser?.emailVerified && !authUser?.verificationSent;
      });
   }

   dismissAlert() {
      this.showAlert = false;
   }

   verifyEmail() {
      this.userOrgFacade.verifyEmail(this.authUser);
      this.emailSent = true;
   }
}

