import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appRoutesNames } from '@app/app.routes.names';
import { AuthUserService } from '@app/auth/services/auth-user.service';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { combineLatest, take, catchError, of } from 'rxjs';

@Component({
   selector: 'app-verify-email',
   templateUrl: './verify-email.component.html',
   styleUrls: ['./verify-email.component.scss'],
})
export class VerifyEmailComponent {
   emailVerified = false;
   error: any;

   constructor(
      private route: ActivatedRoute,
      private userOrgFacade: UserOrgFacade,
      private authUserService: AuthUserService,
      private router: Router
   ) {}

   ngOnInit() {
      combineLatest([
         this.route.params.pipe(take(1)),
         this.authUserService.currentUser$.pipe(take(1)),
      ]).subscribe(([params, authUser]) => {
         const authUserId = params['uid'];
         if (authUserId === authUser?.uid) {
            this.emailVerified = true;
            this.userOrgFacade.emailVerified(authUser);
            setTimeout(() => {
               this.router.navigate([appRoutesNames.LANDING]);
            }, 5000);
         } else {
            this.error = {
               message:
                  'The verification link does not match the account details for this user. Try resending the verification email from your user account',
            };
         }
      });
   }
}

