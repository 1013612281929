import {
   ChangeDetectionStrategy,
   Component,
   Input,
   SecurityContext,
   SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
   selector: 'app-report-text',
   templateUrl: './report-text.component.html',
   styleUrls: ['./report-text.component.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTextComponent {
   @Input() text: string;

   processedText = '';

   constructor(private sanitizer: DomSanitizer) {}

   ngOnInit() {
      this.processText();
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['text']) {
         this.processText();
      }
   }

   processText() {
      if (this.text) {
         const lineBreaks = this.text.replace(new RegExp('\n', 'g'), '<br/>');
         this.processedText = this.sanitizer.sanitize(SecurityContext.HTML, lineBreaks);
      } else {
         this.processedText = '';
      }
   }
}
