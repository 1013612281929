import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { EvolveFacade } from '@app/evolve/state/evolve.facade';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { ActionItem } from '@entities/action-item';
import { takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';
import { DepartmentNamePipe } from '@app/shared/pipes/department-name.pipe';
import { TeamMemberNamePipe } from '@app/shared/pipes/team-member-name.pipe';
import { Priority } from '@entities/enums/priority';
import { Status } from '@entities/enums/status';
import { FilterValues, FilterBarConfig } from '@app/shared/interfaces/filter.interfaces';
import { Department } from '@entities/department';
import { TeamMember } from '@entities/team-member';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { ActionItemType } from '@entities/enums/action-item-type';
import { DatePipe } from '@angular/common';
import { TableComponent, TableConfig } from '@app/shared/components/table/table.component';
import { RoleGuard } from '@app/admin/services/role.guard';
import { AppAreas } from '@entities/enums/app-areas';

const initialSort: Sort = { active: 'name', direction: 'asc' };

@Component({
   selector: 'app-action-item-list',
   templateUrl: './action-item-list.component.html',
   styleUrls: ['./action-item-list.component.scss'],
})
export class ActionItemListComponent implements OnInit, OnDestroy {
   @ViewChild(TableComponent) table: TableComponent;

   actionItems$: Observable<ActionItem[]>;
   sort$: BehaviorSubject<Sort> = new BehaviorSubject(initialSort);
   filter$: Observable<FilterValues>;

   filterBarConfig: FilterBarConfig = {
      businessUnits: true,
      departments: true,
      status: Status,
      type: ActionItemType,
      teamMembers: true,
      priority: Priority,
      teamMembersLabel: 'Assigned To',
      filterChange: (filter) => this.filterFormChanged(filter),
   };

   displayedColumns$ = this.evolveFacade.actionItemsVisibleColumns$;
   /* istanbul ignore next */
   columns: TableColumn<ActionItem>[] = [
      { label: 'Name', def: 'name', visible: true, value: (row) => row.name },
      { label: 'Type', def: 'type', visible: true, value: (row) => ActionItemType[row.type] },

      {
         label: 'Department',
         def: 'departmentId',
         filter: 'departments',
         visible: true,
         value: (row) => this.departmentNamePipe.transform(row.departmentId, this.departments),
      },
      {
         label: 'Assigned To',
         def: 'assigneeId',
         visible: true,
         value: (row) => this.teamMemberNamePipe.transform(row.assigneeId, this.teamMembers),
      },
      {
         label: 'Status',
         def: 'status',
         filter: 'status',
         visible: true,
         value: (row) => Status[row.status],
      },
      {
         label: 'Start Date',
         def: 'startDate',
         visible: false,
         isDate: true,
         value: (row) => this.datePipe.transform(row.startDate),
      },
      {
         label: 'Target Date',
         def: 'targetDate',
         visible: true,
         isDate: true,
         value: (row) => this.datePipe.transform(row.targetDate),
      },
      {
         label: 'Completed Date',
         def: 'completedDate',
         visible: false,
         isDate: true,
         value: (row) => this.datePipe.transform(row.completedDate),
      },
      { label: 'Priority', def: 'priority', visible: true, value: (row) => Priority[row.priority] },
   ];

   departments: Department[];
   teamMembers: TeamMember[];

   tableConfig: TableConfig;

   canEdit = false;

   private destroyed$ = new Subject<void>();

   constructor(
      private evolveFacade: EvolveFacade,
      private departmentNamePipe: DepartmentNamePipe,
      private teamMemberNamePipe: TeamMemberNamePipe,
      private datePipe: DatePipe,
      private roleGuard: RoleGuard
   ) {}

   ngOnInit() {
      this.roleGuard
         .canEdit([], AppAreas.Evolve)
         .pipe(takeUntil(this.destroyed$))
         .subscribe((canEdit) => {
            this.canEdit = canEdit;
         });
      this.actionItems$ = this.evolveFacade.actionItems$;
      this.filter$ = this.evolveFacade.actionItemsFilter$;
      this.evolveFacade.departments$.pipe(takeUntil(this.destroyed$)).subscribe((departments) => {
         this.departments = departments;
         if (this.tableConfig) {
            this.tableConfig.departments = departments;
         }
      });
      this.evolveFacade.teamMembers$.pipe(takeUntil(this.destroyed$)).subscribe((teamMembers) => {
         this.teamMembers = teamMembers;
      });

      /* istanbul ignore next */
      this.tableConfig = {
         columns: this.columns,
         visibleColumns$: this.displayedColumns$,
         data$: this.actionItems$,
         filter$: this.filter$,
         sort$: this.evolveFacade.actionItemsSort$,
         initialSort: { direction: 'asc', active: 'name' },
         sortChange: (sort) => this.evolveFacade.setActionsSort(sort),
         statusField: 'status',
         rowClick: (row) => this.edit(row),
         departments: this.departments,
         teamMemberField: 'assigneeId',
         options: [
            { label: 'Edit', action: (row) => this.edit(row), condition: (row) => this.canEdit },
            {
               label: 'Details',
               action: (row) => this.edit(row),
               condition: (row) => !this.canEdit,
            },
            {
               label: 'Duplicate',
               action: (row) => this.duplicate(row),
               condition: (row) => this.canEdit,
            },
            {
               label: 'Delete',
               action: (row) => this.delete(row),
               condition: (row) => this.canEdit,
            },
         ],
      };
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   filterFormChanged(filter: any) {
      this.evolveFacade.updateActionItemsFilter(filter);
   }

   triggerSort(sort: Sort) {
      this.sort$.next(sort);
   }

   add() {
      this.evolveFacade.addActionItem();
   }

   edit(actionItem: ActionItem) {
      this.evolveFacade.editActionItem(actionItem);
   }

   delete(actionItem: ActionItem) {
      this.evolveFacade.deleteActionItem(actionItem);
   }
   setDisplayedColumns(columns: TableColumn<ActionItem>[]) {
      this.evolveFacade.setActionItemsDisplayedColumns(columns.map((col) => col.def));
   }
   export(filterData: boolean) {
      this.table.export(filterData);
   }
   duplicate(actionItem: ActionItem) {
      this.evolveFacade.duplicateActionItem(actionItem);
   }
}
