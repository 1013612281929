import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isArray, partition } from 'lodash';
import { map, withLatestFrom, catchError, switchMap, tap } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { searchActions } from './search.actions';
import { SearchService } from '../services/search.service';
import { State } from '@app/app.state';
import { Store, select } from '@ngrx/store';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { evolveSelectors } from '@app/evolve/state/evolve.state';
import { getTeamMembers } from '@app/team/state/team.state';
import { Goal } from '@entities/goal';
import { Rock } from '@entities/rock';
import { ActionItem } from '@entities/action-item';
import { TeamMember } from '@entities/team-member';
import { Router } from '@angular/router';
import { appRoutesNames } from '@app/app.routes.names';
import { documentationRouteNames } from '@app/documentation/documentation.routes.names';
import { teamRouteNames } from '@app/team/team.routes.names';
import { evolveRouteNames } from '@app/evolve/evolve.routes.names';
import {
   DepartmentFunctionViewModel,
   documentationSelectors,
} from '@app/documentation/state/documentation.state';
import { TaskDocumentationViewModel } from '@app/documentation/state/documentation.models';

@Injectable()
export class SearchEffects {
   // loadSearchs$ = createEffect(() => {
   //    return this.actions$.pipe(
   //       ofType(searchActions.loadSearch),
   //       switchMap(({ request }) => {
   //          return from(this.searchService.search(request)).pipe(
   //             map((results) => {
   //                return searchActions.searchLoaded({ results });
   //             }),
   //             catchError((error) => {
   //                return of(searchActions.searchError({ error }));
   //             })
   //          );
   //       })
   //    );
   // });

   searchLocal$ = createEffect(() => {
      return this.actions$.pipe(
         ofType(searchActions.quickSearch),
         withLatestFrom(
            this.store.pipe(select(documentationSelectors.getFunctionsViewModel)),
            this.store.pipe(select(documentationSelectors.getTasksViewModel)),
            this.store.pipe(select(evolveSelectors.getGoals)),
            this.store.pipe(select(evolveSelectors.getRocks)),
            this.store.pipe(select(evolveSelectors.getActionItems)),
            this.store.pipe(select(getTeamMembers))
         ),
         switchMap(
            ([{ term }, departmentFunctions, tasks, goals, rocks, actionItems, teamMembers]) => {
               const results = [];
               const getComments = (entity: any) => {
                  if (isArray(entity)) {
                     return entity;
                  } else {
                     return [];
                  }
               };
               if (term && term.length > 2) {
                  const deptFnId = (x) => x.departmentFunction?.id;
                  const deptFnName = (x) => x.departmentFunction?.name;
                  const deptFnContentFns = [
                     (x) => x.departmentFunction?.description,
                     (x) => x.departmentFunction?.purpose,
                     (x) => x.departmentFunction?.kpi,
                     (x) => x.departmentFunction?.policies,
                     (x) => x.departmentFunction?.benchmark,
                  ];
                  const deptFnResults = this.searchService.getResults(
                     departmentFunctions,
                     term,
                     deptFnName,
                     deptFnId,
                     deptFnContentFns
                  );
                  if (deptFnResults.length) {
                     const topScore = Math.max(...deptFnResults.map((result) => result.score));
                     results.push({
                        label: 'Functions',
                        ref: 'departmentFunction',
                        topScore,
                        items: deptFnResults.sort((a, b) => b.score - a.score),
                     });
                  }

                  const taskId = (x) => x.task?.id;
                  const taskName = (x) => x.task?.name;
                  const taskContentFns = [
                     (x) => x.task?.description,
                     (x) => x.task?.purpose,
                     (x) => x.task?.kpi,
                     (x) => x.task?.policies,
                     (x) => x.task?.benchmark,
                     (x) =>
                        (x.task?.steps?.length ? x.task.steps : [])
                           .map((step) => step.name)
                           .join(' '),
                     (x) =>
                        (x.task?.steps?.length ? x.task.steps : [])
                           .map((step) => step.instructions)
                           .reduce((a, b) => a.concat(b), ''),
                  ];
                  const taskResults = this.searchService.getResults(
                     tasks,
                     term,
                     taskName,
                     taskId,
                     taskContentFns
                  );
                  if (taskResults.length) {
                     const topScore = Math.max(...taskResults.map((result) => result.score));
                     results.push({
                        label: 'Tasks',
                        ref: 'task',
                        topScore,
                        items: taskResults.sort((a, b) => b.score - a.score),
                     });
                  }

                  const goalId = (x) => x.id;
                  const goalName = (x) => x.name;
                  const goalContentFns = [
                     (x) => x.description,
                     (x) =>
                        getComments(x.comments)
                           .map((comment) => comment.text)
                           .join(' '),
                  ];
                  const goalResults = this.searchService.getResults(
                     goals,
                     term,
                     goalName,
                     goalId,
                     goalContentFns
                  );
                  if (goalResults.length) {
                     const topScore = Math.max(...goalResults.map((result) => result.score));
                     results.push({
                        label: 'Goals',
                        ref: 'goal',
                        topScore,
                        items: goalResults.sort((a, b) => b.score - a.score),
                     });
                  }

                  const rockId = (x) => x.id;
                  const rockName = (x) => x.name;
                  const rockContentFns = [
                     (x) => x.description,
                     (x: Rock) =>
                        (x.actions?.length ? x.actions : []).map((a) => a.description)?.join(' '),
                     (x: Rock) =>
                        (x.actions?.length ? x.actions : []).map((a) => a.notes)?.join(' '),
                     (x) =>
                        getComments(x.comments)
                           .map((comment) => comment.text)
                           .join(' '),
                  ];
                  const rocksResults = this.searchService.getResults(
                     rocks,
                     term,
                     rockName,
                     rockId,
                     rockContentFns
                  );
                  if (rocksResults.length) {
                     const topScore = Math.max(...rocksResults.map((result) => result.score));
                     results.push({
                        label: 'Rocks',
                        ref: 'rock',
                        topScore,
                        items: rocksResults.sort((a, b) => b.score - a.score),
                     });
                  }

                  const actionItemId = (x) => x.id;
                  const actionItemName = (x) => x.name;
                  const actionItemContentFns = [
                     (x) => x.description,
                     (x) =>
                        getComments(x.comments)
                           .map((comment) => comment.text)
                           .join(' '),
                  ];
                  const actionItemResults = this.searchService.getResults(
                     actionItems,
                     term,
                     actionItemName,
                     actionItemId,
                     actionItemContentFns
                  );
                  if (actionItemResults.length) {
                     const topScore = Math.max(...actionItemResults.map((result) => result.score));
                     results.push({
                        label: 'Actions',
                        ref: 'actionItem',
                        topScore,
                        items: actionItemResults.sort((a, b) => b.score - a.score),
                     });
                  }
                  const teamMemberResults = teamMembers
                     .filter(
                        (x: TeamMember) =>
                           x.firstName?.toLowerCase().includes(term.toLowerCase()) ||
                           x.lastName?.toLowerCase().includes(term.toLowerCase())
                     )
                     .map((result) => ({
                        name: `${result.firstName} ${result.lastName}`,
                        id: result.id,
                        score:
                           term.length / (result.firstName.length + result.lastName.length) +
                           (result.firstName.toLowerCase().startsWith(term.toLowerCase()) ||
                           result.lastName.toLowerCase().startsWith(term.toLowerCase())
                              ? 2
                              : 1),
                        result,
                     }));
                  if (teamMemberResults.length) {
                     const topScore = Math.max(...teamMemberResults.map((result) => result.score));
                     results.push({
                        label: 'Team Members',
                        ref: 'teamMember',
                        topScore,
                        items: teamMemberResults.sort((a, b) => b.score - a.score),
                     });
                  }
               }
               results.sort((a, b) => b.topScore - a.topScore);
               return of(searchActions.searchLoaded({ results }));
            }
         )
      );
   });

   goToItem$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(searchActions.goToItem),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ item, category }, orgId]) => {
               let routes;
               switch (category) {
                  case 'Functions':
                     routes = [
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.DOCUMENTATION,
                        documentationRouteNames.FUNCTIONS,
                        item.id,
                     ];
                     break;
                  case 'Tasks':
                     routes = [
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.DOCUMENTATION,
                        documentationRouteNames.TASKS,
                        item.id,
                     ];
                     break;

                  case 'Goals':
                     routes = [
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.EVOLVE,
                        evolveRouteNames.GOALS,
                        item.id,
                     ];
                     break;

                  case 'Rocks':
                     routes = [
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.EVOLVE,
                        evolveRouteNames.ROCKS,
                        item.id,
                     ];
                     break;

                  case 'Actions':
                     routes = [
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.EVOLVE,
                        evolveRouteNames.ISSUES,
                        item.id,
                     ];
                     break;

                  case 'Team Members':
                     routes = [
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.TEAM,
                        teamRouteNames.TEAM_MEMBERS,
                        item.id,
                     ];
                     break;
               }
               if (routes) {
                  this.router.navigate(routes);
               }
            })
         ),
      { dispatch: false }
   );

   seeAllResults$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(searchActions.seeAllResults),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ term, category }, orgId]) => {
               this.router.navigate(
                  [appRoutesNames.ORGANIZATION, orgId, appRoutesNames.SEARCH, term],
                  { queryParams: { category } }
               );
            })
         ),
      { dispatch: false }
   );

   constructor(
      private actions$: Actions,
      private searchService: SearchService,
      private store: Store<State>,
      private router: Router
   ) {}
}
