<ng-container *ngIf="emailVerified">
   Thanks for verifying your email! You will be redirected to the homepage shortly.
</ng-container>
<ng-container *ngIf="error">
   <div *ngIf="error?.message; else generic">
      {{ error.message }}
   </div>
   <ng-template #generic>
      An error occurred. Please refresh the page to try again. If the problem persists, please
      contact support at support@alignedapps.com
   </ng-template>
</ng-container>
