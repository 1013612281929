import { Injectable, OnDestroy, inject } from '@angular/core';
import { Observable, combineLatest, of, Subject, from } from 'rxjs';
import { User } from '@entities/user';
import { Organization } from '@entities/organization';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import * as UserOrgActions from './user-org.actions';
import {
   getOrgsForUser,
   getOrganizationId,
   getOrganization,
   getCurrentUser,
   getUsersForAccount,
} from './user-org.selectors';

import { AuthUser } from '@entities/auth-user';
import { FunctionNames } from '@app/utilities/functionNames';
import { ProductType } from '@entities/enums/product-type';
import { adminActions } from '@app/admin/state/admin.actions';
import { FunctionsService } from '@app/shared/services/functions.service';
import { AuthUserService } from '@app/auth/services/auth-user.service';
import { ErrorService } from '@app/shared/services/error.service';
import { UserOrgService } from '../services/user-org.service';

@Injectable()
export class UserOrgFacade implements OnDestroy {
   orgsForUser$: Observable<Organization[]> = this.store.pipe(
      select(getOrgsForUser),
      map((orgs) => [...orgs].sort((a, b) => a?.name?.localeCompare(b?.name)))
   );
   selectedOrgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   selectedOrg$: Observable<Organization> = this.store.pipe(select(getOrganization));
   currentUser$: Observable<User> = this.store.pipe(select(getCurrentUser));
   usersForAccount$: Observable<User[]> = this.store.pipe(select(getUsersForAccount));
   authUser$: Observable<AuthUser>;

   private destroyed$ = new Subject<void>();
   private functions = inject(FunctionsService);
   private auth = inject(AuthUserService);
   private userOrgService = inject(UserOrgService);

   constructor(private store: Store<State>, private errorService: ErrorService) {
      this.authUser$ = this.auth.currentUser$;
      this.getUsersForAccount()
         .pipe(takeUntil(this.destroyed$))
         .subscribe((users) => {
            this.store.dispatch(UserOrgActions.UsersUpdated({ users }));
         });
      this.getOrgsForUser()
         .pipe(takeUntil(this.destroyed$))
         .subscribe((orgs) => {
            this.store.dispatch(UserOrgActions.OrganizationsUpdated({ orgs }));
         });
      this.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
         if (user && !user.products) {
            const updatedUser = {
               ...user,
               products: [ProductType.Complete],
            };
            this.store.dispatch(adminActions.SaveUser({ user: updatedUser }));
         }
      });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   private getOrgsForUser() {
      return this.authUser$.pipe(
         switchMap((authUser) => {
            if (authUser) {
               return from(this.userOrgService.getOrgs(authUser.uid));
            } else {
               return of([]);
            }
         })
      );
   }

   private getUsersForAccount() {
      return combineLatest([this.orgsForUser$, this.authUser$]).pipe(
         switchMap(([orgs, authUser]) => {
            if (authUser?.uid && orgs?.length > 0) {
               const orgIds = orgs.map((org) => org.organizationId);
               return from(this.userOrgService.getOrgUsers(orgIds, authUser.uid));
            } else {
               return of([]);
            }
         })
      );
   }

   createOrganization(organization: Organization) {
      this.store.dispatch(UserOrgActions.CreateOrganization({ organization }));
   }

   selectOrganization(organizationId: string) {
      this.store.dispatch(UserOrgActions.SelectOrganization({ organizationId }));
   }

   setOrganization(organizationId: string) {
      this.store.dispatch(UserOrgActions.SetOrganization({ organizationId }));
   }

   saveOrgInfo(organization: Organization) {
      this.store.dispatch(UserOrgActions.SaveOrgInfo({ organization }));
   }

   linkToDemoOrg(): Observable<any> {
      return from(this.functions.httpsCallable(FunctionNames.LINK_DEMO_ORG)({}));
   }

   acceptInvite(organizationId: string, inviteId: string, authId: string) {
      const data = {
         organizationId,
         inviteId,
         authId,
      };
      return this.functions.httpsCallable(FunctionNames.ACCEPT_INVITE)(data);
   }

   inviteAccepted(authUser: AuthUser, organizationId: string) {
      this.store.dispatch(UserOrgActions.InviteAccepted({ authUser, organizationId }));
   }

   getProducts(): Observable<any[]> {
      return from(this.functions.httpsCallable(FunctionNames.GET_PRODUCTS)(null)).pipe(
         map((result: any) => {
            return result?.data;
         })
      );
   }

   saveAuthUser(authUser: AuthUser) {
      this.store.dispatch(UserOrgActions.SaveAuthUser({ authUser }));
   }

   leaveOrganization(organization: Organization) {
      this.store.dispatch(UserOrgActions.LeaveOrganization({ organization }));
   }

   verifyEmail(authUser: AuthUser) {
      this.store.dispatch(UserOrgActions.VerifyEmail({ authUser }));
   }

   emailVerified(authUser: AuthUser) {
      this.store.dispatch(UserOrgActions.EmailVerified({ authUser }));
   }
}
