<!-- <div class="start-at-zero">
   <mat-radio-group
      class="radio-buttons"
      [(ngModel)]="startAtZero"
      (ngModelChange)="updateIndexes()"
   >
      <mat-radio-button [value]="true">Lowest value is 0</mat-radio-button>
      <mat-radio-button [value]="false">Lowest value is 1</mat-radio-button>
   </mat-radio-group>
</div> -->

<div class="header">
   <div class="value">Value</div>
   <div class="label">Label</div>
   <div class="description">Description</div>
</div>
<mat-list cdkDropList (cdkDropListDropped)="reorder($event)">
   <ng-container *ngFor="let level of competencyLevels">
      <mat-list-item cdkDrag cdkDragLockAxis="y" (click)="edit(level)">
         <div class="drag-preview" *cdkDragPreview></div>
         <div class="handle" cdkDragHandle>
            <mat-icon svgIcon="drag-vertical"></mat-icon>
         </div>
         <div class="value">{{ level.value }}</div>
         <div class="label">
            <ng-container *ngIf="showEdit(level); else displayLabel">
               <mat-form-field>
                  <input matInput [(ngModel)]="level.label" />
               </mat-form-field>
            </ng-container>
            <ng-template #displayLabel>
               {{ level.label }}
            </ng-template>
         </div>
         <div class="description">
            <ng-container *ngIf="showEdit(level); else displayDescription">
               <mat-form-field>
                  <input matInput [(ngModel)]="level.description" />
               </mat-form-field>
            </ng-container>
            <ng-template #displayDescription>
               {{ level.description }}
            </ng-template>
         </div>
         <div class="actions">
            <ng-container *ngIf="!showEdit(level)">
               <button mat-icon-button (click)="edit(level)">
                  <mat-icon>edit</mat-icon>
               </button>
               <button mat-icon-button (click)="deleteLevel($event, level)">
                  <mat-icon>delete</mat-icon>
               </button>
            </ng-container>
            <ng-container *ngIf="showEdit(level)">
               <button mat-icon-button (click)="save($event, level)">
                  <mat-icon>done</mat-icon>
               </button>
               <button mat-icon-button (click)="cancel($event, level)">
                  <mat-icon>close</mat-icon>
               </button>
            </ng-container>
         </div>
      </mat-list-item>
   </ng-container>
</mat-list>

<button mat-button color="primary" (click)="add()">
   <mat-icon>add</mat-icon>
   Add Competency Level
</button>
<div>
   <button mat-raised-button color="primary" [disabled]="!changes" (click)="saveAll()">
      Save Competency Levels
   </button>
</div>
