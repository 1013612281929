<div class="invite-accept">
   <div *ngIf="error; else processing">
      <div *ngIf="error.message; else generic" class="message error">
         {{ error.message }}
      </div>
      <ng-template #generic>
         <div class="message error">
            An error occurred. Please refresh the page to try again.
            <br />
            If the problem persists, please contact support at
            <a href="mailto:support@alignedapps.com" target="_blank">support@alignedapps.com</a>
         </div>
      </ng-template>
   </div>
   <ng-template #processing>
      <div class="processing">
         <div class="message">Please wait while we set up your access to this organization...</div>
         <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
   </ng-template>
</div>
