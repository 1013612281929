<div class="goals-container">
   <div class="header">
      <div class="title">Goals</div>
      <button
         mat-raised-button
         class="add-button"
         color="primary"
         *ngIf="canEdit$ | async"
         (click)="newGoal()"
      >
         <mat-icon>add</mat-icon>
         New Goal
      </button>
   </div>
   <mat-tab-group
      animationDuration="0ms"
      [selectedIndex]="activeTab$ | async"
      (selectedTabChange)="selectTab($event)"
   >
      <mat-tab label="Organization Goals">
         <app-table [config]="orgTableConfig" [filterBar]="orgFilterConfig"></app-table>
      </mat-tab>
      <mat-tab label="Business Unit Goals">
         <app-table [config]="buTableConfig" [filterBar]="buFilterConfig"></app-table>
      </mat-tab>
      <mat-tab label="Department Goals">
         <app-table [config]="deptTableConfig" [filterBar]="deptFilterConfig"></app-table>
      </mat-tab>
   </mat-tab-group>
</div>
