import { Route } from '@angular/router';
import { LoginComponent } from './auth/components/login/login.component';
import { CallbackComponent } from './auth/components/callback/callback.component';
import { SignupComponent } from './auth/components/signup/signup.component';

import { RoleGuard } from './admin/services/role.guard';
import { UserRole } from '@entities/enums/user-role';
import { AppAreas } from '@entities/enums/app-areas';
import { OverviewComponent } from './overview/overview.component';
import { EVOLVE_ROUTES } from './evolve/evolve.routes';
import { TEAM_ROUTES } from './team/team.routes';
import { SETTINGS_ROUTES } from './admin/admin.routes';
import { appRoutesNames } from './app.routes.names';
import { DOCUMENTATION_ROUTES } from './documentation/documentation.routes';
import { InviteAcceptComponent } from './user-org/components/invite-accept/invite-accept.component';
import { OrgSignupComponent } from './admin/components/org-signup/org-signup.component';
import { OrgListComponent } from './user-org/components/org-list/org-list.component';
import { OrgShellComponent } from './user-org/components/org-shell/org-shell.component';

import { AuthGuard } from './auth/services/auth.guard';
import { UserSettingsComponent } from './user-org/components/user-settings/user-settings.component';
import { DashboardComponent } from './dashboard/components/dashboard/dashboard.component';
import { MEETING_ROUTES } from './meetings/meetings.routes';
import { ProductType } from '@entities/enums/product-type';
import { ORG_BUILDER_ROUTES } from './org-builder/org-builder.routes';
import { StatusComponent } from './utilities/status/status.component';
import { PERFORMANCE_ROUTES } from './performance/performance.routes';
import { SaveChangesGuard } from './shared/services/save-changes.guard';
import { AdminCenterComponent } from './internal-tools/components/admin-center/admin-center.component';
import { InternalUserGuard } from './internal-tools/services/internal-user.guard.';
import { VisionReportComponent } from './overview/vision-report/vision-report.component';
import { UnauthorizedComponent } from './user-org/components/unauthorized/unauthorized.component';
import { INTERNAL_TOOLS_ROUTES } from './internal-tools/internal-tools.routes';
import { SearchResultsComponent } from './search/components/search-results/search-results.component';
import { VerifyEmailComponent } from './user-org/components/verify-email/verify-email.component';

export const ORG_ROUTES: Route[] = [
   {
      path: appRoutesNames.OVERVIEW,
      component: OverviewComponent,
      canActivate: [AuthGuard, RoleGuard],
      canDeactivate: [SaveChangesGuard],
      data: {
         products: [ProductType.Evolve, ProductType.Process],
         roles: [UserRole.Admin, UserRole.User],
         area: AppAreas.Overview,
      },
   },
   {
      path: `${appRoutesNames.OVERVIEW}/report`,
      component: VisionReportComponent,
      canActivate: [AuthGuard, RoleGuard],
      data: {
         products: [ProductType.Evolve, ProductType.Process],
         roles: [UserRole.Admin, UserRole.User],
         area: AppAreas.Overview,
      },
   },
   {
      path: appRoutesNames.PERFORMANCE,
      canActivate: [AuthGuard, RoleGuard],
      data: {
         area: AppAreas.Performance,
         products: [ProductType.Evolve],
         roles: [UserRole.Admin, UserRole.User],
      },
      children: PERFORMANCE_ROUTES,
   },
   {
      path: appRoutesNames.DOCUMENTATION,
      children: DOCUMENTATION_ROUTES,
      canActivate: [AuthGuard, RoleGuard],
      data: {
         products: [ProductType.Evolve, ProductType.Process],
         roles: [UserRole.Admin, UserRole.User],
         area: AppAreas.Documentation,
      },
   },
   {
      path: appRoutesNames.EVOLVE,
      canActivate: [AuthGuard, RoleGuard],
      data: {
         area: AppAreas.Evolve,
         products: [ProductType.Evolve],
         roles: [UserRole.Admin, UserRole.User],
      },
      children: EVOLVE_ROUTES,
   },
   {
      path: appRoutesNames.ORG_BUILDER,
      canActivate: [AuthGuard, RoleGuard],
      children: ORG_BUILDER_ROUTES,
      data: {
         products: [ProductType.Evolve, ProductType.Process],
         roles: [UserRole.Admin, UserRole.User],
         area: AppAreas.Organization,
      },
   },
   {
      path: appRoutesNames.TEAM,
      canActivate: [AuthGuard, RoleGuard],
      data: {
         area: AppAreas.Team,
         products: [ProductType.Evolve, ProductType.Process],
         roles: [UserRole.Admin, UserRole.User],
      },
      children: TEAM_ROUTES,
   },
   {
      path: appRoutesNames.MEETINGS,
      children: MEETING_ROUTES,
      canActivate: [AuthGuard, RoleGuard],
      data: {
         products: [ProductType.Evolve, ProductType.Process],
         roles: [UserRole.Admin, UserRole.User],
         area: AppAreas.Meetings,
      },
   },
   {
      path: appRoutesNames.SETTINGS,
      canActivate: [AuthGuard, RoleGuard],
      data: {
         area: AppAreas.Admin,
         products: [ProductType.Evolve, ProductType.Process],
         roles: [UserRole.Admin],
      },
      children: SETTINGS_ROUTES,
   },
   {
      path: appRoutesNames.DASHBOARD,
      component: DashboardComponent,
      canActivate: [AuthGuard, RoleGuard],
      data: {
         products: [ProductType.Evolve, ProductType.Process],
         roles: [UserRole.Admin, UserRole.User],
         area: AppAreas.Dashboard,
      },
   },
   {
      path: `${appRoutesNames.SEARCH}/:searchTerm`,
      component: SearchResultsComponent,
      canActivate: [AuthGuard],
   },
   { path: '', redirectTo: appRoutesNames.DASHBOARD, pathMatch: 'full' },
];

export const APP_ROUTES: Route[] = [
   {
      path: appRoutesNames.LOGIN,
      component: LoginComponent,
   },
   { path: appRoutesNames.CALLBACK, component: CallbackComponent },
   {
      path: appRoutesNames.SIGNUP,
      component: SignupComponent,
   },
   {
      path: `${appRoutesNames.INVITE}/:orgId/:inviteId`,
      component: InviteAcceptComponent,
      canActivate: [AuthGuard],
      data: { logoutRedirect: appRoutesNames.SIGNUP },
   },
   {
      path: appRoutesNames.NEWORG,
      component: OrgSignupComponent,
      canActivate: [AuthGuard],
   },
   {
      path: appRoutesNames.LANDING,
      component: OrgListComponent,
      canActivate: [AuthGuard],
   },
   {
      path: `${appRoutesNames.ORGANIZATION}/:orgId`,
      component: OrgShellComponent,
      children: ORG_ROUTES,
      canActivate: [AuthGuard],
   },
   { path: `${appRoutesNames.UNAUTHORIZED}`, component: UnauthorizedComponent },
   {
      path: appRoutesNames.USER_ACCOUNT,
      component: UserSettingsComponent,
      canActivate: [AuthGuard],
   },
   { path: appRoutesNames.STATUS, component: StatusComponent },
   {
      path: appRoutesNames.INTERNAL,
      component: AdminCenterComponent,
      canActivate: [InternalUserGuard],
      children: INTERNAL_TOOLS_ROUTES,
   },
   {
      path: `${appRoutesNames.VERIFY_EMAIL}/:uid`,
      component: VerifyEmailComponent,
      canActivate: [AuthGuard],
   },
   { path: '', redirectTo: appRoutesNames.LANDING, pathMatch: 'full' },
];
