import { Injectable, inject } from '@angular/core';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { ErrorService } from '@app/shared/services/error.service';
import { FirestoreService } from '@app/shared/services/firestore.service';
import { Organization } from '@entities/organization';
import { User } from '@entities/user';

@Injectable({
   providedIn: 'root',
})
export class UserOrgService {
   private errorService = inject(ErrorService);
   private firestore = inject(FirestoreService);

   async getOrgsAndUsers(uid: string) {
      const orgs = await this.getOrgs(uid);
      const users = await this.getOrgUsers(
         orgs.map((org) => org.organizationId),
         uid
      );
      return { orgs, users };
   }

   async getOrgs(uid: string) {
      try {
         const orgsCollection = this.firestore.collection(COLLECTION_NAMES.ORGANIZATIONS);
         const orgsSnapshots = this.firestore.queryCollection(
            orgsCollection,
            this.firestore.where('authUsers', 'array-contains', uid)
         );
         const orgs = (await orgsSnapshots).docs.map((doc) => doc.data() as Organization);
         orgs.sort((a, b) => a?.name?.localeCompare(b?.name));
         return orgs;
      } catch (error) {
         console.error(error);
         return [];
      }
   }

   async getOrgUsers(orgIds: string[], uid: string) {
      const users = await Promise.all(orgIds.map((orgId) => this.getOrgUser(orgId, uid)));
      return users.filter(Boolean);
   }

   async getOrgUser(orgId: string, uid: string) {
      const collection = this.firestore.collection(
         COLLECTION_NAMES.ORGANIZATIONS,
         orgId,
         COLLECTION_NAMES.USERS
      );
      const snapshot = await this.firestore.queryCollection(
         collection,
         this.firestore.where('userAccountId', '==', uid)
      );
      if (snapshot.docs.length > 0) {
         if (snapshot.docs.length > 1) {
            const message = `multiple users for account ${uid} on org ${orgId} `;
            this.errorService.handleError(new Error(message), message, false, false);
         }
         const user: User = snapshot.docs[0].data() as User;
         return user;
      } else {
         return null;
      }
   }
}

