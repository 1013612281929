<mat-card>
   <mat-card-title>
      Linked Goals
      <div class="link-button">
         <button mat-raised-button color="primary" *ngIf="canEdit" (click)="linkGoal()">
            Link Goal
         </button>
      </div>
   </mat-card-title>
   <mat-card-content>
      <app-table [config]="tableConfig"></app-table>
   </mat-card-content>
</mat-card>

<ng-template #linkGoalDialogRef>
   <div mat-dialog-title>Link Goal</div>
   <mat-dialog-content>
      <mat-form-field>
         <mat-label>Goal</mat-label>
         <input
            matInput
            [formControl]="goalToLinkFormControl"
            [matAutocomplete]="goalAutocomplete"
         />
         <span *ngIf="goalToLinkSelected" class="clear-selection" (click)="clear()">
            <mat-icon>close</mat-icon>
         </span>
         <mat-autocomplete
            #goalAutocomplete="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="onOptionSelect($event)"
         >
            <mat-option *ngFor="let goal of filteredGoals$ | async" [value]="goal">
               {{ goal.name }}
            </mat-option>
         </mat-autocomplete>
      </mat-form-field>
   </mat-dialog-content>
   <mat-dialog-actions>
      <button
         mat-raised-button
         color="primary"
         [disabled]="!goalToLinkSelected"
         (click)="addLinkedGoal()"
      >
         Link
      </button>
      <button mat-stroked-button color="primary" matDialogClose>Cancel</button>
   </mat-dialog-actions>
</ng-template>
