import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnDestroy,
   OnInit,
   Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { froalaConfig } from '@app/shared/config/froala.config';
import { ErrorService } from '@app/shared/services/error.service';
import { StorageService } from '@app/shared/services/storage.service';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

@Component({
   selector: 'app-text-editor',
   templateUrl: './text-editor.component.html',
   styleUrls: ['./text-editor.component.scss'],
})
export class TextEditorComponent implements OnInit, OnChanges, OnDestroy {
   @Input() config = froalaConfig;
   @Input() formCtrl: UntypedFormControl;
   @Input() label: string;
   @Input() value: string;
   @Input() alwaysShowLabel = false;
   @Input() canEdit = false;
   @Output() valueChange = new EventEmitter();

   valueCopy: string;
   editor: any;
   editorConfig: any;
   orgId?: string;

   private destroyed$ = new Subject<void>();

   constructor(
      private storageService: StorageService,
      private errorService: ErrorService,
      private userOrgFacade: UserOrgFacade
   ) {}

   ngOnInit(): void {
      this.userOrgFacade.selectedOrgId$.pipe(takeUntil(this.destroyed$)).subscribe((orgId) => {
         this.orgId = orgId;
      });
      if (!this.formCtrl) {
         this.formCtrl = new UntypedFormControl();
         if (this.value) {
            this.formCtrl.setValue(this.value);
         }
      }
      this.formCtrl.valueChanges
         .pipe(takeUntil(this.destroyed$))
         .subscribe((value) => this.valueChange.emit(value));
      this.editorConfig = {
         ...this.config,
         events: {
            'image.beforeUpload': (images) => {
               const path = `${this.orgId}/images/${uuid()}`;
               this.storageService.upload(path, images[0]).subscribe({
                  next: (url) => {
                     this.editor.image.insert(url, null, null, this.editor.image.get());
                     this.editor.popups.hideAll();
                  },
                  error: (err) => {
                     this.errorService.handleError(err, 'Error uploading image');
                  },
               });
            },
         },
      };
   }

   ngOnChanges() {
      if (this.value && this.valueCopy !== this.value) {
         this.valueCopy = this.value;
         this.formCtrl?.setValue(this.value);
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   initFroala(controls) {
      controls.initialize();
      this.editor = controls.getEditor();
   }

   focusEditor() {
      this.editor.events.focus();
   }
}
