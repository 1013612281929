import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnInit,
   Output,
   SimpleChanges,
   TemplateRef,
   ViewChild,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TrainingPlan } from '@entities/training-plan';
import { Status } from '@entities/enums/status';
import { getKeys } from '@app/utilities/getKeys';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Sort } from '@angular/material/sort';
import { TableService } from '@app/shared/services/table.service';
import { TaskRating } from '@entities/task-rating';
import { CompetencyLevel } from '@entities/competency-level';
import {
   MatLegacyDialog as MatDialog,
   MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import * as moment from 'moment';
import { AssignedTaskViewModel } from '@app/documentation/state/documentation.models';
import { deepCopy } from '@app/utilities/deep-copy';
import { UserComment } from '@entities/user-comment';
import { v4 as uuid } from 'uuid';
import { FilterBarConfig, FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatStepper } from '@angular/material/stepper';
import { TeamFacade } from '@app/team/state/team.facade';
import { DialogService } from '@app/shared/services/dialog.service';
import { DatePipe } from '@angular/common';
import { TableConfig } from '@app/shared/components/table/table.component';
import { RoleGuard } from '@app/admin/services/role.guard';
import { AppAreas } from '@entities/enums/app-areas';
import { DocumentationFacade } from '@app/documentation/state/documentation.facade';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';

@Component({
   selector: 'app-training-plans',
   templateUrl: './training-plans.component.html',
   styleUrls: ['./training-plans.component.scss'],
})
export class TrainingPlansComponent implements OnInit, OnChanges {
   @Input() trainingPlans: TrainingPlan[] = [];
   @Input() tasks: AssignedTaskViewModel[] = [];
   @Input() taskRatings: TaskRating[] = [];
   @Input() competencyLevels: CompetencyLevel[] = [];
   @Input() departments: Department[] = [];
   @Output() trainingPlansUpdated = new EventEmitter<TrainingPlan[]>();
   @Output() trainingPlanCompleted = new EventEmitter<TrainingPlan>();
   @Output() viewTask = new EventEmitter<string>();

   @ViewChild('generateTrainingPlansDialogTemplate')
   generateTrainingPlansDialogTemplate: TemplateRef<any>;
   generateTrainingPlansDialogRef: MatDialogRef<any>;

   @ViewChild('editTrainingPlanDialogTemplate')
   editTrainingPlanDialogTemplate: TemplateRef<any>;
   editTrainingPlansDialogRef: MatDialogRef<any>;

   @ViewChild(MatStepper) planGenerationStepper: MatStepper;

   editIds: string[] = [];
   editedTrainingPlans: TrainingPlan[] = [];

   status = Status;
   statusKeys = getKeys(Status);

   displayedColumns = [
      'task.name',
      'status',
      'startRating',
      'targetRating',
      'startDate',
      'targetDate',
      'completedDate',
      'options',
   ];
   taskListColumns = [
      'checkbox',
      'task',
      'departmentFunction',
      'department',
      'businessUnit',
      'rating',
   ];
   dataSource$: Observable<TrainingPlan[]>;
   filter$ = this.teamFacade.tasksFilter$;
   sort$: BehaviorSubject<Sort> = new BehaviorSubject({ direction: 'asc', active: 'task.name' });
   rows$ = new BehaviorSubject<TrainingPlan[]>([]);
   taskRatings$ = new BehaviorSubject<TaskRating[]>([]);
   tasks$ = this.teamFacade.assignedTasks$;

   canEdit$ = this.roleGuard.canEdit([], AppAreas.Team);

   planGenerationDialog: MatDialogRef<any>;
   planGenerationTaskIds: string[] = [];
   planGenerationTargetRating: CompetencyLevel;
   planGenerationTargetDate: string;

   competencyLevelMap: { [key: number]: string };

   editTrainingPlan: TrainingPlan;
   planCompleted = false;

   taskRatingsDataSource$: Observable<TaskRating[]>;
   taskRatingsFilter$ = new BehaviorSubject<FilterValues>({});
   taskRatingsSort$ = new BehaviorSubject<Sort>({ active: 'task.name', direction: 'asc' });
   visibleTaskRatings: TaskRating[] = [];

   tableConfig: TableConfig;
   filterConfig: FilterBarConfig;
   taskRatingsFilterConfig: FilterBarConfig;
   /* istanbul ignore next */
   taskRatingsColumns: TableColumn<TaskRating>[] = [
      { label: '', def: 'checkbox', value: (row) => this.isSelected(row), visible: true },
      {
         label: 'Task',
         def: 'task.name',
         value: (row) => this.taskViewModel(row.taskId)?.task?.name,
         visible: true,
      },
      {
         label: 'Function',
         def: 'departmentFunction',
         value: (row) => this.taskViewModel(row.taskId)?.departmentFunction?.name,
         visible: true,
         filter: 'departmentFunctions',
      },
      {
         label: 'Department',
         def: 'department',
         value: (row) => this.taskViewModel(row.taskId)?.department?.name,
         visible: true,
         filter: 'departments',
      },
      {
         label: 'Business Unit',
         def: 'businessUnit',
         value: (row) => this.taskViewModel(row.taskId)?.businessUnit?.name,
         visible: true,
         filter: 'businessUnits',
      },
      { label: 'Rating', def: 'rating', value: (row) => row.rating, visible: true },
   ];

   /* istanbul ignore next */
   trainingPlanColumns: TableColumn<TrainingPlan>[] = [
      {
         label: 'Task',
         def: 'task.name',
         value: (row) => this.taskViewModel(row.taskId)?.task?.name,
         visible: true,
      },
      {
         label: 'Status',
         def: 'status',
         value: (row) => this.status[row.status],
         visible: true,
         filter: 'status',
      },
      {
         label: 'Start Rating',
         def: 'startRating',
         value: (row) => this.competencyLevelMap[row.startRating],
         visible: true,
      },
      {
         label: 'Target Rating',
         def: 'targetRating',
         value: (row) => this.competencyLevelMap[row.targetRating],
         visible: true,
      },
      {
         label: 'Start Date',
         def: 'startDate',
         isDate: true,
         value: (row) => this.datePipe.transform(row.startDate),
         visible: true,
      },
      {
         label: 'Target Date',
         def: 'targetDate',
         isDate: true,
         value: (row) => this.datePipe.transform(row.targetDate),
         visible: true,
      },
      {
         label: 'Completed Date',
         def: 'completedDate',
         isDate: true,
         value: (row) => this.datePipe.transform(row.completedDate),
         visible: true,
      },
      {
         label: 'Department',
         def: 'department',
         value: (row) => this.getDepartmentName(row.taskId),
         visible: false,
         filter: 'departments',
      },
   ];

   /* istanbul ignore next */
   taskColumns: TableColumn<AssignedTaskViewModel>[] = [
      { def: 'task.name', label: 'Task', visible: true, value: (row) => row.task.name },
      {
         def: 'departmentFunction.name',
         label: 'Function',
         visible: true,
         value: (row) => row.departmentFunction.name,
         filter: 'departmentFunctions',
      },
      {
         def: 'department.name',
         label: 'Department',
         visible: true,
         value: (row) => row.department.name,
         filter: 'departments',
      },
      {
         def: 'businessUnit.name',
         label: 'BusinessUnit',
         visible: true,
         value: (row) => row.businessUnit.name,
         filter: 'businessUnits',
      },
      {
         def: 'taskRating.rating',
         label: 'Rating',
         value: (row) => this.competencyLevelMap[row.taskRating?.rating],
         visible: true,
         filter: 'rating',
         filterValue: (row) => row.taskRating?.rating,
      },
   ];

   private destroyed$ = new Subject<void>();

   constructor(
      private tableService: TableService,
      private dialog: MatDialog,
      private teamFacade: TeamFacade,
      private dialogService: DialogService,
      private datePipe: DatePipe,
      private roleGuard: RoleGuard
   ) {}

   ngOnInit() {
      this.rows$.next(this.trainingPlans);
      if (this.competencyLevels) {
         this.competencyLevelMap = {};
         this.competencyLevels.forEach((level) => {
            this.competencyLevelMap[level.value] = level.label;
         });
      }
      this.setFilter();
      this.taskRatingsDataSource$ = combineLatest([
         this.taskRatings$,
         this.taskRatingsFilter$,
         this.taskRatingsSort$,
      ]).pipe(
         map(([rows, filter, sort]) => {
            const filtered: TaskRating[] = this.tableService.fullFilterAndSort(
               rows,
               filter,
               sort,
               this.taskRatingsColumns
            );
            this.visibleTaskRatings = filtered;
            return this.visibleTaskRatings;
         })
      );
      this.tableConfig = {
         debug: 'training-plans',
         data$: this.rows$,
         filter$: this.filter$,
         initialSort: { active: 'task.name', direction: 'asc' },
         columns: this.trainingPlanColumns,
         departments: this.departments,
         visibleColumns$: of([]),
         rowClick: (row) => this.edit(row),
         options: [
            {
               label: 'View Task',
               action: (row) => this.goToTask(row),
            },
            {
               label: 'Edit',
               action: (row) => this.edit(row),
            },
            {
               label: 'Delete',
               action: (row) => this.deletePlan(row),
            },
         ],
      };

      this.roleGuard
         .canEdit([], AppAreas.Team)
         .pipe(takeUntil(this.destroyed$))
         .subscribe((canEdit) => {
            if (canEdit) {
               this.tableConfig.options = [
                  {
                     label: 'View Task',
                     action: (row) => this.goToTask(row),
                  },
                  {
                     label: 'Edit',
                     action: (row) => this.edit(row),
                  },
                  {
                     label: 'Delete',
                     action: (row) => this.deletePlan(row),
                  },
               ];
            } else {
               this.tableConfig.options = [];
            }
         });
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['trainingPlans']) {
         this.trainingPlans?.forEach((plan) => {
            if (!plan.id) {
               plan.id = uuid();
            }
         });
      }
      if (changes['competencyLevels']) {
         this.setFilter();
      }
      if (changes['taskRatings']) {
         this.taskRatings$.next(this.taskRatings);
      }
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   setFilter() {
      this.filterConfig = {
         businessUnits: true,
         departments: true,
         departmentFunctions: true,
         rating: this.competencyLevelMap,
         status: Status,
         filterChange: (filter) => this.teamFacade.setTeamTasksFilter(filter),
      };
      this.taskRatingsFilterConfig = {
         businessUnits: true,
         departments: true,
         departmentFunctions: true,
         rating: this.competencyLevelMap,
         filterChange: (filter) => this.taskRatingsFilter$.next(filter),
      };
   }

   edit(trainingPlan: TrainingPlan) {
      this.editTrainingPlan = deepCopy(trainingPlan);
      if (!this.editTrainingPlan.comments) {
         this.editTrainingPlan.comments = [];
      }
      if (!this.editTrainingPlan.id) {
         this.editTrainingPlan.id = uuid();
      }
      this.editTrainingPlansDialogRef = this.dialog.open(this.editTrainingPlanDialogTemplate, {
         width: '800px',
         maxWidth: '90vw',
      });
   }

   goToTask(trainingPlan: TrainingPlan) {
      this.viewTask.emit(trainingPlan.taskId);
   }

   cancel() {
      this.editTrainingPlansDialogRef.close();
      this.planCompleted = false;
   }

   save() {
      this.trainingPlans = [
         ...this.trainingPlans.filter((t) => t.id !== this.editTrainingPlan.id),
         this.editTrainingPlan,
      ];
      this.trainingPlansUpdated.emit(this.trainingPlans);
      if (this.planCompleted) {
         this.trainingPlanCompleted.emit(this.editTrainingPlan);
      }
      this.rows$.next(this.trainingPlans);
      this.editTrainingPlansDialogRef.close();
      this.planCompleted = false;
   }

   openGeneratePlansDialog() {
      this.generateTrainingPlansDialogRef = this.dialog.open(
         this.generateTrainingPlansDialogTemplate,
         {
            maxWidth: '80vw',
            data: {
               tasks: this.tasks,
               competencyLevels: this.competencyLevels,
               taskRatings: this.taskRatings,
            },
         }
      );
   }

   showPlanStepperNext() {
      if (this.planGenerationStepper) {
         return (
            this.planGenerationStepper.selectedIndex < this.planGenerationStepper.steps?.length - 1
         );
      } else {
         return false;
      }
   }

   showPlanStepperPrevious() {
      return this.planGenerationStepper?.selectedIndex > 0;
   }

   planStepperNext() {
      this.planGenerationStepper?.next();
   }

   planStepperPrevious() {
      this.planGenerationStepper?.previous();
   }

   generatePlans() {
      const newTrainingPlans = [];
      let ratingsToGenerate: TaskRating[] = [];
      ratingsToGenerate = this.taskRatings.filter((r) =>
         this.planGenerationTaskIds.includes(r.taskId)
      );

      ratingsToGenerate.forEach((rating) => {
         const planExists = this.trainingPlans.some(
            (plan) =>
               plan.taskId === rating.taskId &&
               (plan.targetRating === this.planGenerationTargetRating.value ||
                  plan.targetRatingId === this.planGenerationTargetRating.id)
         );
         if (!planExists) {
            newTrainingPlans.push({
               id: uuid(),
               taskId: rating.taskId,
               status: Status['Not Started'],
               startRating: rating.rating,
               startRatingId: rating.competencyLevelId,
               targetRating: this.planGenerationTargetRating.value,
               targetRatingId: this.planGenerationTargetRating.id,
               startDate: moment().toISOString(),
               targetDate: this.planGenerationTargetDate,
               completedDate: null,
            });
         }
      });
      this.trainingPlans = [...this.trainingPlans, ...newTrainingPlans];
      this.rows$.next(this.trainingPlans);
      this.trainingPlansUpdated.emit(this.trainingPlans);
      this.generateTrainingPlansDialogRef?.close();
      this.planGenerationTargetDate = null;
      this.planGenerationTargetRating = null;
      this.planGenerationTaskIds = [];
   }

   showEdit(trainingPlan: TrainingPlan) {
      return this.editIds.includes(trainingPlan.taskId);
   }

   setDate(
      event: MatDatepickerInputEvent<moment.Moment>,
      trainingPlan: TrainingPlan,
      property: string
   ) {
      const date = event.value;
      const value = date ? date.toISOString() : null;
      trainingPlan[property] = value;
   }

   setTargetDate(event: MatDatepickerInputEvent<moment.Moment>) {
      const date = event.value;
      const value = date ? date.toISOString() : null;
      this.planGenerationTargetDate = value;
   }

   triggerSort(sort: Sort) {
      this.sort$.next(sort);
   }

   taskViewModel(id: string) {
      return this.tasks.find((t) => t.task.id === id);
   }

   isSelected(taskRating: TaskRating) {
      return this.planGenerationTaskIds.includes(taskRating.taskId);
   }

   toggleSelected(taskRating: TaskRating) {
      if (this.isSelected(taskRating)) {
         this.planGenerationTaskIds = this.planGenerationTaskIds.filter(
            (id) => id !== taskRating.taskId
         );
      } else {
         this.planGenerationTaskIds.push(taskRating.taskId);
      }
   }

   allSelected() {
      return this.visibleTaskRatings
         .map((rating) => this.planGenerationTaskIds.includes(rating.taskId))
         .reduce((a, b) => a && b, true);
   }

   toggleSelectAll(event: MatCheckboxChange) {
      if (event.checked) {
         this.visibleTaskRatings.forEach((rating) => {
            if (!this.planGenerationTaskIds.includes(rating.taskId)) {
               this.planGenerationTaskIds.push(rating.taskId);
            }
         });
      } else {
         this.planGenerationTaskIds = [];
      }
   }

   setComments(comments: UserComment[]) {
      this.editTrainingPlan.comments = comments;
   }

   deletePlan(plan: TrainingPlan) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Training Plan?',
            message: 'Do you want to delete this training plan?',
            confirm: 'Yes, delete',
            deny: 'No, cancel',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.trainingPlans = this.trainingPlans.filter((p) => p.id !== plan.id);
               this.rows$.next(this.trainingPlans);
               this.trainingPlansUpdated.emit(this.trainingPlans);
            }
         });
   }

   checkCompleted() {
      if (this.editTrainingPlan.status === Status.Completed) {
         this.editTrainingPlan.completedDate = moment().toISOString();
         this.planCompleted = true;
      }
   }

   getDepartmentName(taskId: string) {
      const viewModel = this.tasks.find((vm) => vm.task?.id === taskId);
      return viewModel.department.name;
   }
}
