import {
   Component,
   OnInit,
   ViewChildren,
   QueryList,
   ElementRef,
   OnDestroy,
   ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Task } from '@entities/task';
import {
   UntypedFormGroup,
   UntypedFormControl,
   UntypedFormArray,
   Validators,
   FormControl,
} from '@angular/forms';
import { Step } from '@entities/step';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { TeamMember } from '@entities/team-member';
import { takeUntil } from 'rxjs/operators';
import { SaveChangesComponent } from '@app/shared/services/save-changes.guard';
import { DocumentationFacade } from '@app/documentation/state/documentation.facade';
import { froalaConfig } from '@app/shared/config/froala.config';
import { appRoutesNames } from '@app/app.routes.names';
import { teamRouteNames } from '@app/team/team.routes.names';
import { DialogService } from '@app/shared/services/dialog.service';
import { RoleGuard } from '@app/admin/services/role.guard';
import { StorageService } from '@app/shared/services/storage.service';
import { v4 as uuid } from 'uuid';
import { ErrorService } from '@app/shared/services/error.service';
import { AppAreas } from '@entities/enums/app-areas';
@Component({
   selector: 'app-documentation-task-edit',
   templateUrl: './documentation-task-edit.component.html',
   styleUrls: ['./documentation-task-edit.component.scss'],
})
export class DocumentationTaskEditComponent implements OnInit, SaveChangesComponent, OnDestroy {
   task: Task;
   businessUnitName: string;
   departmentName: string;
   departmentFunctionName: string;
   canEdit = false;

   assignedTeamMembers$ = this.documentationFacade.assignedTeamMembers$;

   editAction = 'Edit';
   form: UntypedFormGroup;
   taskId: string;
   source: string;
   showEditName = false;
   title: string;
   froalaConfig = {
      ...froalaConfig,
      height: 250,
   };

   editors: any[] = [];

   orgId = 'unknownOrg';
   expandedStep = 0;

   configs = [];
   purposeConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Purpose',
      minHeight: 200,
   };
   policyConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Policies',
      minHeight: 200,
   };
   kpiConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'KPI',
      minHeight: 200,
   };
   benchmarkConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Benchmark',
      minHeight: 200,
   };
   competenciesConfig = {
      ...froalaConfig,
      editorClass: 'editor',
      toolbarInline: true,
      inlineMode: false,
      placeholderText: 'Competencies',
      minHeight: 200,
   };

   uploading = false;

   @ViewChildren('stepName') stepNameInputs: QueryList<ElementRef>;

   private destroyed$ = new Subject<void>();

   constructor(
      private documentationFacade: DocumentationFacade,
      private router: Router,
      private route: ActivatedRoute,
      private dialogService: DialogService,
      private roleGuard: RoleGuard,
      private storageService: StorageService,
      private errorService: ErrorService
   ) {}

   ngOnInit() {
      this.form = new UntypedFormGroup({
         id: new UntypedFormControl(),
         name: new UntypedFormControl(),
         departmentFunctionId: new UntypedFormControl(),
         steps: new UntypedFormArray([]),
         purpose: new UntypedFormControl(),
         benchmark: new UntypedFormControl(),
         kpi: new UntypedFormControl(),
         policies: new UntypedFormControl(),
         competencies: new UntypedFormControl(),
      });
      if (history.state) {
         this.source = history.state.source;
         const viewModel = history.state.viewModel;
         if (viewModel) {
            this.setTask(viewModel.task);
            this.businessUnitName = viewModel.businessUnit.name;
            this.departmentName = viewModel.department.name;
            this.departmentFunctionName = viewModel.departmentFunction.name;
            this.form.markAsDirty();
         }
      }
      this.route.params.pipe(takeUntil(this.destroyed$)).subscribe((params) => {
         if (params['taskId'] && params['taskId'] != 'new') {
            this.documentationFacade.selectTask(params['taskId']);
         }
      });

      this.documentationFacade.org$.pipe(takeUntil(this.destroyed$)).subscribe((org) => {
         this.orgId = org?.organizationId;
      });
      this.documentationFacade.selectedTaskViewModel$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((viewModel) => {
            if (viewModel && viewModel.task) {
               this.setTask(viewModel.task);
               this.businessUnitName = viewModel.businessUnit.name;
               this.departmentName = viewModel.department.name;
               this.departmentFunctionName = viewModel.departmentFunction.name;
            } else {
               this.editAction = 'Add';
            }
         });

      this.roleGuard
         .canEdit([], AppAreas.Documentation, this.task?.assignees)
         .pipe(takeUntil(this.destroyed$))
         .subscribe((canEdit) => {
            this.canEdit = canEdit;
            if (canEdit) {
               this.form.enable();
            } else {
               this.form.disable();
            }
         });
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   setTask(task: Task) {
      this.task = { ...task, policies: task.policies || task.description };
      if (task.id) {
         this.editAction = 'Edit';
         this.taskId = task.id;
      } else {
         this.editAction = 'Add';
      }
      if (!this.form.dirty) {
         this.form.patchValue(task);
         this.form.markAsPristine();
      }
      if (task.steps) {
         const steps = [...task.steps];
         steps.sort((a, b) => a.order - b.order);
         this.form.setControl('steps', new UntypedFormArray([]));
         for (let i = 0; i < task.steps.length; i++) {
            this.addStepControl(i);
            this.setStepControl(i, steps[i]);
            this.configs[i] = this.generateFroalaConfig(i);
         }
      }
   }

   save(onDeactivate: boolean = false) {
      const task = {
         ...this.task,
         ...this.form.value,
      };
      this.form.markAsPristine();
      this.documentationFacade.saveTask(task, onDeactivate);
   }

   delete() {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Task?',
            message: 'Are you sure you want to delete this task?',
            confirm: 'Yes, Delete',
            deny: 'No, Go Back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.documentationFacade.deleteTask(this.task);
            }
         });
   }

   print() {
      this.router.navigate(['report'], { relativeTo: this.route });
   }

   back() {
      if (this.source) {
         this.router.navigateByUrl(this.source);
      } else {
         history.back();
      }
   }

   editName() {
      this.title = this.form.value.name;
      this.showEditName = true;
   }

   updateName() {
      this.showEditName = false;
   }

   cancelUpdateName() {
      this.showEditName = false;
      this.form.get('name').setValue(this.title);
   }

   addStep() {
      const step = new Step();
      step.order = (<UntypedFormArray>this.form.controls.steps).length;
      this.configs[step.order] = this.generateFroalaConfig(step.order);
      this.addStepControl(step.order);
      this.setStepControl(step.order, step);
      setTimeout(() => {
         this.stepNameInputs.last.nativeElement.focus();
      }, 200);
   }

   remove(index: number) {
      (<UntypedFormArray>this.form.controls.steps).removeAt(index);
      this.form.markAsDirty();
   }

   addStepControl(index: number) {
      (<UntypedFormArray>this.form.controls.steps).insert(
         index,
         new UntypedFormGroup({
            stepId: new UntypedFormControl(),
            name: new UntypedFormControl(null, Validators.required),
            order: new UntypedFormControl(),
            instructions: new UntypedFormControl(),
            taskId: new UntypedFormControl(),
         })
      );
   }

   setStepControl(index: number, step: Step) {
      (<UntypedFormArray>this.form.controls.steps).at(index).patchValue(step);
   }

   drop(event: CdkDragDrop<UntypedFormGroup>) {
      moveItemInArray(
         (<UntypedFormArray>this.form.controls.steps).controls,
         event.previousIndex,
         event.currentIndex
      );
      this.reorderSteps();
   }

   reorderSteps() {
      for (let i = 0; i < (<UntypedFormArray>this.form.controls.steps).controls.length; i++) {
         (<UntypedFormArray>this.form.controls.steps).controls[i].get('order').setValue(i);
      }
      this.form.markAsDirty();
   }

   stepControls() {
      return (<UntypedFormArray>this.form.controls.steps).controls;
   }

   stepControl(index: number): UntypedFormGroup {
      return (<UntypedFormArray>this.form.controls.steps).controls[index] as UntypedFormGroup;
   }

   assignTeamMembers() {
      // if (this.form.valid) {
      //    this.save();
      // }
      this.documentationFacade.assignTaskToTeamMember(this.task);
   }

   unassignTeamMember(teamMember: TeamMember) {
      this.documentationFacade.unassignTeamMember(this.task, teamMember);
   }

   viewTeamMember(teamMember: TeamMember) {
      this.router.navigate(
         ['../..', appRoutesNames.TEAM, teamRouteNames.TEAM_MEMBERS, teamMember.id],
         {
            relativeTo: this.route.parent,
         }
      );
   }

   initFroala(controls, index) {
      controls.initialize();
      this.editors[index] = controls.getEditor();
   }

   focusInstructions(index: number, expanded: boolean) {
      const element = document.getElementById(`instructions-${index}`);
      if (element && expanded) {
         this.expandedStep = index;
         const editor = element['data-froala.editor'];
         if (editor) {
            setTimeout(
               (e) => {
                  e.events.focus();
               },
               200,
               editor
            );
         }
      }
   }

   updateInstructions(value: string, index: number) {
      this.stepControls()[index].setValue(value);
      this.form.markAsDirty();
   }

   getControl(control: string) {
      return this.form.get(control) as FormControl;
   }

   private generateFroalaConfig(index: number) {
      return {
         ...this.froalaConfig,
         events: {
            'image.beforeUpload': (images) => {
               this.uploading = true;
               const editor = this.editors[index];
               const path = `${this.orgId}/tasks/${this.taskId}/${uuid()}`;
               this.storageService.upload(path, images[0]).subscribe({
                  next: (url) => {
                     editor.image.insert(url, null, null, editor.image.get());
                     editor.popups.hideAll();
                     this.uploading = false;
                  },
                  error: (err) => {
                     this.uploading = false;
                     this.errorService.handleError(err, 'Error uploading image');
                  },
               });
            },
         },
      };
   }
}
