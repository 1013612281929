<app-sticky-header
   [title]="header$ | async"
   [saveDisabled]="saveDisabled()"
   [isNew]="isNew()"
   [report]="true"
   [canEdit]="canEdit$ | async"
   (back)="back()"
   (save)="save()"
   (showReport)="print()"
   (delete)="delete()"
></app-sticky-header>
<form [formGroup]="form">
   <mat-card>
      <mat-card-title>
         <div class="breadcrumbs">
            <ng-container *ngIf="businessUnitName">
               <div class="breadcrumb-text">
                  {{ businessUnitName }}
               </div>
               <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
            </ng-container>
            <ng-container *ngIf="departmentName">
               <div class="breadcrumb-text">
                  {{ departmentName }}
               </div>
               <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
            </ng-container>
         </div>
         <div class="title-container">
            <ng-container *ngIf="showEditName; else title">
               <div class="field-container">
                  <mat-form-field class="form-field">
                     <input appAutoFocus matInput required type="text" formControlName="name" />
                  </mat-form-field>
                  <button mat-icon-button (click)="updateName()">
                     <mat-icon>done</mat-icon>
                  </button>
                  <button mat-icon-button (click)="cancelUpdateName()">
                     <mat-icon>close</mat-icon>
                  </button>
               </div>
            </ng-container>
            <ng-template #title>
               <div class="function-name">
                  {{ form.value.name }}
               </div>
               <button mat-icon-button *ngIf="canEdit$ | async" (click)="editName()">
                  <mat-icon>edit</mat-icon>
               </button>
            </ng-template>
         </div>
      </mat-card-title>
      <mat-card-content>
         <div class="row">
            <div class="col-md-6">
               <app-team-member-autocomplete
                  class="form-field"
                  [idFormControl]="getControl('leadId')"
                  [canEdit]="canEdit$ | async"
                  (optionSelected)="setLeadId($event)"
                  placeholder="Lead"
               ></app-team-member-autocomplete>
            </div>
            <div class="col-md-6">
               <mat-form-field class="form-field" [ngClass]="{ 'read-only': !(canEdit$ | async) }">
                  <mat-label>Documentation Status</mat-label>
                  <mat-select formControlName="documentationStatus">
                     <mat-option *ngFor="let status of statusKeys" [value]="status">
                        {{ docStatus[status] }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
            </div>
         </div>
         <div class="row">
            <div class="col-md-6">
               <app-text-editor
                  class="task-text-editor"
                  label="Purpose"
                  [canEdit]="canEdit$ | async"
                  [config]="purposeConfig"
                  [formCtrl]="getControl('purpose')"
                  [alwaysShowLabel]="true"
               ></app-text-editor>
            </div>
            <div class="col-md-6">
               <app-text-editor
                  class="task-text-editor"
                  label="KPI"
                  [canEdit]="canEdit$ | async"
                  [config]="kpiConfig"
                  [formCtrl]="getControl('kpi')"
                  [alwaysShowLabel]="true"
               ></app-text-editor>
            </div>
         </div>
         <div class="row">
            <div class="col-md-6">
               <app-text-editor
                  class="task-text-editor"
                  label="Policies"
                  [canEdit]="canEdit$ | async"
                  [config]="policiesConfig"
                  [formCtrl]="getControl('policies')"
                  [alwaysShowLabel]="true"
               ></app-text-editor>
            </div>
            <div class="col-md-6">
               <app-text-editor
                  class="task-text-editor"
                  label="Benchmark"
                  [canEdit]="canEdit$ | async"
                  [config]="benchmarkConfig"
                  [formCtrl]="getControl('benchmark')"
                  [alwaysShowLabel]="true"
               ></app-text-editor>
            </div>
         </div>
      </mat-card-content>
   </mat-card>
</form>
<div class="task-list" *ngIf="!isNew()">
   <app-task-list
      [tasks]="tasks"
      [visible]="true"
      [facade]="documentationFacade"
      [canEdit]="canEdit$ | async"
      (editTask)="onEditTask($event)"
   ></app-task-list>
</div>
