<div class="alert-message" *ngIf="showAlert">
   <div class="alert-content">
      <ng-container *ngIf="emailSent">
         <div class="alert-text">
            Verification email sent! Please check your inbox for the verification link.
         </div>
      </ng-container>
      <ng-container *ngIf="!emailSent">
         <div class="alert-text">Your email is not verified. Please click here to verify:</div>
         <div class="alert-action">
            <button mat-button color="primary" (click)="verifyEmail()">Verify email</button>
         </div>
      </ng-container>
   </div>
   <div class="alert-dismiss" (click)="dismissAlert()">
      <button mat-icon-button>
         <mat-icon>close</mat-icon>
      </button>
   </div>
</div>

