<ng-container *ngIf="!reauthenticated">
   <mat-card class="reauth">
      <mat-card-title>Please re-enter your password to continue</mat-card-title>
      <mat-card-content>
         <form #passwordForm="ngForm" (ngSubmit)="reauthenticate()">
            <div class="row">
               <mat-form-field class="col-md-12">
                  <input matInput type="password" [(ngModel)]="password" name="password" required />
               </mat-form-field>
               <div class="col-md-12">
                  <mat-error>{{ error }}</mat-error>
               </div>
            </div>

            <div class="row">
               <div class="col-md-12">
                  <button
                     mat-raised-button
                     color="primary"
                     type="submit"
                     [disabled]="!passwordForm.valid || authenticating"
                  >
                     Submit
                  </button>
               </div>
            </div>
         </form>
      </mat-card-content>
   </mat-card>
</ng-container>
<ng-container *ngIf="reauthenticated">
   <form [formGroup]="form">
      <div class="row">
         <div class="col-md-6">
            <mat-card>
               <mat-card-title>Account Settings</mat-card-title>
               <mat-card-content>
                  <div class="row">
                     <mat-form-field class="col-md-12">
                        <input
                           matInput
                           type="text"
                           placeholder="First Name"
                           formControlName="firstName"
                           required
                        />
                     </mat-form-field>
                     <mat-form-field class="col-md-12">
                        <input
                           matInput
                           type="text"
                           placeholder="Last Name"
                           formControlName="lastName"
                           required
                        />
                     </mat-form-field>
                  </div>
                  <div class="row">
                     <mat-form-field class="col-md-12">
                        <input
                           matInput
                           type="text"
                           placeholder="Display Name"
                           formControlName="displayName"
                           required
                        />
                        <mat-hint>
                           This is the name that will be shown on comments and notes you create
                        </mat-hint>
                     </mat-form-field>
                  </div>
                  <div class="row">
                     <mat-form-field class="col-md-12">
                        <input
                           matInput
                           type="text"
                           placeholder="Email"
                           formControlName="email"
                           required
                           email
                        />
                     </mat-form-field>
                  </div>
                  <div class="row">
                     <div class="col-md-12">
                        <button
                           mat-raised-button
                           color="primary"
                           [disabled]="form.pristine || !form.valid"
                           (click)="save()"
                        >
                           Save Changes
                        </button>
                        <button
                           mat-button
                           color="primary"
                           *ngIf="!user.emailVerified"
                           (click)="verifyEmail()"
                        >
                           Verify Email
                        </button>
                        <button mat-button color="primary" (click)="resetPassword()">
                           Reset Password
                        </button>
                     </div>
                  </div>
               </mat-card-content>
            </mat-card>
         </div>
         <div class="col-md-6">
            <mat-card>
               <mat-card-title>Your Organizations</mat-card-title>
               <mat-card-content>
                  <div class="row" *ngFor="let org of orgs$ | async">
                     <div class="col-md-11">
                        {{ org.name }}
                     </div>
                     <div class="col-md-1">
                        <button mat-icon-button [matMenuTriggerFor]="optionsMenu">
                           <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #optionsMenu="matMenu">
                           <button mat-menu-item (click)="leave(org)">Leave Organization</button>
                        </mat-menu>
                     </div>
                  </div>
               </mat-card-content>
            </mat-card>
         </div>
      </div>
   </form>
</ng-container>

<ng-template #leaveOrg>
   <h3 mat-dialog-title>Leave Organization?</h3>
   <mat-dialog-content>
      <p>
         Are you sure you want to leave this organization? Once you leave, you cannot rejoin unless
         your account is reactivated by a member of the organization.
      </p>
   </mat-dialog-content>
   <mat-dialog-actions>
      <button mat-raised-button color="primary" [matDialogClose]="true">Yes, Leave</button>
      <button mat-raised-button [matDialogClose]="false">No, Stay</button>
   </mat-dialog-actions>
</ng-template>

<ng-template #verifyEmailTemplate>
   <h3 mat-dialog-title>Verify Email</h3>
   <mat-dialog-content>
      <p>
         An email has been sent to the address provided. Please click the link in the email to
         verify your account.
      </p>
   </mat-dialog-content>
   <mat-dialog-actions>
      <button mat-raised-button color="primary" [matDialogClose]="true">Ok</button>
   </mat-dialog-actions>
</ng-template>
