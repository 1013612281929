import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { Observable, Subject } from 'rxjs';
import { AuthUser } from '@entities/auth-user';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FirebaseAuthService } from '@app/auth/services/firebase-auth.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Organization } from '@entities/organization';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
   selector: 'app-user-settings',
   templateUrl: './user-settings.component.html',
   styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit, OnDestroy {
   user$: Observable<AuthUser>;
   form: UntypedFormGroup;
   reauthenticated = false;
   password: string;
   error: string;
   authenticating = false;
   orgs$: Observable<Organization[]>;

   user: AuthUser;

   @ViewChild('leaveOrg') leaveOrgDialog: TemplateRef<any>;
   @ViewChild('verifyEmailTemplate') verifyEmailDialog: TemplateRef<any>;

   private destroyed$ = new Subject<void>();

   constructor(
      private userOrgFacade: UserOrgFacade,
      private authService: FirebaseAuthService,
      private dialog: MatDialog
   ) {}

   ngOnInit() {
      this.form = new UntypedFormGroup({
         uid: new UntypedFormControl(),
         displayName: new UntypedFormControl(),
         firstName: new UntypedFormControl(),
         lastName: new UntypedFormControl(),
         email: new UntypedFormControl(),
         orgs: new UntypedFormControl(),
      });
      this.user$ = this.userOrgFacade.authUser$;
      this.user$.pipe(takeUntil(this.destroyed$)).subscribe((user) => {
         this.user = user;
         this.form.patchValue(user);
         this.form.markAsPristine();
      });
      this.orgs$ = this.userOrgFacade.orgsForUser$;
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   resetPassword() {
      this.authService.resetPassword();
   }

   save() {
      const authUser = this.form.value;
      if (authUser.email !== this.user.email) {
         authUser.emailVerified = false;
         this.verifyEmail();
      }
      this.userOrgFacade.saveAuthUser(authUser);
   }

   reauthenticate() {
      this.error = null;
      this.authenticating = true;
      this.authService
         .reauthenticate(this.password)
         .then((result) => {
            this.reauthenticated = result;
            this.authenticating = false;
         })
         .catch((error) => {
            this.error = error.message;
            this.authenticating = false;
         });
   }

   leave(org: Organization) {
      this.dialog
         .open(this.leaveOrgDialog, { maxWidth: '600px' })
         .afterClosed()
         .subscribe((confirmed) => {
            if (confirmed) {
               this.userOrgFacade.leaveOrganization(org);
            }
         });
   }

   verifyEmail() {
      this.userOrgFacade.verifyEmail(this.user);
      this.dialog.open(this.verifyEmailDialog);
   }
}
