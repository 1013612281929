<button mat-button color="primary" (click)="addDirectReport()" *ngIf="canEdit">
   <mat-icon>add</mat-icon>
   Add Direct Report
</button>

<app-table [config]="tableConfig"></app-table>

<ng-template #addDirectReportDialogTemplate>
   <div mat-dialog-title>Add Direct Report</div>
   <mat-dialog-content>
      <app-team-member-autocomplete
         [idFormControl]="addDirectReportFormControl"
         (optionSelected)="setIdToAdd($event)"
      ></app-team-member-autocomplete>
   </mat-dialog-content>
   <mat-dialog-actions>
      <button mat-raised-button color="primary" [matDialogClose]="true" [disabled]="!this.idToAdd">
         Add
      </button>
      <button mat-stroked-button color="primary" matDialogClose>Cancel</button>
   </mat-dialog-actions>
</ng-template>

