import { Component, OnInit } from '@angular/core';
import { froalaConfig } from '@app/shared/config/froala.config';
import { TeamFacade } from '@app/team/state/team.facade';
import { TeamMember } from '@entities/team-member';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';
import { take, filter } from 'rxjs/operators';
import { AssignedTaskViewModel } from '@app/documentation/state/documentation.models';
import { UserOrgFacade } from '@app/user-org/state/user-org.facade';
import { CompetencyLevel } from '@entities/competency-level';
import { ActivatedRoute } from '@angular/router';

@Component({
   selector: 'app-team-member-report',
   templateUrl: './team-member-report.component.html',
   styleUrls: ['./team-member-report.component.scss'],
})
export class TeamMemberReportComponent implements OnInit {
   options = {
      ...froalaConfig,
      toolbarButtons: ['print'],
      documentReady: true,
   };
   orgName: string;
   logo: string;
   teamMember: TeamMember;
   teamMembers: TeamMember[];
   taskViewModels: AssignedTaskViewModel[];
   directs: BusinessUnit[];
   manages: Department[];
   leads: DepartmentFunction[];
   competencyLevels: CompetencyLevel[] = [];
   competencyLevelsMap = {};
   departments: Department[] = [];

   constructor(
      private teamFacade: TeamFacade,
      private userOrgFacade: UserOrgFacade,
      private route: ActivatedRoute
   ) {}

   ngOnInit() {
      this.route.params.subscribe((params) => {
         if (params['teamMemberId']) {
            this.teamFacade.selectTeamMember(params['teamMemberId']);
         }
      });
      this.teamFacade.selectedTeamMember$.pipe(filter(Boolean), take(1)).subscribe((teamMember) => {
         this.teamMember = teamMember;
         this.initializeSubscriptions();
      });
      this.userOrgFacade.selectedOrg$.pipe(filter(Boolean), take(1)).subscribe((org) => {
         if (org) {
            this.orgName = org.name;
            this.logo = org.logo;
         }
      });
   }

   initializeSubscriptions() {
      this.teamFacade.assignedTasks$.subscribe((viewModels) => {
         this.taskViewModels = viewModels
            .filter((vm) => vm.businessUnit && vm.department && vm.departmentFunction && vm.task)
            .sort((a, b) => {
               return (
                  a.businessUnit.order - b.businessUnit.order ||
                  a.department.order - b.department.order ||
                  a.departmentFunction.order - b.departmentFunction.order ||
                  a.task.order - b.task.order
               );
            });
         this.taskViewModels.forEach((viewModel) => {
            viewModel.trainingPlan = this.teamMember.trainingPlans.find(
               (plan) => plan.taskId === viewModel.task.id
            );
            viewModel.taskRating = this.teamMember.taskRatings?.find(
               (rating) => rating.taskId === viewModel.task.id
            );
         });
      });
      this.teamFacade.businessUnits$.subscribe((businessUnits) => {
         this.directs = businessUnits.filter((bu) => bu.directorId == this.teamMember.id);
      });
      this.teamFacade.departments$.subscribe((departments) => {
         this.departments = departments;
         this.manages = departments.filter((dept) => dept.managerId == this.teamMember.id);
      });
      this.teamFacade.departmentFunctions$.subscribe((deptFns) => {
         this.leads = deptFns
            .filter((deptFn) => deptFn.leadId == this.teamMember.id)
            .sort((a, b) => {
               if (this.departments.length > 0) {
                  const deptA = this.departments.find((d) => d.id == a.departmentId);
                  const deptB = this.departments.find((d) => d.id == b.departmentId);
                  if (deptA && deptB) {
                     return deptA.name.localeCompare(deptB.name) || a.name.localeCompare(b.name);
                  }
               }
               return a.name.localeCompare(b.name);
            });
      });
      this.teamFacade.teamMembers$.subscribe((teamMembers) => {
         this.teamMembers = teamMembers;
      });
      this.teamFacade.competencyLevels$.subscribe((competencyLevels) => {
         this.competencyLevels = competencyLevels;
         this.competencyLevels.forEach((level) => {
            this.competencyLevelsMap[level.id] = level.label;
         });
      });
   }

   back() {
      history.back();
   }
}
