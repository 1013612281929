<app-sticky-header
   [title]="title$ | async"
   [saveDisabled]="saveDisabled()"
   [isNew]="!getControl('id').value"
   [titleFormControl]="getControl('name')"
   [canEdit]="canEdit"
   [menuOptions]="menuOptions"
   (save)="save()"
   (back)="backToList()"
   (delete)="delete()"
   (titleChange)="setName($event)"
></app-sticky-header>
<form [formGroup]="form">
   <div class="cards-container">
      <mat-card class="info-card">
         <mat-card-content class="info-card-content">
            <div class="info-row">
               <mat-form-field>
                  <mat-label>Type</mat-label>
                  <mat-select formControlName="type" required>
                     <mat-option *ngFor="let option of actionItemTypeKeys" [value]="option">
                        {{ actionItemType[option] }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field>
                  <mat-label>Priority</mat-label>
                  <mat-select formControlName="priority" required>
                     <mat-option [value]="key" *ngFor="let key of priorityKeys">
                        {{ priority[key] }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field>
                  <mat-label>Status</mat-label>
                  <mat-select
                     formControlName="status"
                     required
                     (selectionChange)="statusChanged($event)"
                  >
                     <mat-option [value]="key" *ngFor="let key of statusKeys">
                        {{ status[key] }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
            </div>
            <div class="info-row">
               <app-department-autocomplete
                  placeholder="Department"
                  [idFormControl]="getControl('departmentId')"
                  (optionSelected)="setDepartmentId($event)"
                  [required]="true"
                  [canEdit]="canEdit"
               ></app-department-autocomplete>

               <app-team-member-autocomplete
                  placeholder="Assigned To"
                  [idFormControl]="getControl('assigneeId')"
                  [canEdit]="canEdit"
                  (optionSelected)="setAssignedToId($event)"
               ></app-team-member-autocomplete>
            </div>
            <div class="description">
               <app-text-editor
                  class="fill-height editor"
                  label="Description"
                  [canEdit]="canEdit$ | async"
                  [config]="editorConfig"
                  [formCtrl]="getControl('description')"
               ></app-text-editor>
            </div>
         </mat-card-content>
      </mat-card>
      <div class="dates-card">
         <mat-card>
            <mat-card-title>Dates</mat-card-title>
            <mat-card-content>
               <mat-form-field>
                  <input
                     matInput
                     formControlName="startDate"
                     placeholder="Start Date"
                     [matDatepicker]="startDatePicker"
                     (dateChange)="setDate($event, 'startDate')"
                  />
                  <ng-container *ngIf="canEdit">
                     <app-datepicker-clear
                        class="datepicker-clear"
                        [for]="startDatePicker"
                     ></app-datepicker-clear>
                     <mat-datepicker-toggle
                        matSuffix
                        [for]="startDatePicker"
                     ></mat-datepicker-toggle>
                  </ng-container>
                  <mat-datepicker #startDatePicker></mat-datepicker>
               </mat-form-field>
               <mat-form-field>
                  <input
                     matInput
                     formControlName="targetDate"
                     placeholder="Target Date"
                     [matDatepicker]="targetDatePicker"
                     (dateChange)="setDate($event, 'targetDate')"
                  />
                  <ng-container *ngIf="canEdit">
                     <app-datepicker-clear
                        class="datepicker-clear"
                        [for]="targetDatePicker"
                     ></app-datepicker-clear>
                     <mat-datepicker-toggle
                        matSuffix
                        [for]="targetDatePicker"
                     ></mat-datepicker-toggle>
                  </ng-container>
                  <mat-datepicker #targetDatePicker></mat-datepicker>
               </mat-form-field>
               <mat-form-field>
                  <input
                     matInput
                     formControlName="completedDate"
                     placeholder="Completed Date"
                     [matDatepicker]="completedDatePicker"
                     (dateChange)="setDate($event, 'completedDate')"
                  />
                  <ng-container *ngIf="canEdit">
                     <app-datepicker-clear
                        class="datepicker-clear"
                        [for]="completedDatePicker"
                     ></app-datepicker-clear>
                     <mat-datepicker-toggle
                        matSuffix
                        [for]="completedDatePicker"
                     ></mat-datepicker-toggle>
                  </ng-container>
                  <mat-datepicker #completedDatePicker></mat-datepicker>
               </mat-form-field>
            </mat-card-content>
         </mat-card>
      </div>
   </div>
</form>
<div class="comments">
   <app-user-comments
      [comments]="comments"
      [canEdit]="canEdit"
      (commentsUpdated)="updateComments($event)"
   ></app-user-comments>
</div>

<ng-template #convertToRockTemplate>
   <h3 mat-dialog-title>Convert Action To Rock</h3>
   <mat-dialog-content>
      <div>Are you sure you want to convert this action to a rock? This cannot be undone.</div>
      <mat-checkbox [(ngModel)]="deleteAfterConvert">Delete action after converting</mat-checkbox>
   </mat-dialog-content>
   <mat-dialog-actions>
      <button mat-stroked-button color="primary" matDialogClose>Cancel</button>
      <button mat-raised-button color="primary" (click)="convertToRock()">Convert</button>
   </mat-dialog-actions>
</ng-template>
