import { Injectable, OnDestroy } from '@angular/core';
import { OrgEntityService } from '@app/shared/services/org-entity.service';
import { ActionItem } from '@entities/action-item';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import { EntityService } from '@app/shared/services/entity.service';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { PermissionedEntity } from '@app/shared/interfaces/permissioned-entity.interface';
import { WhereFilterOp, where } from '@angular/fire/firestore';
import { PermissionsFacade } from '@app/shared/state/permissions.facade';
import { AppAreas } from '@entities/enums/app-areas';

@Injectable()
export class ActionItemService implements EntityService<ActionItem>, PermissionedEntity, OnDestroy {
   entities$: Observable<ActionItem[]>;

   readonly COLLECTION_NAME = COLLECTION_NAMES.ISSUES;

   private orgId$: Observable<string> = this.store.pipe(select(getOrganizationId));
   private orgEntityService = new OrgEntityService<ActionItem>();
   private teamMemberId$ = this.permissionsFacade.currentUserTeamMemberId$;
   private permissionGroup$ = this.permissionsFacade.currentUserPermissionGroup$;

   constructor(private store: Store<State>, private permissionsFacade: PermissionsFacade) {
      this.orgEntityService.init(
         this.orgId$,
         this.COLLECTION_NAME,
         AppAreas.Evolve,
         this.permissionsFacade.usePermissionGroups$,
         this.permissionGroup$,
         this.assigneeFilter,
         this.teamMemberId$
      );
      this.entities$ = this.orgEntityService.entities$;
   }
   ngOnDestroy() {
      this.orgEntityService.destroy();
   }
   save(entity: ActionItem): Promise<ActionItem> {
      return this.orgEntityService.save(entity);
   }
   batchSave(entities: ActionItem[]): Promise<ActionItem[]> {
      return this.orgEntityService.batchSave(entities);
   }
   delete(entity: ActionItem): Promise<void> {
      return this.orgEntityService.delete(entity);
   }

   assigneeFilter(teamMemberId: string): Array<[string, WhereFilterOp, string]> {
      return [['assigneeId', '==', teamMemberId]];
   }
}
