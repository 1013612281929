import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/layout.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LayoutEffects } from './state/layout.effects';
import { TopnavComponent } from './components/topnav/topnav.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LayoutFacade } from './state/layout.facade';
import { SharedModule } from '@app/shared/shared.module';
import { SubnavComponent } from './components/subnav/subnav.component';
import { HelpSidebarComponent } from './components/help-sidebar/help-sidebar.component';
import { SearchModule } from '@app/search/search.module';
import { AlertBannerComponent } from './components/alert-banner/alert-banner.component';

@NgModule({
   imports: [
      CommonModule,
      SharedModule,
      RouterModule,
      SearchModule,
      StoreModule.forFeature('layout', reducer),
      EffectsModule.forFeature([LayoutEffects]),
   ],
   declarations: [
      TopnavComponent,
      SidenavComponent,
      SubnavComponent,
      HelpSidebarComponent,
      AlertBannerComponent,
   ],
   exports: [TopnavComponent, SidenavComponent, HelpSidebarComponent, AlertBannerComponent],

   providers: [LayoutFacade],
})
export class LayoutModule {}
