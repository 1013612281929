import * as root from '@app/app.state';
import { BusinessUnit } from '@entities/business-unit';
import { Department } from '@entities/department';
import { DepartmentFunction } from '@entities/department-function';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Task } from '@entities/task';
import { TeamMember } from '@entities/team-member';
import { Role } from '@entities/role';

export interface State extends root.State {
   organization: OrganizationState;
}

export const organizationFeatureKey = 'organization';

export interface OrganizationState {
   businessUnits: BusinessUnit[];
   selectedBusinessUnitId: string;
   departments: Department[];
   selectedDepartmentId: string;
   departmentFunctions: DepartmentFunction[];
   selectedDepartmentFunctionId: string;
   tasks: Task[];
   selectedTaskId: string;
   dragItem: any;
}

export interface OrgTreeItem {
   id: string;
   name: string;
   isTask?: boolean;
   children?: OrgTreeItem[];
}

export const getOrganizationState = createFeatureSelector<OrganizationState>('organization');
export const getBusinessUnits = createSelector(getOrganizationState, (state) =>
   [...state.businessUnits].sort((a, b) => a.name.localeCompare(b.name))
);

export const getSelectedBusinessUnit = createSelector(getOrganizationState, (state) =>
   state.businessUnits.find((b) => b.id == state.selectedBusinessUnitId)
);
export const getSelectedBusinessUnitId = createSelector(
   getOrganizationState,
   (state) => state.selectedBusinessUnitId
);

export const getDepartments = createSelector(getOrganizationState, (state) => {
   const businessUnitIds = state.businessUnits.map((bu) => bu.id);
   const departments = [...state.departments].filter(
      (dept) => dept.businessUnitId && businessUnitIds.includes(dept.businessUnitId)
   );
   return departments.sort((a, b) => a.name.localeCompare(b.name));
});
export const getDepartmentsForBusinessUnit = createSelector(
   getDepartments,
   (departments, props) => {
      return departments.filter((d) => d.businessUnitId == props.businessUnitId);
   }
);
export const getVisibleDepartments = createSelector(getOrganizationState, (state) => {
   const depts = state.departments.filter((d) => d.businessUnitId == state.selectedBusinessUnitId);
   return depts.sort((a, b) => a.order - b.order);
});
export const getSelectedDepartment = createSelector(getOrganizationState, (state) =>
   state.departments.find((d) => d.id == state.selectedDepartmentId)
);
export const getSelectedDepartmentId = createSelector(
   getOrganizationState,
   (state) => state.selectedDepartmentId
);

export const getDepartmentFunctions = createSelector(getOrganizationState, (state) =>
   [...state.departmentFunctions].sort((a, b) => (a.name || '').localeCompare(b.name))
);
export const getSelectedDepartmentFunction = createSelector(getOrganizationState, (state) =>
   state.departmentFunctions.find((f) => f.id == state.selectedDepartmentFunctionId)
);
export const getDepartmentFunctionsForDepartment = createSelector(
   getDepartmentFunctions,
   (deptFns, props) => {
      return deptFns.filter((d) => d.businessUnitId == props.departmentId);
   }
);
export const getVisibleDepartmentFunctions = createSelector(getOrganizationState, (state) => {
   const deptFns = state.departmentFunctions.filter(
      (f) => f.departmentId == state.selectedDepartmentId
   );
   return deptFns.sort((a, b) => a.order - b.order);
});
export const getSelectedDepartmentFunctionId = createSelector(
   getOrganizationState,
   (state) => state.selectedDepartmentFunctionId
);

export const getTasks = createSelector(getOrganizationState, (state) => state.tasks);
export const getVisibleTasks = createSelector(getOrganizationState, (state) => {
   const tasks = state.tasks.filter(
      (t) => t.departmentFunctionId == state.selectedDepartmentFunctionId
   );
   return tasks.sort((a, b) => a.order - b.order);
});
export const getTasksForDepartmentFunction = createSelector(getTasks, (tasks, props) => {
   return tasks.filter((t) => t.departmentFunctionId == props.departmentFunctionId);
});
export const getSelectedTask = createSelector(getOrganizationState, (state) =>
   state.tasks.find((t) => t.id == state.selectedTaskId)
);
export const getSelectedTaskId = createSelector(
   getOrganizationState,
   (state) => state.selectedTaskId
);

export const getTasksForTeamMember = createSelector(getTasks, (tasks, { teamMember }) => {
   return tasks.filter((t) => teamMember?.assignedTasks?.includes(t.id));
});

export const getTasksForRole = createSelector(getTasks, (tasks, { role }) => {
   return tasks.filter((t) => role.tasks.includes(t.id));
});

export const getDepartmentsTree = createSelector(
   getBusinessUnits,
   getDepartments,
   (businessUnits, departments) => {
      const nodes: any[] = [];
      businessUnits.forEach((businessUnit) => {
         const buNode = {
            name: businessUnit.name,
            isDepartment: false,
            id: businessUnit.id,
            children: [],
         };
         const deptChildren = departments
            .filter((d) => d.businessUnitId == businessUnit.id)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((dept) => {
               return { name: dept.name, isDepartment: true, id: dept.id, children: [] };
            });
         buNode.children = deptChildren;
         nodes.push(buNode);
      });
      nodes.sort((a, b) => a.name.localeCompare(b.name));
      return nodes;
   }
);

export const getOrgTree = createSelector(
   getBusinessUnits,
   getDepartments,
   getDepartmentFunctions,
   getTasks,
   (businessUnits, departments, departmentFunctions, tasks) => {
      const orgTree = [];
      businessUnits.forEach((bu) => {
         const businessUnit = { ...bu };
         businessUnit.departments = [];
         const depts = departments
            .filter((d) => d.businessUnitId == businessUnit.id)
            .sort((a, b) => a.order - b.order);
         depts.forEach((dept) => {
            const department = { ...dept };
            department.departmentFunctions = [];
            const deptFns = departmentFunctions
               .filter((d) => d.departmentId == department.id)
               .sort((a, b) => a.order - b.order);
            deptFns.forEach((deptFn) => {
               const departmentFunction = { ...deptFn };
               departmentFunction.tasks = tasks
                  .filter((t) => t.departmentFunctionId == departmentFunction.id)
                  .sort((a, b) => a.order - b.order);
               department.departmentFunctions.push(departmentFunction);
            });
            businessUnit.departments.push(department);
         });
         orgTree.push(businessUnit);
      });
      return orgTree.sort((a, b) => a.order - b.order);
   }
);

export const getTasksTree = createSelector(
   getBusinessUnits,
   getDepartments,
   getDepartmentFunctions,
   getTasks,
   (businessUnits, departments, departmentFunctions, tasks) => {
      const nodes: OrgTreeItem[] = [];
      businessUnits.forEach((businessUnit) => {
         const buNode = { id: businessUnit.id, name: businessUnit.name, children: [] };
         const deptChildren = departments.filter((d) => d.businessUnitId == businessUnit.id);
         deptChildren.forEach((department) => {
            const deptNode = { id: department.id, name: department.name, children: [] };
            const deptFnChildren = departmentFunctions.filter(
               (f) => f.departmentId == department.id
            );
            deptFnChildren.forEach((departmentFunction) => {
               const deptFnNode = {
                  id: departmentFunction.id,
                  name: departmentFunction.name,
                  children: [],
               };
               const taskChildren = tasks.filter(
                  (t) => t.departmentFunctionId == departmentFunction.id
               );
               taskChildren.forEach((task) => {
                  const taskNode = { name: task.name, isTask: true, id: task.id };
                  deptFnNode.children.push(taskNode);
               });
               if (deptFnNode.children.length > 0) {
                  deptFnNode.children.sort((a, b) => a.name.localeCompare(b.name));
                  deptNode.children.push(deptFnNode);
               }
            });
            if (deptNode.children.length > 0) {
               deptNode.children.sort((a, b) => a.name.localeCompare(b.name));
               buNode.children.push(deptNode);
            }
         });
         if (buNode.children.length > 0) {
            buNode.children.sort((a, b) => a.name.localeCompare(b.name));
            nodes.push(buNode);
         }
      });
      nodes.sort((a, b) => a.name.localeCompare(b.name));
      return nodes;
   }
);

export const getDepartmentFunctionsTree = createSelector(
   getBusinessUnits,
   getDepartments,
   getDepartmentFunctions,
   getTasks,
   (businessUnits, departments, departmentFunctions, tasks) => {
      const nodes: any[] = [];
      businessUnits.forEach((businessUnit) => {
         const buNode = { name: businessUnit.name, children: [] };
         const deptChildren = departments.filter((d) => d.businessUnitId == businessUnit.id);
         deptChildren.forEach((department) => {
            const deptNode = { name: department.name, children: [] };
            const deptFnChildren = departmentFunctions.filter(
               (f) => f.departmentId == department.id
            );
            deptFnChildren.forEach((departmentFunction) => {
               const deptFnNode = {
                  name: departmentFunction.name,
                  id: departmentFunction.id,
                  children: [],
               };
               const taskChildren = tasks.filter(
                  (t) => t.departmentFunctionId == departmentFunction.id
               );
               taskChildren.forEach((task) => {
                  const taskNode = { name: task.name, id: task.id };
                  deptFnNode.children.push(taskNode);
               });
               if (deptFnNode.children.length > 0) {
                  deptFnNode.children.sort((a, b) => a.name.localeCompare(b.name));
               }
               deptNode.children.push(deptFnNode);
            });
            if (deptNode.children.length > 0) {
               deptNode.children.sort((a, b) => a.name.localeCompare(b.name));
               buNode.children.push(deptNode);
            }
         });
         if (buNode.children.length > 0) {
            buNode.children.sort((a, b) => a.name.localeCompare(b.name));
            nodes.push(buNode);
         }
      });
      nodes.sort((a, b) => a.name.localeCompare(b.name));
      return nodes;
   }
);

export const getDepartmentTasksTree = createSelector(
   getOrganizationState,
   (state: OrganizationState, props) => {
      const department = state.departments.find((dept) => dept.id === props.departmentId);
      const tree = [];
      if (department) {
         const deptFunctions = state.departmentFunctions.filter(
            (deptFn) => deptFn.departmentId === department.id
         );
         deptFunctions.forEach((deptFn) => {
            const tasks = state.tasks.filter((task) => task.departmentFunctionId === deptFn.id);
            if (tasks.length > 0) {
               tasks.sort((a, b) => a.order - b.order);
               tree.push({ name: deptFn.name, children: tasks, order: deptFn.order });
            }
         });
      }
      tree.sort((a, b) => a.order - b.order);
      return tree;
   }
);

export const getDragItem = createSelector(getOrganizationState, (state) => state.dragItem);

export const getBusinessUnitSummary = createSelector(
   getOrganizationState,
   getSelectedBusinessUnit,
   getVisibleDepartments,
   (state, selectedBusinessUnit, visibleDepartments) => {
      if (selectedBusinessUnit) {
         const businessUnit = {
            ...selectedBusinessUnit,
         };
         businessUnit.departments = [...visibleDepartments].map((d) => {
            return { ...d };
         });
         businessUnit.departments.forEach((department) => {
            department.departmentFunctions = state.departmentFunctions
               .filter((f) => f.departmentId == department.id)
               .sort((a, b) => a.order - b.order);
         });
         return businessUnit;
      } else {
         return null;
      }
   }
);

export const getDepartmentSummary = createSelector(
   getOrganizationState,
   getSelectedDepartment,
   getVisibleDepartmentFunctions,
   (state, selectedDepartment, deptFns) => {
      if (selectedDepartment) {
         const department = { ...selectedDepartment };
         department.departmentFunctions = [...deptFns].map((f) => {
            return { ...f };
         });
         department.departmentFunctions.forEach((deptFn) => {
            deptFn.tasks = state.tasks
               .filter((t) => t.departmentFunctionId == deptFn.id)
               .sort((a, b) => a.order - b.order);
         });
         return department;
      } else {
         return null;
      }
   }
);
