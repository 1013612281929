import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { of, from } from 'rxjs';
import { withLatestFrom, switchMap, map, catchError, tap } from 'rxjs/operators';

import { State } from '@app/app.state';
import { Router } from '@angular/router';

import { appRoutesNames } from '@app/app.routes.names';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';
import * as DocumentationActions from './documentation.actions';
import { Task } from '@entities/task';
import { TaskService } from '@app/org-builder/services/task.service';
import { ErrorService } from '@app/shared/services/error.service';
import { documentationSelectors } from './documentation.state';
import { DraggingStopped } from '@app/org-builder/state/organization.actions';
import { DocumentationFacade } from './documentation.facade';
import { documentationRouteNames } from '../documentation.routes.names';
import { DepartmentFunctionService } from '@app/org-builder/services/department-function.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable()
export class DocumentationEffects {
   constructor(
      private actions$: Actions,
      private store: Store<State>,
      private taskService: TaskService,
      private errorService: ErrorService,
      private documentationFacade: DocumentationFacade,
      private router: Router,
      private departmentFunctionService: DepartmentFunctionService,
      private snackBar: MatSnackBar
   ) {}

   saveDepartmentFunction$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(DocumentationActions.SaveDepartmentFunction),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            switchMap(([{ departmentFunction, onDeactivate }, orgId]) => {
               return from(this.departmentFunctionService.save(departmentFunction)).pipe(
                  tap((deptFn) => {
                     this.snackBar.open('Function Saved');
                     if (!departmentFunction.id && !onDeactivate) {
                        this.router.navigate(
                           [
                              appRoutesNames.ORGANIZATION,
                              orgId,
                              appRoutesNames.DOCUMENTATION,
                              documentationRouteNames.FUNCTIONS,
                              deptFn.id,
                           ],
                           { queryParams: { from: 'new' } }
                        );
                     }
                  }),
                  catchError((error) => {
                     this.errorService.handleError(error);
                     return of();
                  })
               );
            })
         ),
      { dispatch: false }
   );

   saveTask$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(DocumentationActions.SaveTask),
            withLatestFrom(
               this.store.pipe(select(getOrganizationId)),
               this.store.pipe(select(documentationSelectors.getSelectedFunction)),
               this.documentationFacade.assignedTeamMembers$
            ),
            tap(([action, organizationId, viewModel, teamMembers]) => {
               const newTask = action.task.id == null;
               const assignees = teamMembers ? teamMembers.map((t) => t.id) : [];
               const task: Task = {
                  ...action.task,
                  departmentFunctionId:
                     action.task.departmentFunctionId || viewModel.departmentFunction.id,
                  organizationId,
                  order: action.task.order == null ? viewModel.tasks.length : action.task.order,
                  assignees,
               };
               this.taskService.save(task).then((savedTask) => {
                  if (newTask && action.navigate) {
                     this.router.navigate([
                        appRoutesNames.ORGANIZATION,
                        organizationId,
                        appRoutesNames.DOCUMENTATION,
                        documentationRouteNames.TASKS,
                        savedTask.id,
                     ]);
                  }
               });
            })
         ),
      { dispatch: false }
   );

   viewTask$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(DocumentationActions.ViewTask),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ taskId }, orgId]) => {
               this.router.navigate([
                  appRoutesNames.ORGANIZATION,
                  orgId,
                  appRoutesNames.DOCUMENTATION,
                  documentationRouteNames.TASKS,
                  taskId,
               ]);
            })
         ),
      { dispatch: false }
   );

   reorderTasks$ = createEffect(() =>
      this.actions$.pipe(
         ofType(DocumentationActions.ReorderTasks),
         withLatestFrom(this.store.pipe(select(documentationSelectors.getSelectedFunction))),
         switchMap(([action, viewModel]) => {
            const toMove = viewModel.tasks[action.oldIndex];
            const reordered = [...viewModel.tasks];
            const toUpdate = [];
            reordered.splice(action.oldIndex, 1);
            reordered.splice(action.newIndex, 0, toMove);
            for (let i = 0; i < reordered.length; i++) {
               if (reordered[i].id != viewModel.tasks[i].id) {
                  toUpdate.push({
                     ...reordered[i],
                     order: i,
                  });
               }
            }
            return from(this.taskService.batchSave(toUpdate)).pipe(
               map(() => {
                  return DraggingStopped();
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   departmentFunctionReport$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(DocumentationActions.DepartmentFunctionReport),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ departmentFunction }, orgId]) => {
               this.router.navigate([
                  appRoutesNames.ORGANIZATION,
                  orgId,
                  appRoutesNames.DOCUMENTATION,
                  documentationRouteNames.FUNCTIONS,
                  departmentFunction.id,
                  documentationRouteNames.REPORT,
               ]);
            })
         ),
      { dispatch: false }
   );

   taskReport$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(DocumentationActions.TaskReport),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ task }, orgId]) => {
               this.router.navigate([
                  appRoutesNames.ORGANIZATION,
                  orgId,
                  appRoutesNames.DOCUMENTATION,
                  documentationRouteNames.TASKS,
                  task.id,
                  documentationRouteNames.REPORT,
               ]);
            })
         ),
      { dispatch: false }
   );
}
