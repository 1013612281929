import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import { State, getSidenavOpen, getHelpOpen } from './layout/state/layout.state';
import { environment } from '@src/environments/environment';
import * as LayoutActions from './layout/state/layout.actions';
import { FeatureFlagService } from './internal-tools/services/feature-flag.service';
import { FeatureFlagKeys } from './internal-tools/state/feature-flag-keys';
import { getOrganizationId } from './user-org/state/user-org.selectors';
import { map } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import * as routerStore from '@ngrx/router-store';
import { navigationCancelled } from './app.state';
@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
   sidenavOpen$: Observable<boolean>;
   helpOpen$: Observable<boolean>;
   helpFeatureFlag$: Observable<boolean>;
   orgSelected$: Observable<boolean>;

   envName: string;

   navigating = false;
   showAlert = true;

   constructor(
      private store: Store<State>,
      private featureFlagService: FeatureFlagService,
      private actions$: Actions
   ) {}

   ngOnInit() {
      this.sidenavOpen$ = this.store.pipe(select(getSidenavOpen));
      this.helpOpen$ = this.store.pipe(select(getHelpOpen));
      this.orgSelected$ = this.store.pipe(select(getOrganizationId)).pipe(map((orgId) => !!orgId));
      this.envName = environment.name;
      this.store.dispatch(LayoutActions.CloseSidenav());
      this.helpFeatureFlag$ = this.featureFlagService.isEnabled(FeatureFlagKeys.HELP_SIDEBAR);
      this.featureFlagService.syncFeatureFlags();
      this.actions$.pipe(ofType(routerStore.routerNavigationAction)).subscribe(() => {
         this.navigating = true;
      });
      this.actions$.pipe(ofType(routerStore.routerNavigatedAction)).subscribe(() => {
         this.navigating = false;
      });
      this.actions$.pipe(ofType(navigationCancelled)).subscribe(() => {
         this.navigating = false;
      });
      const alertDismissed = localStorage.getItem('alertDismissed');
      if (alertDismissed) {
         this.showAlert = false;
      }
   }
}
