import { Component, Input, OnInit } from '@angular/core';

@Component({
   selector: 'app-report-header',
   templateUrl: './report-header.component.html',
   styleUrls: ['./report-header.component.scss'],
})
export class ReportHeaderComponent implements OnInit {
   @Input() orgName: string;
   @Input() logo: string;
   @Input() category: string;
   @Input() title: string;
   @Input() subtitle: string;

   constructor() {}

   ngOnInit(): void {}
}
