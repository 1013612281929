import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgShellComponent } from './components/org-shell/org-shell.component';
import { OrgListComponent } from './components/org-list/org-list.component';
import { InviteAcceptComponent } from './components/invite-accept/invite-accept.component';
import { UserService } from './services/user.service';
import { OrganizationService } from './services/organization.service';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/user-org.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserOrgEffects } from './state/user-org.effects';
import { UserOrgFacade } from './state/user-org.facade';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { AuthModule } from '@app/auth/auth.module';

@NgModule({
   declarations: [
      OrgShellComponent,
      OrgListComponent,
      InviteAcceptComponent,
      UserSettingsComponent,
      UnauthorizedComponent,
      VerifyEmailComponent,
   ],
   imports: [
      CommonModule,
      SharedModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      StoreModule.forFeature('userOrg', reducer),
      EffectsModule.forFeature([UserOrgEffects]),
      AuthModule,
   ],
   providers: [UserService, OrganizationService, UserOrgFacade],
})
export class UserOrgModule {}
