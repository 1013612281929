<button mat-button color="primary" (click)="back()">
   <mat-icon>arrow_back</mat-icon>
   Back
</button>
<ng-container *ngIf="deptFn">
   <div class="editor" [froalaEditor]="options">
      <div style="width: 100%; min-height: 10in">
         <app-report-header
            *ngIf="orgName"
            [orgName]="orgName"
            [logo]="logoUrl"
            category="Standard Operating Procedures"
            [title]="deptFn.name"
            subtitle="{{ businessUnit.name }} &#8250; {{ department.name }}"
         ></app-report-header>
         <div
            style="
               display: flex;
               flex-direction: column;
               border-bottom: 2px solid black;
               margin-bottom: 8px;
               padding-bottom: 8px;
            "
         >
            <div>
               <div style="font-size: 12pt; font-weight: bold; color: #757575">Purpose</div>
               <app-report-text [text]="deptFn.purpose"></app-report-text>
            </div>
            <div>
               <div style="font-size: 12pt; font-weight: bold; color: #757575">Policies</div>
               <app-report-text [text]="deptFn.policies"></app-report-text>
            </div>
            <div>
               <div style="font-size: 12pt; font-weight: bold; color: #757575">KPI</div>
               <app-report-text [text]="deptFn.kpi"></app-report-text>
            </div>
            <div>
               <div style="font-size: 12pt; font-weight: bold; color: #757575">Benchmark</div>
               <app-report-text [text]="deptFn.benchmark"></app-report-text>
            </div>
         </div>
         <div style="font-size: 12pt; font-weight: bold; color: #757575">Tasks</div>
         <ng-container *ngIf="tasks">
            <ol>
               <li *ngFor="let task of tasks">
                  {{ task.name }}
               </li>
            </ol>
         </ng-container>
      </div>
      <hr class="no-print" />
      <ng-container>
         <div style="min-height: 10in; margin-top: 0.5in; page-break-before: always">
            <app-report-header
               *ngIf="orgName"
               [orgName]="orgName"
               [logo]="logoUrl"
               category="Standard Operating Procedures"
               [title]="deptFn.name"
               subtitle="{{ businessUnit.name }} &#8250; {{ department.name }}"
            ></app-report-header>
            <div *ngFor="let task of tasks; last as isLast" style="margin-bottom: 8pt">
               <div style="font-size: 12pt; font-weight: bold">{{ task.name }}</div>
               <ng-container *ngFor="let step of task.steps; index as i">
                  <div style="margin-left: 12pt">
                     <div>{{ i + 1 }}. {{ step.name }}</div>
                     <div style="margin-left: 12pt" [innerHTML]="sanitize(step.instructions)"></div>
                  </div>
               </ng-container>
            </div>
         </div>
      </ng-container>
   </div>
</ng-container>
