<mat-card>
   <mat-card-title>
      Linked Rocks
      <div class="link-button">
         <button mat-raised-button color="primary" *ngIf="canEdit" (click)="linkRock()">
            Link Rock
         </button>
      </div>
   </mat-card-title>
   <mat-card-content>
      <app-table [config]="tableConfig"></app-table>
   </mat-card-content>
</mat-card>

<ng-template #linkRockDialogRef>
   <div mat-dialog-title>Link Rock</div>
   <mat-dialog-content>
      <mat-form-field>
         <mat-label>Rock</mat-label>
         <input
            matInput
            [formControl]="rockToLinkFormControl"
            [matAutocomplete]="rockAutocomplete"
         />
         <span *ngIf="rockToLinkSelected" class="clear-selection" (click)="clear()">
            <mat-icon>close</mat-icon>
         </span>
         <mat-autocomplete
            #rockAutocomplete="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="onOptionSelect($event)"
         >
            <mat-option *ngFor="let rock of filteredRocks$ | async" [value]="rock">
               {{ rock.name }}
            </mat-option>
         </mat-autocomplete>
      </mat-form-field>
   </mat-dialog-content>
   <mat-dialog-actions>
      <button
         mat-raised-button
         color="primary"
         [disabled]="!rockToLinkSelected"
         (click)="addLinkedRock()"
      >
         Link
      </button>
      <button mat-stroked-button color="primary" matDialogClose>Cancel</button>
   </mat-dialog-actions>
</ng-template>
