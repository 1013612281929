import { Component, Input } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
   selector: 'app-datepicker-clear',
   templateUrl: './datepicker-clear.component.html',
   styleUrls: ['./datepicker-clear.component.scss'],
})
export class DatepickerClearComponent {
   @Input('for') datepicker: MatDatepicker<any>;

   clearInput() {
      this.datepicker.close();
      this.datepicker.select(null);
   }
}
