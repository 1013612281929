export enum FunctionNames {
   RESEND_INVITE = 'resendInvite',
   ACCEPT_INVITE = 'acceptInvite',
   GET_PRODUCTS = 'getProducts',
   CREATE_CUSTOMER = 'createCustomer',
   UPDATE_BILLING_INFO = 'updateBillingInfo',
   GET_PROMO_CODE = 'getPromoCode',
   LEAVE_ORG = 'leaveOrg',
   UPDATE_PLANS = 'updatePlans',
   SEARCH = 'search',
   LINK_DEMO_ORG = 'linkToDemoOrg',
   VERIFY_EMAIL = 'verifyEmail',
}
