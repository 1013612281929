<div class="generate-plans">
   <button
      mat-button
      color="primary"
      *ngIf="canEdit$ | async"
      (click)="openGeneratePlansDialog()"
      data-test-id="generate-plans-button"
   >
      Add Training Plans
   </button>
</div>

<app-table [config]="tableConfig" [filterBar]="filterConfig"></app-table>

<ng-template #generateTrainingPlansDialogTemplate>
   <div mat-dialog-title>Add Training Plans</div>
   <mat-dialog-content class="generate-plans-dialog">
      <mat-horizontal-stepper #stepper>
         <mat-step label="Select Tasks">
            <div class="task-select">
               <div class="task-select-table">
                  <app-filter-bar [config]="taskRatingsFilterConfig"></app-filter-bar>
                  {{ planGenerationTaskIds.length }} Tasks Selected
                  <table
                     mat-table
                     matSort
                     [dataSource]="taskRatingsDataSource$"
                     (matSortChange)="triggerSort($event)"
                  >
                     <ng-container matColumnDef="checkbox">
                        <th mat-header-cell *matHeaderCellDef>
                           <mat-checkbox
                              [checked]="allSelected()"
                              [indeterminate]="planGenerationTaskIds.length > 0 && !allSelected()"
                              (change)="toggleSelectAll($event)"
                           ></mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                           <mat-checkbox
                              [checked]="isSelected(row)"
                              (change)="toggleSelected(row)"
                           ></mat-checkbox>
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="task">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Task</th>
                        <td mat-cell *matCellDef="let row">
                           {{ taskViewModel(row.taskId)?.task?.name }}
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="departmentFunction">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Function</th>
                        <td mat-cell *matCellDef="let row">
                           {{ taskViewModel(row.taskId)?.departmentFunction?.name }}
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="department">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Department</th>
                        <td mat-cell *matCellDef="let row">
                           {{ taskViewModel(row.taskId)?.department?.name }}
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="businessUnit">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Business Unit</th>
                        <td mat-cell *matCellDef="let row">
                           {{ taskViewModel(row.taskId)?.businessUnit?.name }}
                        </td>
                     </ng-container>
                     <ng-container matColumnDef="rating">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Rating</th>
                        <td mat-cell *matCellDef="let row">
                           {{ competencyLevelMap[row.rating] }}
                        </td>
                     </ng-container>
                     <tr mat-header-row *matHeaderRowDef="taskListColumns"></tr>
                     <tr mat-row *matRowDef="let row; columns: taskListColumns"></tr>
                  </table>
               </div>
            </div>
         </mat-step>
         <mat-step label="Training Plan Details">
            <div class="targets">
               <mat-form-field>
                  <mat-label>Target Rating</mat-label>
                  <mat-select [(ngModel)]="planGenerationTargetRating">
                     <mat-option *ngFor="let level of competencyLevels" [value]="level">
                        {{ level.label }}
                     </mat-option>
                  </mat-select>
               </mat-form-field>
               <mat-form-field>
                  <input
                     matInput
                     [(ngModel)]="planGenerationTargetDate"
                     placeholder="Target Date"
                     [matDatepicker]="targetDatePicker"
                     (dateChange)="setTargetDate($event)"
                  />
                  <app-datepicker-clear
                     class="datepicker-clear"
                     [for]="targetDatePicker"
                  ></app-datepicker-clear>
                  <mat-datepicker-toggle matSuffix [for]="targetDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #targetDatePicker></mat-datepicker>
               </mat-form-field>
            </div>
         </mat-step>
      </mat-horizontal-stepper>
   </mat-dialog-content>

   <mat-dialog-actions class="stepper-actions">
      <div class="left-buttons">
         <button
            mat-raised-button
            color="primary"
            *ngIf="showPlanStepperPrevious()"
            (click)="planStepperPrevious()"
         >
            Back
         </button>
      </div>
      <div class="right-buttons">
         <button mat-stroked-button color="primary" matDialogClose>Cancel</button>
         <button
            mat-raised-button
            color="primary"
            *ngIf="showPlanStepperNext(); else generatePlansButton"
            (click)="planStepperNext()"
            [disabled]="!planGenerationTaskIds?.length"
         >
            Next
         </button>
         <ng-template #generatePlansButton>
            <button
               mat-raised-button
               color="primary"
               (click)="generatePlans()"
               [disabled]="!planGenerationTargetDate || !planGenerationTargetRating"
            >
               Add Training Plans
            </button>
         </ng-template>
      </div>
   </mat-dialog-actions>
</ng-template>

<ng-template #editTrainingPlanDialogTemplate>
   <div mat-dialog-title>Edit Training Plan</div>
   <mat-dialog-content class="edit-content">
      <div class="edit-row">
         <mat-form-field class="edit-item">
            <input
               matInput
               readonly
               [(ngModel)]="taskViewModel(editTrainingPlan.taskId).task.name"
               placeholder="Task"
            />
         </mat-form-field>
         <mat-form-field class="edit-item">
            <mat-label>Status</mat-label>
            <mat-select [(ngModel)]="editTrainingPlan.status" (ngModelChange)="checkCompleted()">
               <mat-option *ngFor="let key of statusKeys" [value]="key">
                  {{ status[key] }}
               </mat-option>
            </mat-select>
         </mat-form-field>
      </div>
      <div class="edit-row">
         <mat-form-field class="edit-item">
            <mat-label>Start Rating</mat-label>
            <mat-select disabled [(ngModel)]="editTrainingPlan.startRating">
               <mat-option *ngFor="let level of competencyLevels" [value]="level.value">
                  {{ level.label }}
               </mat-option>
            </mat-select>
         </mat-form-field>
         <mat-form-field class="edit-item">
            <mat-label>Target Rating</mat-label>
            <mat-select [(ngModel)]="editTrainingPlan.targetRating">
               <mat-option *ngFor="let level of competencyLevels" [value]="level.value">
                  {{ level.label }}
               </mat-option>
            </mat-select>
         </mat-form-field>
      </div>
      <div class="edit-row">
         <mat-form-field class="action-edit-date action-field edit-item">
            <input
               matInput
               placeholder="Start Date"
               [ngModel]="editTrainingPlan.startDate"
               [matDatepicker]="editStartDatePicker"
               (dateChange)="setDate($event, editTrainingPlan, 'startDate')"
            />
            <app-datepicker-clear
               class="datepicker-clear"
               [for]="editStartDatePicker"
            ></app-datepicker-clear>
            <mat-datepicker-toggle matSuffix [for]="editStartDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #editStartDatePicker></mat-datepicker>
         </mat-form-field>
         <mat-form-field class="action-edit-date action-field edit-item">
            <input
               matInput
               placeholder="Target Date"
               [ngModel]="editTrainingPlan.targetDate"
               [matDatepicker]="editTargetDatePicker"
               (dateChange)="setDate($event, editTrainingPlan, 'targetDate')"
            />
            <app-datepicker-clear
               class="datepicker-clear"
               [for]="editTargetDatePicker"
            ></app-datepicker-clear>
            <mat-datepicker-toggle matSuffix [for]="editTargetDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #editTargetDatePicker></mat-datepicker>
         </mat-form-field>
         <mat-form-field class="action-edit-date action-field edit-item">
            <input
               matInput
               placeholder="Completed Date"
               [ngModel]="editTrainingPlan.completedDate"
               [matDatepicker]="editCompletedDatePicker"
               (dateChange)="setDate($event, editTrainingPlan, 'completedDate')"
            />
            <app-datepicker-clear
               class="datepicker-clear"
               [for]="editCompletedDatePicker"
            ></app-datepicker-clear>
            <mat-datepicker-toggle
               matSuffix
               [for]="editCompletedDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #editCompletedDatePicker></mat-datepicker>
         </mat-form-field>
      </div>
      <app-user-comments
         [comments]="editTrainingPlan.comments"
         (commentsUpdated)="setComments($event)"
      ></app-user-comments>
   </mat-dialog-content>
   <div *ngIf="planCompleted">
      <mat-hint>Completing the training plan will also set the task to the target rating.</mat-hint>
   </div>
   <mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="save()">Save</button>
      <button mat-stroked-button color="primary" (click)="cancel()">Cancel</button>
   </mat-dialog-actions>
</ng-template>

