<button mat-stroked-button color="primary" [matMenuTriggerFor]="columnMenu">
   Edit Columns
   <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #columnMenu>
   <div class="mat-menu-item" *ngFor="let col of columns">
      <mat-checkbox color="primary" [checked]="col.visible" (change)="updateColumn($event, col)">
         {{ col.label }}
      </mat-checkbox>
   </div>
</mat-menu>
