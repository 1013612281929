<div>
   <app-sticky-header
      [title]="title$ | async"
      [saveDisabled]="saveDisabled()"
      [isNew]="isNew"
      [titleFormControl]="getControl('name')"
      [canEdit]="canEdit$ | async"
      [menuOptions]="menuOptions"
      (back)="backToList()"
      (save)="save()"
      (delete)="delete()"
      (titleChange)="setName($event)"
   ></app-sticky-header>
   <app-rock-info
      [rock]="rock$ | async"
      [goals]="goals$ | async"
      [canEdit]="canEdit$ | async"
   ></app-rock-info>
   <div class="actions">
      <app-rock-actions
         [actions]="actions"
         [teamMembers]="teamMembers$ | async"
         [canEdit]="canEdit$ | async"
         (actionsUpdated)="setActions($event)"
      ></app-rock-actions>
   </div>
   <div class="comments">
      <app-user-comments
         [comments]="comments"
         [newest]="true"
         [canEdit]="canEdit$ | async"
         (commentsUpdated)="updateComments($event)"
      ></app-user-comments>
   </div>
</div>
