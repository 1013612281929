import { Injectable, OnDestroy } from '@angular/core';
import { TeamMember } from '@entities/team-member';
import { TeamMemberService } from '../services/team-member.service';
import { Observable, combineLatest, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { State } from '@app/app.state';
import {
   getTeamMembers,
   getSelectedTeamMember,
   getRoles,
   getSelectedRole,
   getTeamMembersForSelectedRole,
   getCompetencies,
   getSelectedCompetency,
   getAssignedCompetencies,
   teamSelectors,
   TaskRatingTreeItem,
} from './team.state';
import * as TeamActions from './team.actions';
import {
   getTasksTree,
   getTasks,
   getBusinessUnits,
   getTasksForRole,
   getTasksForTeamMember,
   getDepartmentFunctionsTree,
   getDepartmentTasksTree,
   OrgTreeItem,
} from '@app/org-builder/state/organization.state';
import { Task } from '@entities/task';
import { Department } from '@entities/department';
import { DepartmentService } from '@app/org-builder/services/department.service';
import { distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';

import { TeamMemberStatus } from '@entities/enums/team-member-status';
import {
   MatLegacyDialogRef as MatDialogRef,
   MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { AssignTasksComponent } from '../components/assign-tasks/assign-tasks.component';
import { DocumentationFacade } from '@app/documentation/state/documentation.facade';
import { Role } from '@entities/role';
import { RoleService } from '../services/role.service';
import { BusinessUnit } from '@entities/business-unit';
import { OrgBuilderFacade } from '@app/org-builder/state/org-builder.facade';
import { AssignRoleComponent } from '../components/assign-role/assign-role.component';
import { DepartmentFunction } from '@entities/department-function';
import {
   AssignedTaskViewModel,
   TaskDocumentationViewModel,
} from '@app/documentation/state/documentation.models';
import { DialogService } from '@app/shared/services/dialog.service';
import { DocumentationActions } from '@app/documentation/state/documentation.actions';
import { Competency } from '@entities/competency';
import { CompetencyService } from '../services/competency.service';
import { PerformanceEvaluationService } from '../services/performance-evaluation.service';
import { PerformanceEvaluation } from '@entities/performance-evaluation';
import { CompetencyLevel } from '@entities/competency-level';
import { CompetencyLevelService } from '../services/competency-level.service';
import { TaskRating } from '@entities/task-rating';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';

export enum TaskAssignTo {
   TEAM_MEMBER,
   ROLE,
   COMPETENCY,
}

@Injectable()
export class TeamFacade implements OnDestroy {
   teamMembers$: Observable<TeamMember[]> = this.store.pipe(select(getTeamMembers));
   selectedTeamMember$: Observable<TeamMember> = this.store.pipe(select(getSelectedTeamMember));
   roles$: Observable<Role[]> = this.store.pipe(select(getRoles));
   selectedRole$: Observable<Role> = this.store.pipe(select(getSelectedRole));
   teamMembersForSelectedRole$: Observable<TeamMember[]> = this.store.pipe(
      select(getTeamMembersForSelectedRole)
   );
   competencies$ = this.store.pipe(select(getCompetencies));
   selectedCompetency$ = this.store.pipe(select(getSelectedCompetency));
   assignedCompetencies$ = this.store.pipe(select(getAssignedCompetencies));
   competencyLevels$ = this.store.pipe(select(teamSelectors.getCompetencyLevels));

   selectedPerformanceEvaluation$ = this.store.pipe(
      select(teamSelectors.getSelectedPerformanceEvaluation)
   );

   orgTree$: Observable<OrgTreeItem[]> = this.store.pipe(select(getTasksTree));
   departmentFunctionTree$: Observable<any[]> = this.store.pipe(select(getDepartmentFunctionsTree));
   taskRatingTree$: Observable<TaskRatingTreeItem[]>;
   selectedTaskRatingTree$: Observable<TaskRatingTreeItem[]>;
   selectedTaskRatingTreeIds$: Observable<string[]> = this.store.pipe(
      select(teamSelectors.getSelectedTaskRatingTreeIds)
   );
   tasksFilter$ = this.store.pipe(select(teamSelectors.getTeamTasksFilter), distinctUntilChanged());
   teamMembersDisplayedColumns$ = this.store.pipe(
      select(teamSelectors.getTeamMembersDisplayedColumns)
   );

   businessUnits$: Observable<BusinessUnit[]>;
   departments$: Observable<Department[]>;
   departmentFunctions$: Observable<DepartmentFunction[]>;
   tasks$: Observable<Task[]>;
   assignedTasks$: Observable<AssignedTaskViewModel[]>;
   tasksForSelectedRole$: Observable<Task[]>;

   private dialogRef: MatDialogRef<any>;
   private destroyed$ = new Subject<void>();

   constructor(
      private teamMemberService: TeamMemberService,
      private store: Store<State>,
      private dialog: MatDialog,
      private documentationFacade: DocumentationFacade,
      private roleService: RoleService,
      private orgBuilderFacade: OrgBuilderFacade,
      private dialogService: DialogService,
      private competencyService: CompetencyService,
      private performanceEvaluationService: PerformanceEvaluationService,
      private competencyLevelService: CompetencyLevelService
   ) {
      this.businessUnits$ = this.orgBuilderFacade.businessUnits$;
      this.departments$ = this.orgBuilderFacade.departments$;
      this.departmentFunctions$ = this.orgBuilderFacade.departmentFunctions$;
      this.tasks$ = this.orgBuilderFacade.tasks$;
      this.teamMemberService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((teamMembers) => {
         this.store.dispatch(TeamActions.TeamMembersUpdated({ teamMembers }));
      });
      this.competencyService.entities$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((competencies) => {
            this.store.dispatch(TeamActions.CompetenciesUpdated({ competencies }));
         });
      this.performanceEvaluationService.entities$
         .pipe(takeUntil(this.destroyed$))
         .subscribe((performanceEvaluations) => {
            this.store.dispatch(
               TeamActions.PerformanceEvaluationsUpdated({ performanceEvaluations })
            );
         });

      this.assignedTasks$ = combineLatest([
         this.store.pipe(select(getSelectedTeamMember)),
         this.documentationFacade.tasksViewModel$,
         this.competencyLevels$,
      ]).pipe(
         map(([teamMember, tasks, competencyLevels]) => {
            if (teamMember && teamMember.assignedTasks && tasks) {
               const taskRatings: AssignedTaskViewModel[] = [];
               const assignedTasks = tasks.filter((t) =>
                  teamMember.assignedTasks?.includes(t.task.id)
               );
               const baseRating = Math.min(...competencyLevels.map((level) => level.value));
               const baseLevel = competencyLevels.find((level) => level.value === baseRating);
               assignedTasks.forEach((viewModel) => {
                  const taskRating = teamMember.taskRatings?.find(
                     (r) => r.taskId === viewModel.task.id
                  );
                  if (taskRating) {
                     const taskLevel =
                        competencyLevels.find(
                           (level) =>
                              level.id === taskRating.competencyLevelId ||
                              level.value === taskRating.rating
                        ) ?? baseLevel;
                     taskRatings.push({
                        ...viewModel,
                        taskRating: {
                           ...taskRating,
                           competencyLevelId: taskLevel.id,
                           rating: taskLevel?.value,
                        },
                     });
                  } else {
                     taskRatings.push({
                        ...viewModel,
                        taskRating: {
                           rating: baseLevel.value,
                           competencyLevelId: baseLevel.id,
                           taskId: viewModel.task.id,
                           notes: null,
                        },
                     });
                  }
               });
               return taskRatings;
            } else {
               return [];
            }
         })
      );

      this.tasksForSelectedRole$ = combineLatest([
         this.store.pipe(select(getSelectedRole)),
         this.documentationFacade.tasksViewModel$,
      ]).pipe(
         map(([role, tasks]) => {
            if (role && role.tasks && tasks) {
               return tasks
                  .filter((t) => role.tasks.includes(t.task.id))
                  .map((viewModel) => viewModel.task);
            } else {
               return [];
            }
         })
      );
      this.roleService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((roles) => {
         this.store.dispatch(TeamActions.RolesUpdated({ roles }));
      });
      this.competencyLevelService.entities$.pipe(takeUntil(this.destroyed$)).subscribe((levels) => {
         if (levels?.length > 0) {
            this.store.dispatch(TeamActions.CompetencyLevelsUpdated({ competencyLevels: levels }));
         }
      });

      this.taskRatingTree$ = combineLatest([this.orgTree$, this.teamMembers$]).pipe(
         map(([orgTree, teamMembers]) => {
            const taskRatings = teamMembers.flatMap((teamMember) =>
               this.getTaskRatings(teamMember)
            );
            return this.buildTaskRatingTree(orgTree, taskRatings);
         })
      );
      this.selectedTaskRatingTree$ = combineLatest([
         this.taskRatingTree$,
         this.store.pipe(select(teamSelectors.getSelectedTaskRatingTreeIds)).pipe(startWith([])),
      ]).pipe(
         map(([tree, ids]) => {
            const subtree = this.getSubtree(tree, [...ids]);
            if (ids.length !== subtree.depth) {
               this.store.dispatch(
                  TeamActions.SelectTaskRatingTreeItem({ ids: ids.slice(0, subtree.depth) })
               );
            }
            return subtree.tree;
         })
      );
   }

   ngOnDestroy() {
      this.destroyed$.next();
      this.destroyed$.complete();
   }

   closeDialog() {
      if (this.dialogRef) {
         this.dialogRef.close();
      }
   }

   addTeamMember() {
      this.store.dispatch(TeamActions.SelectTeamMember({ teamMemberId: null }));
   }

   editTeamMember(teamMemberId: string) {
      this.store.dispatch(TeamActions.EditTeamMember({ teamMemberId }));
   }

   saveTeamMember(
      teamMember: TeamMember,
      options?: { new?: boolean; close?: boolean; overwrite?: boolean }
   ) {
      if (teamMember.id) {
         this.store.dispatch(TeamActions.SaveTeamMember({ teamMember, options }));
      } else {
         this.store.dispatch(TeamActions.CreateTeamMember({ teamMember, options }));
      }
   }

   deleteTeamMember(teamMember: TeamMember) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Team Member?',
            message: 'Are you sure you want to delete this team member?',
            confirm: 'Yes, Delete',
            deny: 'No, Go Back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(TeamActions.DeleteTeamMember({ teamMember }));
            }
         });
   }

   selectTeamMember(teamMemberId: string) {
      this.store.dispatch(TeamActions.SelectTeamMember({ teamMemberId }));
   }

   assignTasks(assignTo: TaskAssignTo = TaskAssignTo.TEAM_MEMBER) {
      this.dialogRef = this.dialog.open(AssignTasksComponent, {
         data: { facade: this, assignTo },
         width: '100%',
         maxWidth: 800,
         height: '80vh',
      });
   }

   cancelAssignTasks() {
      this.dialogRef.close();
   }

   saveTaskAssignments(taskIds: string[], assignTo: TaskAssignTo = TaskAssignTo.TEAM_MEMBER) {
      this.store.dispatch(TeamActions.AssignTasks({ taskIds, assignTo }));
      this.dialogRef.close();
   }

   unassignTask(task: Task, assignedTo: TaskAssignTo = TaskAssignTo.TEAM_MEMBER) {
      this.store.dispatch(TeamActions.UnassignTask({ taskId: task.id, assignedTo }));
   }

   setActive(teamMember: TeamMember) {
      const active: TeamMember = {
         ...teamMember,
         status: TeamMemberStatus.Active,
      };
      this.store.dispatch(TeamActions.SaveTeamMember({ teamMember: active }));
   }

   setInactive(teamMember: TeamMember) {
      const inactive: TeamMember = {
         ...teamMember,
         status: TeamMemberStatus.Inactive,
      };
      this.store.dispatch(TeamActions.SaveTeamMember({ teamMember: inactive }));
   }

   editRole(roleId: string) {
      this.store.dispatch(TeamActions.EditRole({ roleId }));
      // this.dialogRef = this.dialog.open(RoleEditComponent, { data: { facade: this, role } });
   }

   selectRole(roleId: string) {
      this.store.dispatch(TeamActions.SelectRole({ roleId }));
   }

   cancelEditRole() {
      this.dialogRef.close();
   }

   saveRole(role: Role) {
      this.store.dispatch(TeamActions.SaveRole({ role }));
      // this.dialogRef.close();
   }

   deleteRole() {}

   tasksForRole(role: Role) {
      return this.store.pipe(select(getTasksForRole, { role }));
   }

   tasksForTeamMember(teamMember: TeamMember) {
      return this.store.pipe(select(getTasksForTeamMember, { teamMember }));
   }

   assignTeamMembersToRole(role: Role) {
      this.dialogRef = this.dialog.open(AssignRoleComponent, {
         data: { facade: this, role },
         minWidth: 400,
      });
   }

   unassignTeamMemberFromRole(teamMember: TeamMember, role: Role) {}

   saveRoleAssignments(role: Role, teamMembers: TeamMember[]) {
      teamMembers.forEach((teamMember) => {
         if (!teamMember.assignedTasks) {
            teamMember.assignedTasks = [];
         }
         if (!role.teamMembers) {
            role.teamMembers = [];
         }
         teamMember.assignedTasks = [...teamMember.assignedTasks, ...role.tasks];
         teamMember.assignedTasks = teamMember.assignedTasks.filter(
            (task, index) => index == teamMember.assignedTasks.indexOf(task)
         );
         this.store.dispatch(TeamActions.SaveTeamMember({ teamMember }));
      });
      const teamMemberIds = teamMembers.map((t) => t.id);
      const roleToSave = {
         ...role,
         teamMembers: [...role.teamMembers, ...teamMemberIds],
      };
      roleToSave.teamMembers = roleToSave.teamMembers.filter(
         (teamMember, index) => index == roleToSave.teamMembers.indexOf(teamMember)
      );
      this.store.dispatch(TeamActions.SaveRole({ role: roleToSave }));
      this.dialogRef.close();
   }

   viewTask(taskId: string) {
      this.store.dispatch(DocumentationActions.ViewTask({ taskId }));
   }

   saveCompetency(competency: Competency) {
      this.store.dispatch(TeamActions.SaveCompetency({ competency }));
   }

   selectCompetency(competencyId: string) {
      this.store.dispatch(TeamActions.SelectCompetency({ competencyId }));
   }

   editCompetency(competencyId: string) {
      this.store.dispatch(TeamActions.EditCompetency({ competencyId }));
   }

   deleteCompetency(competency: Competency) {
      this.dialogService
         .showConfirmDialog({
            title: 'Delete Competency?',
            message: 'Are you sure you want to delete this competency?',
            confirm: 'Yes, Delete',
            deny: 'No, Go Back',
         })
         .afterClosed()
         .subscribe((result) => {
            if (result) {
               this.store.dispatch(TeamActions.DeleteCompetency({ competency }));
            }
         });
   }

   departmentTasksTree(departmentId: string): Observable<any[]> {
      return this.store.pipe(select(getDepartmentTasksTree, { departmentId }));
   }

   createEvaluation(teamMember: TeamMember, year: number) {
      this.store.dispatch(TeamActions.CreatePerformanceEvaluation({ teamMember, year }));
   }

   selectPerformanceEvaluation(performanceEvaluationId: string) {
      this.store.dispatch(TeamActions.SelectPerformanceEvaluation({ performanceEvaluationId }));
   }

   editPerformanceEvaluation(performanceEvaluationId: string) {
      this.store.dispatch(TeamActions.EditPerformanceEvaluation({ performanceEvaluationId }));
   }

   getPerformanceEvaluations(teamMemberId: string) {
      return this.store.pipe(select(teamSelectors.getPerformanceEvaluations, { teamMemberId }));
   }

   savePerformanceEvaluation(performanceEvaluation: PerformanceEvaluation) {
      this.store.dispatch(TeamActions.SavePerformanceEvaluation({ performanceEvaluation }));
   }

   deletePerformanceEvaluation(performanceEvaluation: PerformanceEvaluation) {
      this.store.dispatch(TeamActions.DeletePerformanceEvaluation({ performanceEvaluation }));
   }

   saveCompetencyLevels(competencyLevels: CompetencyLevel[]) {
      this.store.dispatch(TeamActions.SaveCompetencyLevels({ competencyLevels }));
   }

   getTaskRatings(teamMember: TeamMember) {
      const taskRatings: TaskRating[] = [];
      teamMember.taskRatings?.forEach((taskRating) => {
         taskRatings.push({
            ...taskRating,
            teamMemberId: teamMember.id,
         });
      });
      return taskRatings;
   }

   buildTaskRatingTree(items: OrgTreeItem[], taskRatings: TaskRating[]): TaskRatingTreeItem[] {
      const treeItems: TaskRatingTreeItem[] = [];
      items.forEach((item) => {
         if (item.isTask) {
            const individualRatings = taskRatings.filter((t) => t.taskId === item.id);
            const withRating = individualRatings.filter((r) => r.rating).length;
            const rating =
               individualRatings.map((r) => r.rating).reduce((a, b) => a + b, 0) /
               (withRating || 1);
            treeItems.push({
               id: item.id,
               name: item.name,
               rating,
               individualRatings,
            });
         } else if (item.children) {
            const children = this.buildTaskRatingTree(item.children, taskRatings);
            const withRating = children.filter((c) => c.rating).length;
            const rating =
               children.map((child) => child.rating).reduce((a, b) => a + b, 0) / (withRating || 1);
            treeItems.push({
               id: item.id,
               name: item.name,
               rating,
               children,
            });
         } else {
            treeItems.push({
               id: item.id,
               name: item.name,
               rating: 0,
            });
         }
      });

      return treeItems;
   }

   getSubtree(tree: TaskRatingTreeItem[], ids: string[], depth = 0) {
      if (ids.length > 0) {
         const id = ids.shift();
         const subtree = tree.find((item) => item.id === id);
         if (subtree) {
            if (subtree.children) {
               return this.getSubtree(subtree.children, ids, depth + 1);
            } else if (subtree.individualRatings) {
               return { tree: subtree.individualRatings, depth: depth + 1 };
            }
         }
      }
      return { tree, depth };
   }

   selectTaskRatingItems(ids: string[]) {
      this.store.dispatch(TeamActions.SelectTaskRatingTreeItem({ ids }));
   }

   setTeamTasksFilter(filter: FilterValues) {
      this.store.dispatch(TeamActions.SetTeamTasksFilter({ filter }));
   }

   setTeamMemberDisplayedColumns(columns: string[]) {
      this.store.dispatch(TeamActions.SetDisplayedColumns({ columns }));
   }
}
