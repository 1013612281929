import { createAction, props } from '@ngrx/store';
import { Rock } from '@entities/rock';
import { FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { Sort } from '@angular/material/sort';

export const RocksUpdated = createAction('[Rocks] rocks updated', props<{ rocks: Rock[] }>());
export const AddRock = createAction('[Rocks] Add rock');
export const EditRock = createAction('[Rocks] Edit rock', props<{ rock: Rock }>());
export const CreateRock = createAction(
   '[Rocks] Create rock',
   props<{ rock: Rock; preventNavigation: boolean; showSnackBar?: boolean }>()
);
export const SaveRock = createAction('[Rocks] Save rock', props<{ rock: Rock }>());
export const DeleteRock = createAction('[Rocks] Delete rock', props<{ rock: Rock }>());
export const SelectRock = createAction('[Rocks] Select rock', props<{ rockId: string }>());
export const UpdateRockFilterValues = createAction(
   '[Rocks] Update filter values',
   props<{ filter: FilterValues }>()
);
export const SetActiveTab = createAction('[Rocks] Set active tab', props<{ activeTab: number }>());
export const SetVisibleColumns = createAction(
   '[Rocks] Set visible columns',
   props<{ columns: string[] }>()
);
export const SetSort = createAction('[Rocks] Set sort', props<{ sort: Sort }>());
export const DuplicateRock = createAction('[Rocks] Duplicate rock', props<{ rock: Rock }>());

export const RockActions = {
   RocksUpdated,
   AddRock,
   EditRock,
   CreateRock,
   SaveRock,
   DeleteRock,
   DuplicateRock,
   SelectRock,
   UpdateRockFilterValues,
   SetActiveTab,
   SetVisibleColumns,
   SetSort,
};
