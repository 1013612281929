import { Injectable, inject } from '@angular/core';
import { OrganizationService } from './organization.service';
import { UserService } from './user.service';
import { AuthUserService } from '@app/auth/services/auth-user.service';
import { Organization } from '@entities/organization';
import { AuthUser } from '@entities/auth-user';
import { deepCopy } from '@app/utilities/deep-copy';
import { ProductType } from '@entities/enums/product-type';
import { User } from '@entities/user';
import { UserRole } from '@entities/enums/user-role';

@Injectable({
   providedIn: 'root',
})
export class OrgCreateService {
   private orgService = inject(OrganizationService);
   private userService = inject(UserService);
   private authUserService = inject(AuthUserService);

   async createOrg(org: Organization, authUser: AuthUser) {
      const organization: Organization = {
         ...deepCopy(org),
         authUsers: [authUser.uid],
      };
      const plan = organization.billingInfo.plans.find(
         (p) => p.productType == ProductType.Complete
      );
      plan.assigned = 1;
      plan.available--;
      const createdOrg = await this.orgService.save(organization);
      const adminUser: User = {
         id: null,
         displayName: authUser.displayName,
         email: authUser.email,
         organizationId: createdOrg.organizationId,
         userAccountId: authUser.uid,
         firstName: authUser.firstName,
         lastName: authUser.lastName,
         role: UserRole.Admin,
         isAdmin: true,
         products: [ProductType.Complete],
         product: ProductType.Complete,
         active: true,
      };
      const savedUser = await this.userService.save(adminUser, createdOrg.organizationId);
      adminUser.id = savedUser.id;
      const authUserToUpdate: AuthUser = {
         ...authUser,
         orgs: [...authUser.orgs, createdOrg.organizationId],
      };
      const updatedAuthUser = await this.authUserService.save(authUserToUpdate);
      return {
         createdOrg,
         updatedAuthUser,
         adminUser,
      };
   }
}

