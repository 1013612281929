<div class="right-buttons">
   <button
      mat-icon-button
      class="download-button"
      color="accent"
      [matMenuTriggerFor]="csvMenu"
      matTooltip="Export as .csv"
   >
      <mat-icon svgIcon="file-download-outline"></mat-icon>
   </button>
   <mat-menu #csvMenu="matMenu">
      <button mat-menu-item (click)="export(false)">Export All</button>
      <button mat-menu-item (click)="export(true)">Export Filtered</button>
   </mat-menu>
   <button
      mat-stroked-button
      class="save-button"
      color="primary"
      [disabled]="form.pristine"
      (click)="saveAll()"
   >
      <mat-icon>save</mat-icon>
      Save All
   </button>
</div>
<app-filter-bar
   [config]="filterBarConfig"
   [filter]="filterChange$ | async"
   (filterChange)="filterFormChanged($event)"
></app-filter-bar>
<app-performance-task-details
   *ngFor="let viewModel of tasks$ | async; trackBy: trackByFn"
   [viewModel]="viewModel"
   [canEdit]="canEdit$ | async"
   (dirtyChange)="setDirty(viewModel.task.id, $event)"
></app-performance-task-details>
