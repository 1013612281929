import {
   Component,
   OnInit,
   Input,
   Output,
   EventEmitter,
   SimpleChanges,
   OnChanges,
   ViewChild,
   TemplateRef,
} from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, Subject, of } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { TableService } from '@app/shared/services/table.service';
import { map, takeUntil } from 'rxjs/operators';
import { Task } from '@entities/task';
import { TableColumn } from '@app/shared/interfaces/table-column.interfaces';
import { AssignedTaskViewModel } from '@app/documentation/state/documentation.models';
import { TableConfig } from '@app/shared/components/table/table.component';
import { FilterBarConfig, FilterValues } from '@app/shared/interfaces/filter.interfaces';
import { CompetencyLevel } from '@entities/competency-level';
import { TaskRating } from '@entities/task-rating';
import { TeamFacade } from '@app/team/state/team.facade';

const initialSort: Sort = { active: 'name', direction: 'asc' };

@Component({
   selector: 'app-assigned-tasks',
   templateUrl: './assigned-tasks.component.html',
   styleUrls: ['./assigned-tasks.component.scss'],
})
export class AssignedTasksComponent implements OnInit, OnChanges {
   @Input('tasks') tasks$: Observable<AssignedTaskViewModel[]>;
   @Input() competencyLevels: CompetencyLevel[];
   @Input() filename: string;
   @Input() canEdit = true;

   @Output() assign = new EventEmitter();
   @Output() unassign = new EventEmitter();
   @Output() exportTasks = new EventEmitter();
   @Output() view = new EventEmitter<string>();

   @ViewChild('editRatingTemplateRef') editRatingTemplateRef: TemplateRef<any>;

   competencyLevelsMap: { [key: number]: string } = {};

   displayedColumns: string[] = [
      'task.name',
      'departmentFunction.name',
      'department.name',
      'businessUnit.name',
      'rating.rating',
      'options',
   ];

   /* istanbul ignore next */
   columns: TableColumn<AssignedTaskViewModel>[] = [
      { def: 'task.name', label: 'Task', visible: true, value: (row) => row.task?.name },
      {
         def: 'departmentFunction.name',
         label: 'Function',
         visible: true,
         value: (row) => row.departmentFunction?.name,
         filter: 'departmentFunctions',
      },
      {
         def: 'department.name',
         label: 'Department',
         visible: true,
         value: (row) => row.department?.name,
         filter: 'departments',
      },
      {
         def: 'businessUnit.name',
         label: 'BusinessUnit',
         visible: true,
         value: (row) => row.businessUnit?.name,
         filter: 'businessUnits',
      },
      {
         def: 'taskRating.rating',
         label: 'Rating',
         value: (row) => this.competencyLevelsMap[row.taskRating?.rating],
         visible: this.canEdit,
         filter: 'rating',
         filterValue: (row) => row.taskRating?.rating,
      },
   ];

   tableConfig: TableConfig;
   filterBarConfig: FilterBarConfig;

   selectedTask: AssignedTaskViewModel;
   tasks: AssignedTaskViewModel[] = [];
   updatedTasks: AssignedTaskViewModel[] = [];

   dataSource$: Observable<any[]>;
   sort$: BehaviorSubject<Sort>;
   filter$ = this.teamFacade.tasksFilter$;
   updatedTasks$ = new BehaviorSubject<AssignedTaskViewModel[]>([]);

   private destroyed$ = new Subject<void>();

   constructor(private tableService: TableService, private teamFacade: TeamFacade) {
      this.sort$ = new BehaviorSubject(initialSort);
   }

   ngOnInit() {
      this.tasks$.pipe(takeUntil(this.destroyed$)).subscribe((tasks) => {
         this.tasks = tasks;
         this.updatedTasks$.next(tasks);
      });
      this.dataSource$ = combineLatest([
         this.updatedTasks$.asObservable(),
         this.sort$.asObservable(),
      ]).pipe(
         map(([tasks, sort]) => {
            return this.tableService.sort(tasks, sort);
         })
      );

      /* istanbul ignore next */
      this.tableConfig = {
         data$: this.dataSource$,
         filter$: this.filter$,
         columns: this.columns,
         visibleColumns$: of([
            'task.name',
            'departmentFunction.name',
            'department.name',
            'businessUnit.name',
         ]),
         initialSort: { direction: 'asc', active: 'task.name' },
         rowClick: (row) => this.viewTask(row.task),
         options: [
            { label: 'View Task', action: (row) => this.viewTask(row.task) },
            {
               label: 'Unassign',
               action: (row) => this.unassignTask(row.task),
               condition: () => this.canEdit,
            },
         ],
         exportFilename: this.filename,
      };

      this.filterBarConfig = {
         businessUnits: true,
         departments: true,
         departmentFunctions: true,
         rating: this.competencyLevelsMap,
         filterChange: (filter) => this.teamFacade.setTeamTasksFilter(filter),
      };
   }

   ngOnChanges(changes: SimpleChanges) {
      if (changes['competencyLevels']) {
         if (this.competencyLevels?.length > 0) {
            this.competencyLevelsMap = {};
            this.competencyLevels.forEach((level) => {
               this.competencyLevelsMap[level.value] = level.label;
            });
         }
      }
   }

   triggerSort(sort: Sort) {
      this.sort$.next(sort);
   }

   assignTasks() {
      this.assign.emit();
      // this.teamFacade.assignTasks();
   }

   unassignTask(task: Task) {
      this.unassign.emit(task);
      // this.teamFacade.unassignTask(task);
   }

   export() {
      this.exportTasks.emit();
   }

   viewTask(task: Task) {
      this.view.emit(task.id);
   }
}
