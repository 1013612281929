import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { State, NoOpAction } from '@app/app.state';
import { Router } from '@angular/router';
import { ErrorService } from '@app/shared/services/error.service';
import { UserOrgActions, OrganizationCreated } from './user-org.actions';
import { withLatestFrom, map, catchError, tap, switchMap } from 'rxjs/operators';
import { getAuthUser } from '@app/auth/state/auth.state';
import { of, from } from 'rxjs';
import { OrganizationService } from '../services/organization.service';
import { UserService } from '../services/user.service';
import { appRoutesNames } from '@app/app.routes.names';
import { FirebaseAuthService } from '@app/auth/services/firebase-auth.service';
import { AuthUserService } from '@app/auth/services/auth-user.service';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { FunctionNames } from '@app/utilities/functionNames';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthUserUpdated } from '@app/auth/state/auth.actions';
import { FunctionsService } from '@app/shared/services/functions.service';
import { adminActions } from '@app/admin/state/admin.actions';
import { FeatureFlagService } from '@app/internal-tools/services/feature-flag.service';
import { FeatureFlagKeys } from '@app/internal-tools/state/feature-flag-keys';
import { OrgCreateService } from '../services/org-create.service';
import { UserOrgService } from '../services/user-org.service';

@Injectable()
export class UserOrgEffects {
   invitesAccepted: string[] = [];

   constructor(
      private actions$: Actions,
      private store: Store<State>,
      private router: Router,
      private errorService: ErrorService,
      private authService: FirebaseAuthService,
      private organizationService: OrganizationService,
      private userService: UserService,
      private authUserService: AuthUserService,
      private functions: FunctionsService,
      private snackBar: MatSnackBar,
      private featureFlagService: FeatureFlagService,
      private orgCreateService: OrgCreateService,
      private userOrgService: UserOrgService
   ) {}

   createOrganization$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.CreateOrganization),
            withLatestFrom(
               this.store.pipe(select(getAuthUser)),
               this.featureFlagService.isEnabled(FeatureFlagKeys.PERMISSION_GROUPS)
            ),
            switchMap(([{ organization }, authUser, permissionGroupsEnabled]) => {
               return from(this.orgCreateService.createOrg(organization, authUser)).pipe(
                  map(({ createdOrg, updatedAuthUser, adminUser }) => {
                     return {
                        createdOrg,
                        updatedAuthUser,
                        adminUser,
                        permissionGroupsEnabled,
                     };
                  })
               );
            }),
            tap(({ createdOrg, updatedAuthUser, adminUser, permissionGroupsEnabled }) => {
               this.store.dispatch(AuthUserUpdated({ authUser: updatedAuthUser }));
               this.store.dispatch(OrganizationCreated({ organization: createdOrg, adminUser }));
               this.store.dispatch(
                  UserOrgActions.SelectOrganization({
                     organizationId: createdOrg.organizationId,
                  })
               );
               if (permissionGroupsEnabled) {
                  this.store.dispatch(adminActions.SetDefaultPermissionGroups());
               }
            })
         ),
      { dispatch: false }
   );

   saveOrganization$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.SaveOrgInfo),
            tap((action) => {
               this.organizationService.save(action.organization).catch((error) => {
                  this.errorService.handleError(error);
               });
            })
         ),
      { dispatch: false }
   );

   selectOrganization$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.SelectOrganization),
            tap((action) => {
               const url = localStorage.getItem('redirect');
               if (url) {
                  localStorage.removeItem('redirect');
                  this.router.navigateByUrl(url);
               } else {
                  this.router.navigate([
                     appRoutesNames.ORGANIZATION,
                     action.organizationId,
                     appRoutesNames.DASHBOARD,
                  ]);
               }
            })
         ),
      { dispatch: false }
   );

   routerNavigation$ = createEffect(() =>
      this.actions$.pipe(
         ofType(ROUTER_NAVIGATION),
         map((action: RouterNavigationAction) => {
            if (
               action.payload &&
               action.payload.routerState &&
               action.payload.routerState.root &&
               action.payload.routerState.root.firstChild &&
               action.payload.routerState.root.firstChild.params
            ) {
               const organizationId = action.payload.routerState.root.firstChild.params['orgId'];
               if (organizationId) {
                  return UserOrgActions.SetOrganization({ organizationId });
               }
            }
            return new NoOpAction();
         })
      )
   );

   saveAuthUser$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.SaveAuthUser),
            tap((action) => {
               this.authService
                  .updateEmail(action.authUser.email)
                  .then(() => {
                     return this.authUserService.save(action.authUser);
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );

   leaveOrganization$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.LeaveOrganization),
            switchMap((action) => {
               return from(
                  this.functions.httpsCallable(FunctionNames.LEAVE_ORG)({
                     organizationId: action.organization.organizationId,
                  })
               ).pipe(
                  map(() => {
                     this.snackBar.open(`You have left ${action.organization.name}`);
                  }),
                  catchError((error) => {
                     this.errorService.handleError(error);
                     return of();
                  })
               );
            })
         ),
      { dispatch: false }
   );

   verifyEmail$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.VerifyEmail),
            tap(({ authUser }) => {
               this.functions
                  .httpsCallable(FunctionNames.VERIFY_EMAIL)({
                     authUser,
                  })
                  .then((result: any) => {
                     if (!result.error) {
                        this.authUserService.save({
                           ...authUser,
                           verificationSent: true,
                        });
                     }
                  });
            })
         ),
      { dispatch: false }
   );

   emailVerified$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.EmailVerified),
            tap(({ authUser }) => {
               this.authUserService.save({
                  ...authUser,
                  emailVerified: true,
               });
            })
         ),
      { dispatch: false }
   );

   inviteAccepted$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(UserOrgActions.InviteAccepted),
            switchMap(({ authUser, organizationId }) => {
               return from(this.userOrgService.getOrgsAndUsers(authUser.uid)).pipe(
                  map(({ orgs, users }) => {
                     return { orgs, users, organizationId };
                  })
               );
            }),
            tap(({ orgs, users, organizationId }) => {
               this.store.dispatch(UserOrgActions.OrganizationsUpdated({ orgs }));
               this.store.dispatch(UserOrgActions.UsersUpdated({ users }));
               this.store.dispatch(UserOrgActions.SelectOrganization({ organizationId }));
            })
         ),
      { dispatch: false }
   );
}
