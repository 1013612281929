<div class="search-header">Search results for "{{ searchTerm$ | async }}"</div>

<mat-accordion multi>
   <ng-container *ngFor="let category of searchResults$ | async">
      <mat-expansion-panel class="search-category" [expanded]="true">
         <mat-expansion-panel-header class="search-category-header">
            <mat-panel-title class="search-category-label">
               {{ category.label }}
            </mat-panel-title>
            <mat-panel-description class="search-category-count">
               {{ category.items.length }} results
            </mat-panel-description>
         </mat-expansion-panel-header>
         <div class="search-result-items">
            <mat-card
               class="search-result-item"
               *ngFor="let item of category.items"
               (click)="goToItem(item, category.label)"
            >
               <mat-card-title>
                  {{ item.name }}
               </mat-card-title>
               <mat-card-content>
                  <ng-container [ngSwitch]="category.ref">
                     <ng-container *ngSwitchCase="'departmentFunction'">
                        <div class="breadcrumbs">
                           <div class="breadcrumb-text">
                              {{ item.result.businessUnit?.name }}
                           </div>
                           <mat-icon class="breadcrumb-icon">chevron_right</mat-icon>
                           <div class="breadcrumb-text">
                              {{ item.result.department?.name }}
                           </div>
                        </div>
                        <div class="details">
                           <div class="team-member" matTooltip="lead">
                              <mat-icon>person</mat-icon>
                              <div class="team-member-name">
                                 {{
                                    item.result.departmentFunction?.leadId
                                       | teamMemberName: teamMembers
                                 }}
                              </div>
                           </div>
                           <div class="tasks">
                              <mat-icon>list</mat-icon>
                              <div class="tasks-count">
                                 {{ item.result?.departmentFunction?.tasks?.length }}
                              </div>
                           </div>
                        </div>
                     </ng-container>
                     <ng-container *ngSwitchCase="'task'">
                        <div class="item-breadcrumbs">
                           {{ item.result.businessUnit?.name }} >
                           {{ item.result.department?.name }} >
                           {{ item.result.departmentFunction.name }}
                        </div>
                     </ng-container>
                     <ng-container *ngSwitchCase="'actionItem'">
                        <div class="item-name">
                           {{ item.name }}
                        </div>
                     </ng-container>
                     <ng-container *ngSwitchCase="'goal'">
                        <div class="item-name">
                           {{ item.name }}
                        </div>
                     </ng-container>
                     <ng-container *ngSwitchCase="'rock'">
                        <div class="item-name">
                           {{ item.name }}
                        </div>
                     </ng-container>
                     <ng-container *ngSwitchCase="'teamMember'">
                        <div class="item-name">
                           {{ item.name }}
                        </div>
                     </ng-container>
                  </ng-container>
               </mat-card-content>
            </mat-card>
         </div>
      </mat-expansion-panel>
   </ng-container>
</mat-accordion>

