<button mat-button color="primary" (click)="back()">
   <mat-icon>arrow_back</mat-icon>
   Back
</button>
<ng-container *ngIf="teamMember">
   <div class="editor" [froalaEditor]="options">
      <div style="min-height: 10in">
         <app-report-header
            *ngIf="orgName"
            [orgName]="orgName"
            [logo]="logo"
            category="Role Description"
            title="{{ teamMember.firstName }} {{ teamMember.lastName }}"
            [subtitle]="teamMember.title"
         ></app-report-header>
         <div>
            <div style="font-size: 12pt; font-weight: bold">
               Primary Department:
               {{ teamMember.primaryDepartmentId | departmentName : departments }}
            </div>
            <div style="font-size: 12pt; font-weight: bold">
               Manager: {{ teamMember.managerId | teamMemberName : teamMembers }}
            </div>
         </div>
         <div style="display: flex; flex-direction: row; padding-top: 1rem">
            <div
               style="flex: 1 1 33%; margin-right: 12pt"
               [style.border-right]="
                  manages.length > 0 || leads.length > 0 ? '1pt solid #757575' : 'none'
               "
               *ngIf="directs.length > 0"
            >
               <div style="font-size: 12pt; font-weight: bold">Director Assignments</div>
               <div *ngFor="let bu of directs">
                  {{ bu.name }}
               </div>
            </div>
            <div
               style="flex: 1 1 33%; margin-right: 12pt"
               [style.border-right]="leads.length > 0 ? '1pt solid #757575' : 'none'"
               *ngIf="manages.length > 0"
            >
               <div style="font-size: 12pt; font-weight: bold">Manager Assignments</div>
               <div *ngFor="let dept of manages">
                  {{ dept.name }}
               </div>
            </div>
            <div style="flex: 1 1 33%" *ngIf="leads.length > 0">
               <div style="font-size: 12pt; font-weight: bold">Lead Assignments</div>
               <div *ngFor="let deptFn of leads; index as i">
                  <div
                     style="font-size: 10pt; text-transform: uppercase"
                     *ngIf="i == 0 || leads[i].departmentId != leads[i - 1].departmentId"
                  >
                     {{ deptFn.departmentId | departmentName : departments }}
                  </div>
                  <div style="padding-left: 10px">
                     {{ deptFn.name }}
                  </div>
               </div>
            </div>
         </div>
      </div>
      <hr class="no-print" />
      <div style="min-height: 10in; page-break-before: always; margin-top: 0.5in">
         <app-report-header
            *ngIf="orgName"
            [orgName]="orgName"
            [logo]="logo"
            category="Role Description"
            title="{{ teamMember.firstName }} {{ teamMember.lastName }}"
            [subtitle]="teamMember.title"
         ></app-report-header>
         <div
            style="
               font-size: 16pt;
               font-weight: bold;
               border-bottom: 1pt solid #757575;
               margin-bottom: 1rem;
            "
         >
            Task Assignments
         </div>
         <div *ngFor="let viewModel of taskViewModels; index as i">
            <div
               *ngIf="
                  i == 0 ||
                  taskViewModels[i].department.name != taskViewModels[i - 1].department.name
               "
               style="font-size: 12pt; font-weight: bold; text-transform: uppercase"
            >
               {{ viewModel.department.name }}
            </div>
            <div
               *ngIf="
                  i == 0 ||
                  taskViewModels[i].departmentFunction.name !=
                     taskViewModels[i - 1].departmentFunction.name
               "
               style="font-size: 12pt; font-weight: bold; padding-left: 1rem"
            >
               {{ viewModel.departmentFunction.name }}
            </div>
            <div style="padding-left: 2rem">
               {{ viewModel.task.name }}
            </div>
            <!-- <div *ngIf="viewModel.task.description" style="padding-left: 3rem;">
               {{ viewModel.task.description }}
            </div> -->
         </div>
         <div
            style="
               font-size: 16pt;
               font-weight: bold;
               border-bottom: 1pt solid #757575;
               margin-bottom: 1rem;
            "
         >
            Training Plans
         </div>
         <table style="width: 100%">
            <thead>
               <tr>
                  <th>Task</th>
                  <th>Rating</th>
                  <th>Start Date</th>
                  <th>Target Date</th>
                  <th>Target Rating</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let viewModel of taskViewModels; index as i">
                  <td>
                     {{ viewModel.task?.name }}
                  </td>
                  <td>{{ competencyLevelsMap[viewModel.taskRating?.competencyLevelId] }}</td>
                  <td>{{ viewModel.trainingPlan?.startDate | date : 'MM/dd/yyyy' }}</td>
                  <td>{{ viewModel.trainingPlan?.targetDate | date : 'MM/dd/yyyy' }}</td>
                  <td>{{ competencyLevelsMap[viewModel.trainingPlan?.targetRatingId] }}</td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
</ng-container>
