import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { from, of } from 'rxjs';
import { withLatestFrom, tap, map, catchError, switchMap } from 'rxjs/operators';

import {
   getSelectedTeamMember,
   getSelectedRole,
   getTeamMembers,
   getCompetencies,
   getTeamMember,
   teamSelectors,
} from './team.state';
import { TaskAssignTo } from './team.facade';
import * as TeamActions from './team.actions';

import { State } from '@app/app.state';
import { appRoutesNames } from '@app/app.routes.names';
import { teamRouteNames } from '../team.routes.names';
import { TaskService } from '@app/org-builder/services/task.service';
import { getDepartments, getTasks } from '@app/org-builder/state/organization.state';
import { ErrorService } from '@app/shared/services/error.service';
import { getOrganizationId } from '@app/user-org/state/user-org.selectors';

import { CompetencyLevelService } from '../services/competency-level.service';
import { CompetencyService } from '../services/competency.service';
import { PerformanceEvaluationService } from '../services/performance-evaluation.service';
import { RoleService } from '../services/role.service';
import { TeamMemberService } from '../services/team-member.service';

import { PerformanceEvaluation } from '@entities/performance-evaluation';
import { PerformanceEvaluationItem } from '@entities/performance-evaluation-item';
import { PerformanceEvaluationStatus } from '@entities/enums/performance-evaluation-status';
import { Role } from '@entities/role';
import { Task } from '@entities/task';
import { TeamMember } from '@entities/team-member';
import { v4 } from 'uuid';

@Injectable()
export class TeamEffects {
   constructor(
      private actions$: Actions,
      private store: Store<State>,
      private teamMemberService: TeamMemberService,
      private errorService: ErrorService,
      private snackBar: MatSnackBar,
      private router: Router,
      private roleService: RoleService,
      private taskService: TaskService,
      private competencyService: CompetencyService,
      private performanceEvaluationService: PerformanceEvaluationService,
      private dialog: MatDialog,
      private competencyLevelService: CompetencyLevelService
   ) {}

   createTeamMember$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.CreateTeamMember),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            switchMap(([action, orgId]) => {
               const andClose = action.options ? action.options.close : false;
               const andNew = action.options ? action.options.new : false;
               return from(this.teamMemberService.save(action.teamMember)).pipe(
                  tap((saved) => {
                     this.snackBar.open('Team Member Saved');
                     this.store.dispatch(TeamActions.SelectTeamMember({ teamMemberId: saved.id }));
                     if (andNew) {
                        this.store.dispatch(TeamActions.SelectTeamMember({ teamMemberId: null }));
                     } else if (andClose) {
                        this.router.navigate([
                           appRoutesNames.ORGANIZATION,
                           orgId,
                           appRoutesNames.TEAM,
                        ]);
                     } else {
                        this.router.navigate(
                           [
                              appRoutesNames.ORGANIZATION,
                              orgId,
                              appRoutesNames.TEAM,
                              teamRouteNames.TEAM_MEMBERS,
                              saved.id,
                           ],
                           { replaceUrl: true }
                        );
                     }
                  }),
                  catchError((error) => {
                     this.errorService.handleError(error);
                     return of();
                  })
               );
            })
         ),
      { dispatch: false }
   );

   saveTeamMember$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.SaveTeamMember),
            withLatestFrom(
               this.store.pipe(select(getOrganizationId)),
               this.store.pipe(select(getTeamMembers))
            ),
            tap(([action, orgId, teamMembers]) => {
               const overwrite = action.options ? action.options.overwrite : false;
               const andClose = action.options ? action.options.close : false;
               const andNew = action.options ? action.options.new : false;
               let toUpdate = [];
               if (action.teamMember?.directReports?.length > 0) {
                  const addManagerId = teamMembers
                     .filter(
                        (t) =>
                           action.teamMember.directReports.includes(t.id) &&
                           t.managerId !== action.teamMember.id
                     )
                     .map((t) => ({ ...t, managerId: action.teamMember.id }));
                  const removeManagerId = teamMembers
                     .filter(
                        (t) =>
                           t.managerId === action.teamMember.id &&
                           !action.teamMember.directReports.includes(t.id)
                     )
                     .map((t) => ({ ...t, managerId: null }));
                  toUpdate = [...addManagerId, ...removeManagerId];
               }
               const removeDirectReport = teamMembers
                  .filter(
                     (t) =>
                        t.directReports?.includes(action.teamMember.managerId) &&
                        action.teamMember.managerId !== t.id
                  )
                  .map((t) => ({
                     ...t,
                     directReports: t.directReports.filter((id) => id !== action.teamMember.id),
                  }));
               const addDirectReport = teamMembers
                  .filter(
                     (t) =>
                        t.id === action.teamMember.managerId &&
                        !t.directReports?.includes(action.teamMember.managerId)
                  )
                  .map((t) => ({
                     ...t,
                     directReports:
                        t.directReports?.length > 0
                           ? t.directReports.concat(action.teamMember.id)
                           : [action.teamMember.id],
                  }));
               toUpdate = [...toUpdate, ...addDirectReport, ...removeDirectReport];
               this.teamMemberService
                  .batchSave(toUpdate)
                  .then(() => {
                     return this.teamMemberService.save(action.teamMember, overwrite);
                  })
                  .then(() => {
                     this.snackBar.open('Team Member Saved');
                     if (andNew) {
                        this.store.dispatch(TeamActions.SelectTeamMember({ teamMemberId: null }));
                        this.router.navigate([
                           appRoutesNames.ORGANIZATION,
                           orgId,
                           appRoutesNames.TEAM,
                           teamRouteNames.TEAM_MEMBERS,
                           'new',
                        ]);
                     } else if (andClose) {
                        this.router.navigate([
                           appRoutesNames.ORGANIZATION,
                           orgId,
                           appRoutesNames.TEAM,
                        ]);
                     }
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );

   editTeamMember$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.EditTeamMember),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ teamMemberId }, orgId]) => {
               this.router.navigate([
                  appRoutesNames.ORGANIZATION,
                  orgId,
                  appRoutesNames.TEAM,
                  teamRouteNames.TEAM_MEMBERS,
                  teamMemberId,
               ]);
            })
         ),
      { dispatch: false }
   );

   deleteTeamMember$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.DeleteTeamMember),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([action, orgId]) => {
               this.teamMemberService
                  .delete(action.teamMember)
                  .then(() => {
                     const snackBarRef = this.snackBar.open('Team Member Deleted', 'Undo');
                     snackBarRef.afterDismissed().subscribe((event) => {
                        if (event.dismissedByAction) {
                           this.store.dispatch(
                              TeamActions.SaveTeamMember({
                                 teamMember: action.teamMember,
                                 options: { overwrite: true },
                              })
                           );
                        }
                     });
                     this.router.navigate([
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.TEAM,
                     ]);
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );

   assignTasks$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.AssignTasks),
            withLatestFrom(
               this.store.pipe(select(getSelectedTeamMember)),
               this.store.pipe(select(getSelectedRole)),
               this.store.pipe(select(getTasks)),
               this.store.pipe(select(getTeamMembers))
            ),
            tap(([action, teamMember, role, tasks, teamMembers]) => {
               if (action.assignTo == TaskAssignTo.TEAM_MEMBER) {
                  const toSave: TeamMember = {
                     ...teamMember,
                     assignedTasks: action.taskIds,
                  };
                  this.teamMemberService.save(toSave).then(() => {
                     const updatedTasks = tasks.filter((t) => action.taskIds.includes(t.id));
                     const toSave = [];
                     updatedTasks.forEach((task) => {
                        const assignees = teamMembers
                           .filter((t) => t.assignedTasks?.includes(task.id))
                           .map((t) => t.id);
                        if (!assignees.includes(teamMember.id)) {
                           assignees.push(teamMember.id);
                        }
                        const t: Task = {
                           ...task,
                           assignees,
                        };
                        toSave.push(t);
                     });
                     this.taskService.batchSave(toSave);
                  });
               } else {
                  const toSave: Role = {
                     ...role,
                     tasks: action.taskIds,
                  };
                  this.roleService.save(toSave);
               }
            })
         ),
      { dispatch: false }
   );

   unassignTask$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.UnassignTask),
            withLatestFrom(
               this.store.pipe(select(getSelectedTeamMember)),
               this.store.pipe(select(getSelectedRole))
            ),
            tap(([action, teamMember, role]) => {
               let tasks = [];
               if (action.assignedTo == TaskAssignTo.TEAM_MEMBER) {
                  tasks = [...teamMember.assignedTasks];
                  tasks = tasks.filter((t) => t != action.taskId);
                  const toSave: TeamMember = {
                     ...teamMember,
                     assignedTasks: tasks,
                  };
                  this.teamMemberService.save(toSave);
               } else {
                  tasks = [...role.tasks];
                  tasks = tasks.filter((t) => t != action.taskId);
                  const toSave: Role = {
                     ...role,
                     tasks: tasks,
                  };
                  this.roleService.save(toSave);
               }
            })
         ),
      { dispatch: false }
   );

   saveRole$ = createEffect(() =>
      this.actions$.pipe(
         ofType(TeamActions.SaveRole),
         switchMap((action) => {
            return from(this.roleService.save(action.role)).pipe(
               map((role) => {
                  this.snackBar.open('Role Template Saved');
                  return TeamActions.SelectRole({ roleId: role.id });
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   editRole$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.EditRole),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([action, orgId]) => {
               this.router.navigate([
                  appRoutesNames.ORGANIZATION,
                  orgId,
                  appRoutesNames.TEAM,
                  teamRouteNames.ROLES,
                  action.roleId,
               ]);
            })
         ),
      { dispatch: false }
   );

   saveCompetency$ = createEffect(() =>
      this.actions$.pipe(
         ofType(TeamActions.SaveCompetency),
         withLatestFrom(this.store.pipe(select(getOrganizationId))),
         switchMap(([{ competency }, orgId]) => {
            return from(this.competencyService.save(competency)).pipe(
               map((saved) => {
                  if (!competency.id) {
                     this.router.navigate([
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.TEAM,
                        teamRouteNames.COMPETENCIES,
                        saved.id,
                     ]);
                  }
                  return TeamActions.SelectCompetency({ competencyId: saved.id });
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   deleteCompetency$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.DeleteCompetency),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ competency }, orgId]) => {
               this.competencyService
                  .delete(competency)
                  .then(() => {
                     this.snackBar.open('Competency Deleted');
                     this.router.navigate([
                        appRoutesNames.ORGANIZATION,
                        orgId,
                        appRoutesNames.TEAM,
                        teamRouteNames.COMPETENCIES,
                     ]);
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );

   editCompetency$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.EditCompetency),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ competencyId }, orgId]) => {
               this.router.navigate([
                  appRoutesNames.ORGANIZATION,
                  orgId,
                  appRoutesNames.TEAM,
                  teamRouteNames.COMPETENCIES,
                  competencyId,
               ]);
            })
         ),
      { dispatch: false }
   );

   createPerformanceEvaluation$ = createEffect(() =>
      this.actions$.pipe(
         ofType(TeamActions.CreatePerformanceEvaluation),
         withLatestFrom(
            this.store.pipe(select(getCompetencies)),
            this.store.pipe(select(getDepartments))
         ),
         switchMap(([{ teamMember, year }, competencies, departments]) => {
            const performanceEvaluation: PerformanceEvaluation = {
               teamMemberId: teamMember.id,
               year,
               notes: null,
               status: PerformanceEvaluationStatus['Not Started'],
               performanceEvaluationItems: [],
            } as PerformanceEvaluation;

            const assignedCompetencies = competencies.filter((competency) =>
               competency.linkedTasks.some((taskId) => teamMember.assignedTasks?.includes(taskId))
            );
            assignedCompetencies.forEach((competency) => {
               const department = departments.find((dept) => dept.id === competency.departmentId);
               const perfEvalItem: PerformanceEvaluationItem = {
                  id: v4(),
                  performanceEvaluationId: performanceEvaluation.id,
                  competency: {
                     ...competency,
                     departmentName: department ? department.name : null,
                  },
               };
               performanceEvaluation.performanceEvaluationItems.push(perfEvalItem);
            });
            return from(this.performanceEvaluationService.save(performanceEvaluation, true)).pipe(
               map((saved) => {
                  return TeamActions.EditPerformanceEvaluation({
                     performanceEvaluationId: saved.id,
                  });
               }),
               catchError((error) => {
                  this.errorService.handleError(error);
                  return of();
               })
            );
         })
      )
   );

   editPerformanceEvaluation$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.EditPerformanceEvaluation),
            withLatestFrom(this.store.pipe(select(getOrganizationId))),
            tap(([{ performanceEvaluationId }, orgId]) => {
               this.router.navigate([
                  appRoutesNames.ORGANIZATION,
                  orgId,
                  appRoutesNames.TEAM,
                  teamRouteNames.PERFORMANCE_EVALUATIONS,
                  performanceEvaluationId,
               ]);
            })
         ),
      { dispatch: false }
   );

   savePerformanceEvaluation$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.SavePerformanceEvaluation),
            tap(({ performanceEvaluation }) => {
               this.performanceEvaluationService.save(performanceEvaluation).catch((error) => {
                  this.errorService.handleError(error);
               });
            })
         ),
      { dispatch: false }
   );

   deletePerformanceEvaluation$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.DeletePerformanceEvaluation),
            withLatestFrom(
               this.store.pipe(select(getOrganizationId)),
               this.store.pipe(select(getSelectedTeamMember))
            ),
            tap(([{ performanceEvaluation }, orgId, { id: teamMemberId }]) => {
               this.performanceEvaluationService.delete(performanceEvaluation).then(() => {
                  this.snackBar.open('Performance Evaluation Deleted');
                  this.router.navigate([
                     appRoutesNames.ORGANIZATION,
                     orgId,
                     appRoutesNames.TEAM,
                     teamRouteNames.TEAM_MEMBERS,
                     teamMemberId,
                  ]);
               });
            })
         ),
      { dispatch: false }
   );

   saveCompetencyLevels$ = createEffect(
      () =>
         this.actions$.pipe(
            ofType(TeamActions.SaveCompetencyLevels),
            withLatestFrom(this.store.pipe(select(teamSelectors.getCompetencyLevels))),
            tap(([{ competencyLevels }, oldCompetencyLevels]) => {
               const toDelete = oldCompetencyLevels.filter(
                  (level) => !competencyLevels.some((l) => l.id === level.id)
               );
               this.competencyLevelService
                  .batchSave(competencyLevels)
                  .then(() => {
                     Promise.all(
                        toDelete.map((level) => this.competencyLevelService.delete(level))
                     );
                  })
                  .catch((error) => {
                     this.errorService.handleError(error);
                  });
            })
         ),
      { dispatch: false }
   );
}
