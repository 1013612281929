import { Injectable, inject } from '@angular/core';
import { EntityService } from '@app/shared/services/entity.service';
import { Organization } from '@entities/organization';
import { Observable } from 'rxjs';
import { AuthUser } from '@entities/auth-user';

import { COLLECTION_NAMES } from '@app/shared/services/collection-names';
import { processEntity } from '@app/utilities/process-entity';
import { FirestoreService } from '@app/shared/services/firestore.service';

@Injectable()
export class OrganizationService implements EntityService<Organization> {
   readonly COLLECTION_NAME: string = COLLECTION_NAMES.ORGANIZATIONS;
   entities$: Observable<Organization[]>;

   private firestore = inject(FirestoreService);

   constructor() {}

   save(entity: Organization): Promise<Organization> {
      const processed = processEntity(entity);
      if (processed.organizationId) {
         return this.firestore
            .updateDoc(
               this.firestore.doc(this.COLLECTION_NAME, processed.organizationId),
               processed
            )
            .then(() => {
               return processed;
            });
      } else {
         const docRef = this.firestore.doc(this.COLLECTION_NAME);
         processed.organizationId = docRef.id;
         return this.firestore.setDoc(docRef, processed).then(() => {
            return processed;
         });
      }
   }
   batchSave(entities: Organization[]) {
      const batch = this.firestore.writeBatch();
      const savedEntities = [];
      entities.forEach((d) => {
         const processed = processEntity(d);
         const ref = this.firestore.doc(this.COLLECTION_NAME, d.organizationId);
         batch.set(ref, processed);
         savedEntities.push(processed);
      });
      return batch.commit().then(() => savedEntities);
   }

   delete(entity: Organization) {
      return this.firestore.deleteDoc(
         this.firestore.doc(this.COLLECTION_NAME, entity.organizationId)
      );
   }

   addAuthUser(authUser: AuthUser, organizationId: string) {
      return this.firestore.updateDoc(this.firestore.doc(this.COLLECTION_NAME, organizationId), {
         authUsers: this.firestore.arrayUnion(authUser.uid),
      });
   }

   createOrg() {}
}
