<button mat-button color="primary" (click)="back()">
   <mat-icon>arrow_back</mat-icon>
   Back
</button>
<ng-container *ngIf="allLoaded()">
   <div class="editor" [froalaEditor]="options">
      <ng-container *ngIf="orgName">
         <div style="display: flex; justify-content: space-between">
            <div style="display: flex">
               <img *ngIf="logo" [src]="logo" style="height: 50px" />
               <div style="font-size: 24pt; line-height: 60px">{{ orgName }}</div>
            </div>
            <div
               style="
                  font-size: 12pt;
                  font-weight: bold;
                  text-transform: uppercase;
                  padding-top: 12pt;
                  text-align: right;
               "
            >
               Organization Overview
            </div>
         </div>
      </ng-container>
      <ng-container *ngFor="let bu of businessUnits">
         <div style="border-bottom: 1px solid #414141">
            <h4 style="display: inline">{{ bu.name }}</h4>
            <h5 style="float: right">Director: {{ getTeamMemberName(bu.directorId) }}</h5>
         </div>
         <div style="width: 100%">
            <b>Purpose:</b>
            <div [innerHTML]="bu.purpose"></div>
         </div>
         <div style="display: flex; width: 100%">
            <div style="font-size: 16px; flex: 0 0 50%">Departments</div>
            <div style="font-size: 16px; flex: 0 0 50%">Manager</div>
         </div>
         <ng-container *ngFor="let dept of getDepartments(bu.id)">
            <div style="display: flex; width: 100%">
               <div style="font-size: 14px; flex: 0 0 50%">{{ dept.name }}</div>
               <div style="font-size: 14px; flex: 0 0 50%">
                  {{ getTeamMemberName(dept.managerId) }}
               </div>
            </div>
         </ng-container>
      </ng-container>
   </div>
</ng-container>
